import React, { useState, useEffect } from "react";
import CommandPalette from "./CommandPalette";
import { Icon } from "@iconify/react";
import Menu2 from "./Menu2";
import Button from "./Button";

export function AssignPallette({
  row,
  records,
  handleSelect,
  updateData,
  profile,
}) {
  const [assignee, setAssignee] = useState();
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const [teamNames, setTeamNames] = useState();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setAssignee(row.Team);
    setSelected(row.ReportedByTeams && row.ReportedByTeams);
  }, [row.ReportedByTeams]);

  const handleAddReportedByTeam = async (item) => {
    // e.preventDefault();

    let newReportedByTeams = [...row.ReportedByTeams];
    newReportedByTeams.push(item.name);

    // const updated = await _impediments.updateImpediment(
    //   row._id,
    //   null,
    //   "ReportedByTeams",
    //   newReportedByTeams,
    //   null
    // );

    // if (updated) {
    //
    //   updateData(updated);
    // }
  };

  const handleSelectOption = async (e, item) => {
    e.preventDefault();

    debugger;
    setOpen(false);
  };

  return (
    <>
      {/* <div className="whitespace-nowrap text-sm text-gray-500"> */}
      <div className="flex items-center text-slate-900 mt-1">
        <div
          className="flex items-center"
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Menu2 open={menuOpen} items={teamNames} />
        </div>
        {/* {profile && profile.tenantInfo.type == "admin" && ( */}
        <div className="flex items-center font-normal text-gray-500">
          <Button iconButton roundedFull handleClick={setOpen}>
            <Icon
              icon="mdi:account-plus"
              className="flex h-4 w-4 text-indigo-500 "
            />
          </Button>

          <CommandPalette
            open={open}
            setOpen={setOpen}
            options={records}
            handleClick={(item) => {
              handleSelect(item);
            }}
          />
        </div>
        {/* )} */}
      </div>
    </>
  );
}
