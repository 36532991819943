import react, { useEffect } from "react";
import { useState } from "react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Button from "../../components/Button";
import { Plan } from "../../modules/plan";
import ImpedimentList from "../../components/ImpedimentList";
import Impediments from "../Impediments";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { convertFromRaw } from "draft-js";
// import { stateToHTML } from "draft-js-export-html";
import { _auth } from "../../modules/_auth";
import {
  RenderTextField,
  RenderTeamName,
  RenderImpactedTeams,
  RenderCompetencyList,
  RenderImpedimentTypeList,
  RenderNumberColumn,
  RenderActions,
  RenderScopeList,
  RenderSubComponent,
  RenderLocalDescriptionField,
  RenderNewReportedByTeams,
} from "../Impediments";
import { RenderImpedimentSidebar } from "../Impediments/ImpedimentSidebar";
import DraggableImpedimentList from "./parts/draggableList";
import Input from "../../components/Input";
import Draglist from "../../components/DraggableCanvas";
import ModalBasic from "../../components/ModalBasic";
import PlanSelectCard from "./parts/planSelectCard";
import { _impediments } from "../../modules/impediments";
import { set } from "immutable";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { utils } from "../../modules/_utils";
import CompetenciesBar from "./parts/competenciesBar";
import { _user } from "../../modules/_user";
import Competencies from "../Competencies";
import AssessmentTemplateQuestionEditor from "../../components/AssessmentTemplatesQuestEditor";
import { _competencies } from "../../modules/competencies";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Tabs from "../component/Tabs";
import RTFViewer from "../../components/WYSIWYGViewer";
import RTFEditor from "../../components/RTFEditor";
import { convertToRaw, ContentState } from "draft-js";
import { Icon } from "@iconify/react";
export default function MyPlanBoard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [competencies, setCompetencies] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [allPlans, setAllPlans] = useState([]); // [{id: 1, name: "My Plan", competencies: [{id: 1, name: "Competency 1", cards: [{id: 1, name: "Task 1"}, {id: 2, name: "Task 2"}]}]}
  const [selectedCard, setSelectedCard] = useState(null);
  const [allImpediments, setAllImpediments] = useState([]);
  const [planImpediments, setPlanImpediments] = useState([]); // [{id: 1, name: "My Plan", competencies: [{id: 1, name: "Competency 1", cards: [{id: 1, name: "Task 1"}, {id: 2, name: "Task 2"}]}]
  const [competencyImpediments, setCompetencyImpediments] = useState([]);
  const [impedimentCards, setImpedimentCards] = useState([]); // [{id: 1, name: "My Plan", competencies: [{id: 1, name: "Competency 1", cards: [{id: 1, name: "Task 1"}, {id: 2, name: "Task 2"}]}]
  const [impediments, setImpediments] = useState([]);
  const [createPlanModalOpen, setCreatePlanModalOpen] = useState(false);
  const [openPlanModalOpen, setOpenPlanModalOpen] = useState(false);
  const [planName, setPlanName] = useState("");
  const [goal_date, setGoalDate] = useState("");
  const [winReady, setWinReady] = useState(false);
  const [updated, setUpdated] = useState(null);
  const [users, setUsers] = useState([]);
  // const [key, setKey] = useState(0);
  const [update, setUpdate] = useState(false);
  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const [selectImpedimentModalOpen, setSelectImpedimentModalOpen] =
    useState(false);
  const [competencyListOptions, setCompetencyListOptions] = useState([]);

  const [createCompetencyModalOpen, setCreateCompetencyModalOpen] =
    useState(false);

  const profile = _auth.getUserProfile();
  const [selectedTab, setSelectedTab] = useState(
    sessionStorage.getItem(`${profile.tenantId}-selectedTab`)
      ? sessionStorage.getItem(`${profile.tenantId}-selectedTab`)
      : "Plan Impediments"
  );
  useEffect(() => {}, [impedimentModalOpen]);

  useEffect(() => {
    (async () => {
      //check local storage for selected plan
      if (impedimentModalOpen || selectImpedimentModalOpen) {
        return;
      }
      let plan = sessionStorage.getItem(`${profile.tenantId}-plan`);
      if (
        plan &&
        plan !== null &&
        plan !== undefined &&
        plan !== "" &&
        plan.length > 0
      ) {
        plan = JSON.parse(plan);

        let updatePlan = await Plan.getPlan(plan._id);
        if (updatePlan) {
          let tenantId = updatePlan.tenantId._id;
          let tenantName = updatePlan.tenantId.tenantTitle;
          updatePlan = {
            ...updatePlan,
            tenantId,
            tenantName,
          };
          console.log("UseEffect updatePlan", updatePlan);
          setSelectedPlan(updatePlan);
          setImpedimentCards(updatePlan.Competencies[0].Cards || []);

          console.log(
            "UseEffect updatePlan Cards",
            updatePlan.Competencies[0].Cards
          );
          setCompetencies(
            updatePlan.Competencies ? updatePlan.Competencies : []
          );

          setSelectedCompetency(
            updatePlan && updatePlan.Competencies
              ? updatePlan.Competencies[0]
              : null
          );
          setSelectedRow(null);

          sessionStorage.setItem(
            `${profile.tenantId}-plan`,
            JSON.stringify(updatePlan)
          );
          // setImpediments(
          //   plan && plan.Competencies ? plan.Competencies.impediments : null
          // );
        }
      } else {
      }
    })();
  }, [impedimentModalOpen, selectImpedimentModalOpen]);

  useEffect(() => {
    (async () => {
      let compList = await _competencies.getCompetencyList();
      if (compList) {
        setCompetencyListOptions(compList);
      } else {
      }
    })();
  }, []);

  useEffect(() => {
    console.log("selectedPlan changed", selectedPlan);
    if (
      selectedPlan &&
      selectedPlan.Competencies &&
      selectedPlan.Competencies.length > 0
    ) {
      setSelectedCompetency(selectedPlan.Competencies[0] || {});
      setImpedimentCards(selectedPlan.Competencies[0].Cards || []);
      setCompetencies(selectedPlan.Competencies);
    } else {
      setSelectedCompetency(null);
      setImpedimentCards([]);
      setCompetencies([]);
    }
    sessionStorage.setItem(
      `${profile.tenantId}-plan`,
      JSON.stringify(selectedPlan)
    );
    console.log(
      "UseEffect selectedPlan impediment cards",
      selectedPlan?.Competencies[0]?.Cards
    );
    //save selected plan to local storage
  }, [selectedPlan]);

  useEffect(() => {
    (async () => {
      console.log("Profile", profile);
      let scope = "tenant";
      if (profile.tenantInfo.type === "admin") {
        scope = "all";
      }
      if (openPlanModalOpen) {
        let plans = await Plan.getAllPlans(scope);

        if (plans && plans.length > 0) {
          plans = plans.map((plan) => {
            let tenantId = plan.tenantId._id;
            let tenantName = plan.tenantId.tenantTitle;
            plan = {
              ...plan,
              tenantId,
              tenantName,
            };
            return plan;
          });
          setAllPlans(plans);
        }
      }
    })();
  }, [openPlanModalOpen]);

  useEffect(() => {
    (async () => {
      if (selectImpedimentModalOpen || impedimentModalOpen) {
        return;
      }
      let resp = await _impediments.getImpediments();

      if (resp) {
        setAllImpediments(resp);
      }
    })();
  }, [selectImpedimentModalOpen, impedimentModalOpen]);

  useEffect(() => {
    if (selectImpedimentModalOpen === true || impedimentModalOpen === true) {
      console.log("setImpedimentCards Not building impediments");
      return;
    }
    let resp =
      selectedCompetency &&
      selectedCompetency !== null &&
      selectedCompetency !== undefined &&
      selectedCompetency !== "" &&
      selectedCompetency.Cards &&
      selectedCompetency.Cards.map((card) => {
        return allImpediments.filter((impediment) => {
          return impediment._id == card.impedimentId;
        })[0];
      });
    //if the impediment does not have a sort order, set it to the index

    console.log("");

    if (resp && resp.length > 0) {
      resp =
        resp &&
        resp.length > 0 &&
        resp.map((impediment, index) => {
          if (impediment && !impediment.sort_order) {
            impediment.sort_order = index;
          }
          return impediment;
        });

      if (
        selectedCompetency &&
        selectedCompetency.Cards &&
        resp &&
        resp.length > 0
      ) {
        let cards = selectedCompetency.Cards
          ? selectedCompetency.Cards.sort((a, b) =>
              a.sort_order ? a.sort_order - b.sort_order : a.Index - b.Index
            )
          : [];
        cards &&
          cards.length > 0 &&
          resp &&
          resp.length > 0 &&
          cards.map((card) => {
            console.log(
              "setImpedimentCards Finding impediment for card",
              card.impedimentId
            );
            let impediment = resp.find(
              (impediment) => impediment && impediment._id == card.impedimentId
            );
            if (impediment) {
              console.log(
                " setImpedimentCards Found impediment for card",
                card.impedimentId
              );
              card.impediment = impediment;
            } else {
              console.log(
                "setImpedimentCards Did not find impediment for card",
                card.impedimentId
              );
            }
            return card;
          });

        setImpediments(resp);
        setImpedimentCards(cards);

        setSelectedRow(cards[0].impediment || null);
      } else {
        console.log("Resp is empty or selectedCompetency.Cards is empty");
      }
    } else {
      setImpedimentCards([]);
      setImpediments([]);
      setSelectedRow(null);
    }
    setWinReady(true);
  }, [selectedCompetency, selectedPlan]);

  useEffect(() => {
    if (
      updated &&
      updated !== null &&
      updated !== undefined &&
      updated !== ""
    ) {
      let newSelectedRow = { ...selectedRow };
      newSelectedRow = updated;

      setSelectedRow(newSelectedRow);

      let newImpediments = [...impediments];
      let index = newImpediments.findIndex(
        (impediment) => impediment._id == updated._id
      );
      newImpediments[index] = updated;

      setImpediments(newImpediments);

      let newAllImpediments = [...allImpediments];
      let allIndex = newAllImpediments.findIndex(
        (impediment) => impediment._id == updated._id
      );
      newAllImpediments[allIndex] = updated;

      setAllImpediments(newAllImpediments);

      // let newPlanImpediments = [...planImpediments];
      // newPlanImpediments.find(
      //   (impediment) => impediment._id == updated._id
      // );
    }
  }, [updated]);

  useEffect(() => {
    console.log("selectedPlan", selectedPlan);
  }, [selectedPlan, selectedCompetency]);

  useEffect(() => {
    //create competencyImpediments array from impedimentCards and impediments
    //sort impediments by impedimentCards sort order
    let sorted = [];
    impedimentCards
      .sort((a, b) => a.sort_order - b.sort_order)
      .forEach((card) => {
        let impediment = impediments.find(
          (impediment) => impediment && impediment._id == card.impedimentId
        );
        if (impediment) {
          sorted.push(impediment);
        }
      });
    setImpediments(sorted);
  }, [impedimentCards]);

  useEffect(() => {
    (async () => {
      let resp = await _user.getTenantUsers(1);

      if (resp) {
        //set all resp._id to resp.id
        // resp = resp.map((user) => {
        //   user.id = user.user_id;
        //   return user;
        // });

        setUsers(resp);
      }
    })();
  }, []);

  const downloadNestedPDF = () => {
    // Initialize a new jsPDF instance
    const doc = new jsPDF();
    console.log("Profile AutoTable", profile);
    // Add the plan.name at the top of the PDF
    doc.text(profile.tenantName, 10, 10);
    doc.text(selectedPlan.name, 10, 20);
    // doc.text(selectedPlan.goal_date, 10, 20);
    // doc.text(selectedPlan.status, 10, 30);

    let startY = 30; // Initialize startY outside the loop

    // Loop through each competency
    selectedPlan.Competencies.forEach((comp) => {
      // Define the main table's columns
      const mainColumns = [
        `${comp.competencyId.key} ${comp.competencyId.Area}-${comp.competencyId.Name}`,
        "",
        "", // Add an empty column
        "", // Add an empty column
      ];

      const impedimentCards = comp.Cards;
      //sort the impedimentCards by sort_order
      impedimentCards.sort((a, b) => a.sort_order - b.sort_order);

      const mainRows = [];

      // Define the main table's rows
      impedimentCards &&
        impedimentCards.length > 0 &&
        impedimentCards.forEach((card) => {
          console.log("AutoTable Impediment Cards", card);
          if (!card.inPlan) {
            return;
          }
          if (!card.impediment) {
            card.impediment = allImpediments.find(
              (i) => i._id === card.impedimentId
            );
          }
          if (card.impediment) {
            let index = card.impediment.Index;
            let title = card.impediment.Title;
            let description = card.impediment.Description;

            let team = card.impediment.teams_impacted.find(
              (team) => team.claimed_by_team._id === profile.tenantId
            );

            if (!team) {
              team =
                card.impediment.teams_impacted.length > 0
                  ? card.impediment.teams_impacted[0]
                  : {};
            }
            let burden = team.score || 0;
            let effort = team.effort || 0;
            let actionPlan = team.action_plan_text || "";
            // if (team.action_plan_rtf) {
            //   // Convert the ContentState object from raw format to a ContentState instance
            //   team.action_plan_rtf.entityMap =
            //     team.action_plan_rtf.entityMap || {};

            //   const contentState = convertFromRaw(team.action_plan_rtf);

            //   // Convert the ContentState instance to HTML
            //   const html = stateToHTML(contentState);

            //   // Replace the action plan with the HTML
            //   actionPlan = html;
            // }

            if (!title) {
              title =
                description.length > 50
                  ? description.substring(0, 50) + "..."
                  : description;
            }
            // Add the description as a new line in the same row
            // mainRows.push([`IM-${index}  ${title}\n${description}`, ""]);
            mainRows.push([
              `IM-${index}  ${title}`,
              `Burden: ${burden} hours/wk`,
              `Effort: ${effort} days`,
              team.Resolved ? "Resolved" : "Not Resolved",
            ]);

            mainRows.push([
              {
                content:
                  description && description.length > 0
                    ? `Description: \n${description}`
                    : "No description available",
                colSpan: 4,
                styles: { halign: "left", valign: "top" },
              },
            ]);

            mainRows.push([
              {
                content:
                  actionPlan && actionPlan.length > 0
                    ? `Action Plan: \n${actionPlan}`
                    : "No action plan available",
                colSpan: 4,
                styles: { halign: "left", valign: "top" },
              },
            ]);
          }
        });

      // Add the main table to the PDF
      let currentImpediment = null;
      let color = [255, 255, 255]; // Start with white
      let counter = 0;
      doc.autoTable({
        startY,
        head: [mainColumns],
        body: mainRows,
        styles: { cellWidth: "auto", halign: "left", valign: "top" },
        didParseCell: function (data) {
          // Skip the header row
          if (data.section === "head") {
            return;
          }

          // Increment the counter
          counter++;

          // Check if the counter has reached 3
          if (counter === 7) {
            // Reset the counter
            counter = 0;

            // Change the color
            color = color[0] === 255 ? [235, 235, 235] : [255, 255, 255];
          }

          // Set the fillColor for the cell
          data.cell.styles.fillColor = color;
        },
        didDrawCell: function (data) {
          // Check if the cell text is defined and contains a newline character
          if (
            data.cell.text &&
            data.cell.text[0] &&
            data.cell.text[0].includes("\n")
          ) {
            // Split the text into lines
            const lines = data.cell.text[0].split("\n");

            // Set the text of the cell to the second line
            data.cell.text[0] = lines[1];

            // Draw the first line manually
            doc.setFontStyle("bold");
            doc.text(lines[0], data.cell.x, data.cell.y);
            doc.setFontStyle("normal");
          }
        },
        // didDrawCell: function (data) {
        //   // Check if the cell contains a newline character
        //   if (data.cell.text[0].includes("\n")) {
        //     // Split the text into lines
        //     const lines = data.cell.text[0].split("\n");

        //     // Set the text of the cell to the second line
        //     data.cell.text[0] = lines[1];

        //     // Draw the first line manually
        //     doc.setFontStyle("bold");
        //     doc.text(lines[0], data.cell.x, data.cell.y);
        //     doc.setFontStyle("normal");
        //   }
        // },
      });

      // Update the Y position for the next table
      startY = doc.autoTable.previous.finalY + 20; // Update startY after each table
    });

    // Save the PDF
    doc.save("table.pdf");
  };
  const handleDownloadPdfImage = async () => {
    const table = document.getElementById("response_table");
    const rows = Array.from(table.querySelectorAll("tr"));
    const data = rows.map((row) => {
      return Array.from(row.querySelectorAll("td, th")).map(
        (cell) => cell.innerText
      );
    });
    const numColumns = data[0].length;
    const cellWidth = 110;
    const margin = 20;
    // Set document header with the current date on the right side
    const columnStyles = {};
    for (let i = 0; i < numColumns; i++) {
      columnStyles[i] = { cellWidth: cellWidth }; // Set the width for each column
    }

    const pageWidth = numColumns * cellWidth + 2 * margin;
    //if the pageWidth is less than A4, set it to A4
    if (pageWidth < 210) {
      pageWidth = 210;
    }

    const pdf = new jsPDF({
      format: [pageWidth, 400], // "a4", // You can choose a different format
      unit: "mm",
      orientation: "landscape",
    });
    let name = ""; // `Bid Comparison for ${solicitation?.projectId?.projectId}-${solicitation.incrementId}  ${solicitation?.name}`;
    pdf.setFont("Helvetica");
    pdf.setFontSize("12");

    // Set document header
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });

    // Get the width of the date text
    const dateWidth = pdf.getStringUnitWidth(formattedDate);

    pdf.text(250 - dateWidth - 1, 10, formattedDate);
    pdf.text(20, 10, name);
    // Define table headers and data
    const tableConfig = {
      startY: 20,
      //head: [headers],
      body: data,
      columnStyles: columnStyles,
      cellStyles: {
        rowEven: { fillColor: [255, 0, 0] }, // Red background color for even-numbered rows
        // Add more custom styling as needed
      },
    };

    // Generate the table using autoTable
    pdf.autoTable(tableConfig);

    // Save the PDF
    pdf.save("Bid Responses.pdf");
  };
  const handleRemoveImpediment = async (index, impediment) => {
    console.log("handleRemoveImpediment", impediment);
    //if the index is null, get the index of the impediment in the impedimentCards
    if (index === null) {
      index = impedimentCards.findIndex(
        (card) => card.impedimentId === impediment._id
      );
    }

    const newCompetency = { ...selectedCompetency };
    newCompetency.impediments = newCompetency.impediments.filter(
      (i) => i._id !== impediment._id
    );
    let newImpedimentCards = [...impedimentCards];
    newImpedimentCards = newImpedimentCards.filter(
      (i) => i.impedimentId !== impediment._id
    );
    // setSelectedCompetency(newCompetency);
    setSelectedCard(newCompetency);
    setAllImpediments(allImpediments.filter((i) => i._id !== impediment._id));
    setImpediments(impediments.filter((i) => i._id !== impediment._id));
    setImpedimentCards(newImpedimentCards);
    //find the card in competencies.Cards and remove it
    let newCompetencies = competencies.map((c) => {
      if (c._id === newCompetency._id) {
        c.Cards = newImpedimentCards;
        return c;
      }
      return c;
    });
    let selected;

    if (newImpedimentCards.length > 0) {
      //if the index is the last item in the array, select the last item
      if (index === newImpedimentCards.length) {
        index = index - 1;
      } else {
        selected = newImpedimentCards[index];
      }
    } else {
      selected = {};
    }
    console.log("handleRemoveImpediment selected", selected);
    setSelectedRow(selected.impediment);
  };
  const handleRemoveCompetency = async (e, index, competency) => {
    e.stopPropagation();
    e.preventDefault();
    console.log("handleRemoveCompetency", competency._id);
    const id = competency._id;
    const newPlan = { ...selectedPlan };
    console.log("handleRemoveCompetency newPlan", newPlan);

    newPlan.Competencies = newPlan.Competencies.filter((c) => {
      console.log(
        "handleRemoveCompetency Current competency ID:",
        c.competencyId._id
      );
      console.log(
        "handleRemoveCompetency Target competency ID:",
        competency._id
      );

      const result = c.competencyId._id !== competency._id;
      console.log("handleRemoveCompetency Filter result:", result);

      return result;
    });
    console.log(
      "handleRemoveCompetency newPlan.Competencies",
      newPlan.Competencies
    );

    setSelectedPlan(newPlan);
    //update the plan in the database
    const resp = await Plan.removeCompetencyFromPlan(
      newPlan._id,
      competency._id
    );
    console.log("handleRemoveCompetency resp", resp);
  };

  const addImpedimentToCompetency = (impediment) => {
    const newCompetency = { ...selectedCompetency };
    newCompetency.impediments.push(impediment);
    // find and remove old competency and add new one
    const newCompetencies = competencies.map((c) => {
      if (c._id === newCompetency._id) {
        return newCompetency;
      }
      return c;
    });
    setCompetencies(newCompetencies);
  };

  const handleCreatePlanClick = async (e) => {
    e.preventDefault();

    let plan = await Plan.createPlan(planName, goal_date);

    if (plan) {
      let tenantName = plan.tenantId.tenantTitle;
      let tenantId = plan.tenantId._id;
      plan = {
        ...plan,
        tenantName,
        tenantId,
      };

      setAllPlans([...allPlans, plan]);
      setSelectedPlan(plan);
      setCreatePlanModalOpen(false);
      setPlanName("");
      setGoalDate("");
    }
  };

  const handleOpenPlanClick = async (e) => {
    e.preventDefault();

    // setSelectedPlan(plans[0]);

    // setSelectedCompetency(plans[0].Competencies[0]);
    // setImpediments(plans[0].Competencies[0].impediments);
  };
  const handleSelectCompetency = (e, index) => {
    e.preventDefault();

    setSelectedCompetency(competencies[index]);
    // setImpediments(selectedPlan.Competencies[index].impediments);
  };

  const handleOnCompDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(competencies);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // setCompetencies(items);
    updateCompSortOrder(items);
  };

  const updateCompSortOrder = async (data) => {
    //update the sort_order field of each item in data to match its current index
    data.forEach((item, index) => {
      item.sort_order = index;
    });

    setCompetencies(data);
    let updatedPlan = { ...selectedPlan };
    updatedPlan.Competencies = data;
    setSelectedPlan(updatedPlan);
    //need API
    const resp = await Plan.UpdateCompSortOrder(selectedPlan._id, data);

    // let resp = true;
    // if (resp) {

    //   // setCompetencies(resp.Competencies);
    //   let updatedPlan = await Plan.getPlan(selectedPlan._id);
    //   if (updatedPlan) {

    //     setSelectedPlan(updatedPlan);

    //     setCompetencies(updatedPlan.Competencies);
    //     setSelectedCompetency(updatedPlan.Competencies[0]);
    //     setSelectedRow(null);
    //     localStorage.setItem("selectedPlan", JSON.stringify(updatedPlan));
    //   }

    // } else {

    // }
  };
  const updateSortOrder = async (data) => {
    //update the sort order of the impediments to the order of the impediment cards

    let impCards = selectedCompetency.Cards;

    //if any items in data are undefined, return
    if (data.findIndex((item) => item == undefined) > -1) {
      return;
    } else {
      impCards.forEach((card) => {
        //find the index of the impediment in the data
        let index = data.findIndex((item) => {
          return item.impedimentId == card.impedimentId;
        });

        if (index > -1) {
          card.sort_order = index;
        }
      });

      setImpedimentCards(impCards);

      const resp = await Plan.UpdateSortOrder(
        selectedPlan._id,
        selectedCompetency,
        impCards
      );
      if (resp) {
      }
    }
  };

  const updateAssignedTo = async (data) => {
    if (data == undefined || data == null || data == "") {
      return;
    } else {
      let impCards = [];
      impedimentCards.forEach((card) => {
        impCards.push({ ...card });
      });

      let impCards2 = [];
      impCards2 = [...impedimentCards];
      //find impediment in impedimentCards and update assigned_to
      let index = impCards.findIndex(
        (card) => card.impedimentId == data.impedimentId
      );
      if (index > -1) {
        impCards[index].assigned_to = data.assigned_to;
        impCards2[index].assigned_to = data.assigned_to.id;
      } else {
      }

      // let newSelectedRow = { ...selectedRow };
      // newSelectedRow.assigned_to = data;
      // setSelectedRow(newSelectedRow);

      const resp = await Plan.UpdateSortOrder(
        selectedPlan._id,
        selectedCompetency,
        impCards2
      );

      // let resp = true;
      if (resp) {
        setImpedimentCards(impCards);

        // setImpedimentCards(impCards);
      } else {
      }
    }
  };

  const addImpedimentToPlan = async (data) => {
    if (data == undefined || data == null || data == "") {
      return;
    } else {
      let impCards = [];
      impedimentCards.forEach((card) => {
        impCards.push({ ...card });
      });

      let impCards2 = [];
      impCards2 = [...impedimentCards];
      //find impediment in impedimentCards and update assigned_to
      let index = impCards.findIndex(
        (card) => card.impedimentId == data.impedimentId
      );
      if (index > -1) {
        impCards[index].inPlan = data.inPlan;
      } else {
      }

      const resp = await Plan.UpdateSortOrder(
        selectedPlan._id,
        selectedCompetency,
        impCards2
      );

      // let resp = true;
      if (resp) {
        setImpedimentCards(impCards);
        //update competencies with new impediment
        let newCompetencies = competencies.map((c) => {
          if (c._id === selectedCompetency._id) {
            c.Cards = impCards;
            return c;
          }
          return c;
        });
        setCompetencies(newCompetencies);
      } else {
      }
    }
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(competencies);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCompetencies(items);
  }
  const handleOnBlurV4RTF = async (e, fieldname, content, row) => {
    const myTeam = row.teams_impacted.find(
      (team) => team.claimed_by_team._id == profile.tenantId
    );
    const val = e.target.value;
    const rtfField = e.target.name;
    const textVal = content;
    const textField = fieldname;
    const id = row._id;
    const value = row.teams_impacted;
    //"description" ? description[id] : updateField[id][fieldname];
    //

    myTeam[rtfField] = val;
    // if (textVal !== "") {
    myTeam[textField] = textVal;
    // }

    //update selectedRow with new value
    let newSelectedRow = { ...selectedRow };
    //find myteam and update it
    let myTeamIndex = newSelectedRow.teams_impacted.findIndex(
      (team) => team.claimed_by_team._id == profile.tenantId
    );
    newSelectedRow.teams_impacted[myTeamIndex] = myTeam;

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      "teams_impacted",
      newSelectedRow.teams_impacted
    );

    setUpdated(newSelectedRow);
  };
  const handleOnBlurV3 = async (e, id, fieldname, row) => {
    const myTeam = row.teams_impacted.find(
      (team) => team.claimed_by_team._id == profile.tenantId
    );
    const val = e.target.value;
    const value = row.teams_impacted;
    //"description" ? description[id] : updateField[id][fieldname];
    //

    myTeam[fieldname] = val;

    //update selectedRow with new value
    let newSelectedRow = { ...selectedRow };
    //find myteam and update it
    let myTeamIndex = newSelectedRow.teams_impacted.findIndex(
      (team) => team.claimed_by_team._id == profile.tenantId
    );
    newSelectedRow.teams_impacted[myTeamIndex] = myTeam;

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      "teams_impacted",
      newSelectedRow.teams_impacted
    );

    setUpdated(newSelectedRow);
  };
  const handleOnBlurV2 = async (e, id, fieldname, row) => {
    //
    //get value from description state
    // const value = e.target.value;
    const value = row.teams_impacted;
    //"description" ? description[id] : updateField[id][fieldname];

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      "teams_impacted",
      value
    );

    // if (update) {

    //   const newDescription = { ...description };
    //   delete newDescription[id];

    //   //

    //   let newImpediments = [...employees];

    //   let index = newImpediments.findIndex(
    //     (impediment) => impediment._id === id
    //   );

    //   newImpediments[index][fieldname] = value;
    //   // newImpediments[index][fieldname] = value;
    //   // setEmployees(newImpediments);
    // }
  };
  const handleOnBlur = async (e, id, fieldname) => {
    // //get value from description state
    // const descriptionFilter = searchValue;
    // const reportedByFilter = searchReportedByValue;

    const value = e.target.value;
    //"description" ? description[id] : updateField[id][fieldname];

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      fieldname,
      // e.target.value
      value
    );

    if (update) {
      let newUpdated = { ...updated };
      newUpdated = update;
      setUpdated(newUpdated);

      // setUpdated(update);
      // setSelectedRow(currentSelectedRow);
    }
  };
  const onToggleChange = async (value) => {
    setSelectedTab(value);

    sessionStorage.setItem(`${profile.tenantId}-selectedTab`, value);
    // let tv = value === "Plan Impediments" ? true : false;
    // setToggleValue(tv);
  };

  const onFieldChange = (row) => {
    // let newState = [...employees];
    //
    // const impedimentIndex = employees.findIndex((el) => el._id === row._id);
    //
    // const teamIndex = newState[impedimentIndex].teams_impacted.findIndex(
    //   (el) => el.claimed_by_team._id === profile.tenantId
    // );
    //
    // if (teamIndex > -1) {
    //   newState[impedimentIndex].teams_impacted[teamIndex].score = row.score;
    //   newState[impedimentIndex].teams_impacted[teamIndex].effort = row.effort;
    // }
    //
  };

  return (
    <>
      <div className="flex h-[calc(100vh-64px)]">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-hidden overflow-x-scroll w-full focus:outline-none xl:order-last">
              <div className="  bg-white border-b border-gray-200">
                <div className="px-6 py-3 flex items-center justify-between">
                  <div id="right-side" className="flex flex-col  ">
                    {selectedPlan && selectedPlan.name && (
                      <h2 className="pt-2 text-lg font-medium text-gray-900">
                        {selectedPlan && selectedPlan.name}
                      </h2>
                    )}
                  </div>
                  <div id="left-side" className="flex items-center">
                    {/* <Button
                      handleClick={() => setCreatePlanModalOpen(true)}
                      color="link"
                      size="sm"
                    >
                      <span className="text-sm text-gray-600">+ New Plan</span>
                    </Button> */}
                    <Button
                      handleClick={(e) => downloadNestedPDF()}
                      color="secondary"
                      size="xs"
                    >
                      <span className="">Download PDF</span>
                    </Button>
                  </div>
                </div>
                {/* <div className=" py-4 "></div> */}
              </div>
              <article className="">
                <div className="p-2 border-b border-gray-200 relative inline-flex  bg-white w-full">
                  <Tabs
                    tabs={[
                      {
                        name: "Plan Impediments",
                        current: selectedTab,
                      },
                      {
                        name: "All Impediments",
                        current: selectedTab,
                      },
                    ]}
                    selectedTab={selectedTab}
                    setSelectedTab={onToggleChange}
                  />
                </div>
                <DraggableImpedimentList
                  addNewImpediment={() => {
                    setImpedimentModalOpen(true);
                  }}
                  addExistingImpediment={() => {
                    setSelectImpedimentModalOpen(true);
                  }}
                  selectedPlan={selectedPlan}
                  winReady={winReady}
                  users={users}
                  updateSortOrder={(data) => updateSortOrder(data)}
                  updateAssignedTo={(data) => updateAssignedTo(data)}
                  addImpedimentToPlan={(data) => addImpedimentToPlan(data)}
                  initialData={
                    selectedTab === "Plan Impediments"
                      ? impedimentCards.filter((card) => card.inPlan === true)
                      : impedimentCards
                  } //this could also be unclaimedImpediments
                  items={impedimentCards} //this could also be unclaimedImpediments
                  setSelectedRow={(item) => {
                    setSelectedRow(item);
                  }}
                  selectedRow={selectedRow}
                  RenderTeamName={(row) => {
                    return (
                      <RenderTeamName
                        row={row}
                        teams={[]}
                        setReportedByTeamModalOpen={
                          Impediments.setReportedByTeamModalOpen
                        }
                        handleCommandClick={Impediments.handleCommandClick}
                        updateData={Impediments.updateData}
                        profile={profile}
                      />
                    );
                  }}
                  RenderImpactedTeams={(row) => {
                    return <RenderImpactedTeams row={selectedRow} />;
                  }}
                  RenderImpedimentTypeList={(row, p) => {
                    return (
                      <RenderImpedimentTypeList row={selectedRow} profile={p} />
                    );
                  }}
                  RenderCompetencyList={(item) => {
                    return (
                      <RenderCompetencyList
                        row={selectedRow}
                        competencies={[]}
                        updateData={Impediments.updateData}
                        profile={profile}
                        from={"sidebar"}
                      />
                    );
                  }}
                  RenderScopeList={(row) => {
                    return (
                      <RenderScopeList row={selectedRow} profile={profile} />
                    );
                  }}
                  RenderNumberColumn={(row, field) => {
                    return (
                      <RenderNumberColumn
                        row={row}
                        handleOnBlur={Impediments.handleOnBlurV2}
                        handleTextColumnValueChange2={Impediments.onFieldChange}
                        columnId={field}
                        profile={profile}
                      />
                    );
                  }}
                  RenderActions={(item, idx) => {
                    return (
                      <RenderActions
                        dedupeMode={true}
                        component="sidebar"
                        updateData={Impediments.updateData}
                        // updateData={() => {
                        //   Impediments.updateData();
                        //   setImpedimentModalOpen(false);
                        // }}
                        setUpdatedId={Impediments.setUpdatedId}
                        row={item}
                        showDelete={false}
                        showViewDetails={false}
                        showResolveImpediment={true}
                        showClaim={true}
                        // rowIndex={idx}
                        profile={profile}
                        setSelectedRow={(item) => {
                          setSelectedRow(item);
                        }}
                      />
                    );
                  }}
                  renderSubComponent={(row, field) => {
                    return (
                      <RenderSubComponent
                        row={row}
                        handleOnBlur={Impediments.handleOnBlur}
                        field={field}
                      />
                    );
                  }}
                  renderSubComponent1={(row, field) => {
                    return (
                      <RenderSubComponent
                        row={row}
                        handleOnBlur={Impediments.handleOnBlur}
                        field={field}
                      />
                    );
                  }}
                  RenderTextField={(row, field) => {
                    return (
                      <RenderTextField
                        row={row}
                        handleOnBlur={Impediments.handleOnBlur}
                        field={field}
                        placeholder={"Enter a title"}
                        data={impediments}
                      />
                    );
                  }}
                />

                {/* <Draglist impediments={impediments} /> */}
              </article>
            </main>
            {/* Left Column */}
            {/* <CompetenciesBar
              competencies={competencies}
              selectedPlan={selectedPlan}
              setSelectedCompetency={setSelectedCompetency}
              setOpenPlanModalOpen={setOpenPlanModalOpen}
              setCreatePlanModalOpen={setCreatePlanModalOpen}
              handleSelectCompetency={handleSelectCompetency}
              handleOnDragEnd={handleOnDragEnd}
            /> */}

            <aside className="-z-1 bg-white overflow-hidden h-[calc(100vh-64px]) w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
              <div className=" pt-2 bg-white">
                <div className="px-6 flex items-center justify-between">
                  <div id="right-side" className="flex flex-col  ">
                    <h2 className="text-lg font-medium text-gray-900">
                      Plan Builder
                    </h2>
                  </div>
                  <div id="left-side" className="flex items-top">
                    <Button
                      handleClick={() => setCreatePlanModalOpen(true)}
                      color="link"
                      size="sm"
                    >
                      <span className="text-sm text-gray-600">+ New Plan</span>
                    </Button>
                    <Button
                      handleClick={(e) => setOpenPlanModalOpen(true)}
                      color="link"
                      size="sm"
                    >
                      <span className="text-sm text-gray-600">Select Plan</span>
                    </Button>
                  </div>
                </div>
                <div className="flex shrink-0">
                  {/* <p className="mt-1 text-sm text-gray-600">
              Build the map to new heights
            </p> */}
                </div>

                <div className="flex h-12 justify-between items-center mt-6 py-1 sticky top-0 z-10 border-t border-b border-gray-200  px-4 font-medium ">
                  {/* {selectedPlan && selectedPlan.name} */}
                  <div className="">Competencies</div>
                  <div>
                    {selectedPlan && selectedPlan.name && (
                      <Button
                        color="secondary-text"
                        handleClick={() => setCreateCompetencyModalOpen(true)}
                      >
                        + Add Competency
                      </Button>
                    )}
                  </div>
                </div>

                {/* <div className="mt-6 flex font-medium">
       
          </div> */}

                {/* <form className="mt-6 flex space-x-4" action="#">
            <div className="min-w-0 flex-1">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-secondary-500 focus:ring-secondary-500 sm:text-sm"
                  placeholder="Search"
                />
              </div>
            </div>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
            >
              <FunnelIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="sr-only">Search</span>
            </button>
          </form> */}
              </div>
              {/* Directory list */}
              <nav
                className="min-h-0 flex-1 overflow-y-auto   scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
          scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
              >
                <DragDropContext onDragEnd={handleOnCompDragEnd}>
                  <Droppable
                    droppableId="competencies"
                    onDrop={handleOnCompDragEnd}
                    onDragEnd={handleOnCompDragEnd}
                  >
                    {(provided) => (
                      <ul
                        className="relative z-0 divide-y divide-gray-200 characters"
                        // className="characters"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {competencies && competencies.length > 0 ? (
                          competencies.map((card, index) => {
                            let competency;
                            competency = card.competencyId;

                            let selected;

                            competency && competency._id
                              ? (selected =
                                  competency._id ===
                                  selectedCompetency?.competencyId._id)
                              : (selected = false);
                            let impedimentCount = card.Cards
                              ? card.Cards.length
                              : 0;
                            let status = card.status
                              ? card.status
                              : "Not Started";

                            return (
                              <Draggable
                                key={competency._id}
                                draggableId={competency._id}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    key={competency._id}
                                    className={`${
                                      selected ? "bg-gray-500" : "bg-white"
                                    } relative`}
                                    onClick={(e) =>
                                      handleSelectCompetency(e, index)
                                    }
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div
                                      className={`relative space-y-2 py-5 px-4 ${
                                        selected
                                          ? "bg-gray-50"
                                          : "bg-white  hover:bg-gray-50"
                                      }`}
                                    >
                                      <div
                                        id="rowOne"
                                        className="min-w-0 flex items-center justify-between"
                                      >
                                        <div id="r1Left" className="flex">
                                          {/* Extend touch target to entire panel */}
                                          <span
                                            className="absolute inset-0"
                                            aria-hidden="true"
                                          />
                                          <p className="text-sm font-medium text-gray-900">
                                            {competency.key} {""}{" "}
                                            {competency.Name}
                                          </p>
                                        </div>
                                        <div id="r1Right" className="flex">
                                          {/* <span className="inline-flex items-center px-2.5 py-0.5 text-xs rounded bg-accent-700 text-white text-opacity-80"> */}
                                          <Button
                                            // iconButton={true}
                                            iconButton
                                            color="text"
                                            className="z-10 "
                                            handleClick={(e) => {
                                              console.log("Click!");

                                              handleRemoveCompetency(
                                                e,
                                                null,
                                                competency
                                              );
                                            }}
                                          >
                                            <span className="sr-only">
                                              Delete
                                            </span>
                                            <Icon icon="mdi:trash" />
                                          </Button>
                                          {/* </span> */}
                                        </div>
                                      </div>
                                      <div
                                        id="rowTwo"
                                        className="flex items-center justify-between "
                                      >
                                        <div
                                          id="r2Left"
                                          className="flex items-center"
                                        >
                                          <p className="truncate text-sm text-gray-500">
                                            {competency.Area}
                                          </p>
                                        </div>
                                        <div
                                          id="r2Right"
                                          className="flex items-center"
                                        >
                                          {card.Cards && (
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-50 text-indigo-500">
                                              {card.Cards.length} impediments
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })
                        ) : (
                          <li className="bg-white relative"></li>
                        )}

                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
                {/* <Droppable droppableId="competencies">
            <ul
              role="list"
              className="relative z-0 divide-y divide-gray-200"
            >
              {selectedPlan && selectedPlan.Competencies ? (
                selectedPlan.Competencies.map((competency, index) => {
                  competency = competency.competencyId;
                  let selected;

                  competency && competency._id
                    ? (selected =
                        competency._id ==
                        selectedCompetency.competencyId._id)
                    : (selected = false);

                  // 
                  return (
                    <li
                      key={competency._id}
                      className={`${
                        selected ? "bg-gray-500" : "bg-white"
                      } relative`}
                      onClick={(e) => handleSelectCompetency(e, index)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        className={`relative flex items-center space-x-3  py-5 px-4
                  ${
                    selected ? "bg-gray-50" : "bg-white  hover:bg-gray-50"
                  }`}
                      >
                        
                        <div className="min-w-0 flex-1">
                          <a href="#" className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                {/* <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-900">
                              {competency.key}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              {competency.Name}
                            </p>
                          </a>
                        </div>
                      </div>
                    </li> */}
                {/* );
                })
              ) : (
                <li className="bg-white relative"></li>
              )}
            </ul> */}
                {/* </Droppable>  */}
              </nav>
            </aside>
            <div className="z-50 h-full fixed w-[33vw] bottom-0 right-0 top-[133px] ">
              <RenderImpedimentSidebar
                readOnly={false}
                from={"planbuilder"}
                dedupeMode={true}
                row={selectedRow}
                updateData={null}
                profile={profile}
                RenderNewReportedByTeams={(row) => {
                  return (
                    <RenderNewReportedByTeams
                      row={row}
                      teams={null}
                      setReportedByTeamModalOpen={null}
                      handleCommandClick={null}
                      updateData={null}
                      profile={profile}
                    />
                  );
                }}
                RenderTeamName={(row) => {
                  return (
                    <RenderTeamName
                      row={row}
                      teams={[]}
                      setReportedByTeamModalOpen={
                        Impediments.setReportedByTeamModalOpen
                      }
                      handleCommandClick={Impediments.handleCommandClick}
                      updateData={Impediments.updateData}
                      profile={profile}
                    />
                  );
                }}
                RenderImpactedTeams={(row) => {
                  return <RenderImpactedTeams row={selectedRow} />;
                }}
                RenderImpedimentTypeList={(row, p) => {
                  return (
                    <RenderImpedimentTypeList row={selectedRow} profile={p} />
                  );
                }}
                RenderCompetencyList={(item) => {
                  return (
                    <RenderCompetencyList
                      row={selectedRow}
                      competencies={[]}
                      updateData={Impediments.updateData}
                      profile={profile}
                      from={"sidebar"}
                    />
                  );
                }}
                RenderScopeList={(row) => {
                  return (
                    <RenderScopeList row={selectedRow} profile={profile} />
                  );
                }}
                RenderNumberColumn={(row, field) => {
                  return (
                    <RenderNumberColumn
                      row={row}
                      handleOnBlur={handleOnBlurV2}
                      handleTextColumnValueChange2={onFieldChange}
                      columnId={field}
                      profile={profile}
                    />
                  );
                }}
                RenderActions={(item, idx) => {
                  return (
                    <RenderActions
                      dedupeMode={true}
                      component="sidebar"
                      updateData={() => {
                        handleRemoveImpediment(idx, item);
                      }}
                      // updateData={() => {
                      //   Impediments.updateData;
                      //   setSelectImpedimentModalOpen(false);
                      // }}
                      setUpdatedId={Impediments.setUpdatedId}
                      row={item}
                      showDelete={false}
                      showViewDetails={false}
                      showResolveImpediment={true}
                      showClaim={true}
                      // rowIndex={idx}
                      profile={profile}
                      setSelectedRow={(item) => {
                        setSelectedRow(item);
                      }}
                    />
                  );
                }}
                renderSubComponent={(row, field) => {
                  return (
                    <RenderSubComponent
                      row={row}
                      handleOnBlur={handleOnBlur}
                      field={field}
                    />
                  );
                }}
                renderSubComponent1={(row, field) => {
                  return (
                    <RenderLocalDescriptionField
                      row={row}
                      handleOnBlur={handleOnBlurV3}
                      field={field}
                      profile={profile}
                    />
                  );
                }}
                renderActionPlanRTFEditor={(row, field) => {
                  console.log("Rendering RTFEditor", row, field);
                  // Before your return statement
                  const teamImpacted = row.teams_impacted.find(
                    (team) => team.claimed_by_team._id == profile.tenantId
                  );
                  let value = "";
                  let valueType = "";
                  let actionPlanRTF = teamImpacted?.action_plan_rtf;
                  const actionPlanText = teamImpacted?.action_plan_text;
                  if (actionPlanRTF === undefined || actionPlanRTF === null) {
                    console.log(
                      "XXXX actionPlanRTF is undefined",
                      actionPlanRTF
                    );
                    value = actionPlanText;
                    valueType = "actionPlanText";
                  } else {
                    console.log("XXXX actionPlanRTF is defined", actionPlanRTF);
                    // actionPlanRTF = {};
                    if (!actionPlanRTF.entityMap) {
                      actionPlanRTF.entityMap = {};
                    }
                    console.log("XXXX This is the RTF value", actionPlanRTF);
                    const contentState = convertFromRaw(actionPlanRTF);
                    const textContent =
                      contentState?.getPlainText() || undefined;
                    console.log(
                      "XXXX This is the RTF textContent value",
                      textContent
                    );

                    value =
                      textContent == undefined || textContent == null
                        ? actionPlanText
                        : actionPlanRTF;

                    valueType =
                      textContent == undefined || textContent == null
                        ? "actionPlanText"
                        : "actionPlanRTF";
                  }

                  console.log("This is the RFTEditor value", valueType, value);
                  return (
                    <RTFEditor
                      placeholder="Describe your action plan here"
                      handleFieldChange={(e, rawContent, content) => {
                        handleOnBlurV4RTF(
                          {
                            target: {
                              name: "action_plan_rtf",
                              value: rawContent,
                              type: "rtf",
                            },
                          },
                          "action_plan_text",

                          content,
                          row
                        );
                      }}
                      value={value}
                      stringValue={actionPlanText}
                    />
                  );
                }}
                RenderTextField={(row, field) => {
                  return (
                    <RenderTextField
                      row={row}
                      handleOnBlur={Impediments.handleOnBlur}
                      field={field}
                      placeholder={"Enter a title"}
                      data={impediments}
                    />
                  );
                }}
              />
            </div>

            {/* <Impediments impediments={impediments} /> */}
          </div>
        </div>
      </div>
      <ModalBasic
        id="plan-modal"
        modalOpen={createPlanModalOpen}
        setModalOpen={setCreatePlanModalOpen}
        title="Create a plan"
        showClose={false}
        showFooter={true}
        closeButtonText="Close"
        handleConfirmClick={(e) => handleCreatePlanClick(e)}
        // selectPlan={selectPlan}
        OKbuttonText="Create this plan"
        CancelButton
        cleanUpState={null}
        size={"md"}
      >
        <div className="flex flex-col space-y-6 mx-auto px-24 py-8 h-64 w-1/2 ">
          <div>Create a new plan here</div>
          <div className="flex">
            <label className="mr-4" htmlFor="planName">
              Plan Name
            </label>
            <Input type="text" onChange={(e) => setPlanName(e.target.value)} />
          </div>
          <div className="flex">
            <label className="mr-4" htmlFor="planDescription">
              Goal Date
            </label>
            <DatePicker
              // selected={null}
              showMonthDropdown
              minDate={new Date()}
              // onSelect={(e) => onFieldChange()}
              onSelect={(e) => setGoalDate(e)}
              selected={goal_date}
              value={goal_date}
              onChange={(e) => setGoalDate(e)}
              // calendarClassName="calendar"
              // className="border p-2 rounded"
              className="block w-full min-w-full flex-1  rounded-md border-gray-300 focus:border-secondary-500 focus:ring-secondary-500 sm:text-sm"
            />
          </div>
        </div>
      </ModalBasic>
      <ModalBasic
        id="open-plan-modal"
        modalOpen={openPlanModalOpen}
        setModalOpen={setOpenPlanModalOpen}
        title="Select a plan"
        showClose={false}
        showFooter={true}
        closeButtonText="Close"
        handleConfirmClick={(e) => handleOpenPlanClick(e)}
        // selectPlan={selectPlan}
        OKbuttonText="Select this plan"
        CancelButton
        cleanUpState={null}
        size={"sm"}
        scrollable
      >
        <div className="h-full  mx-auto px-24 py-8 ">
          <PlanSelectCard
            profile={profile}
            setPlan={(plan) => {
              setSelectedPlan(plan);

              // setCompetencies(plan.Competencies ? plan.Competencies : []);
              setSelectedCompetency(
                plan && plan.Competencies ? plan.Competencies[0] : null
              );
              setImpediments(
                plan && plan.Competencies ? plan.Competencies.impediments : []
              );
            }}
            plans={allPlans}
            // addToMyPlan={addToMyPlan}
            selectPlan={(plan) => {
              setSelectedPlan(plan);
              setSelectedCompetency(
                plan && plan.Competencies ? plan.Competencies[0] : null
              );
              setImpediments(
                plan && plan.Competencies ? plan.Competencies[0] : null
              );
            }}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="select-competency-modal"
        modalOpen={createCompetencyModalOpen}
        setModalOpen={createCompetencyModalOpen}
        title="Select a competency"
        showClose={false}
        showFooter={true}
        // closeButtonText="Close"
        handleConfirmClick={() => setCreateCompetencyModalOpen(false)}
        // selectPlan={selectPlan}
        OKbuttonText="Close this window"
        CancelButton={false}
        cleanUpState={null}
        size={"lg"}
        scrollable={true}
      >
        <div className="mx-auto px-24 py-8 h-64">
          <Competencies
            modal={true}
            plan={selectedPlan}
            updatePlan={(added) => {
              setSelectedPlan(added);
              sessionStorage.setItem(
                `${profile.tenantId}-plan`,
                JSON.stringify(added)
              );
              // sessionStorage.setItem("selectedPlan", JSON.stringify(added));
            }}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="add-impediment-modal"
        modalOpen={impedimentModalOpen}
        setModalOpen={() => {
          setImpedimentModalOpen(false);
          // setKey((prevKey) => prevKey + 1); // Increment the key
        }}
        title={
          selectedCompetency?.competencyId !== null
            ? "Impediments related to " +
              (selectedCompetency?.competencyId.Area +
                " - " +
                selectedCompetency?.competencyId.Name)
            : "Impediments"
        }
        showClose={true}
        showFooter
        OKbuttonText="Close this window"
        handleConfirmClick={() => setImpedimentModalOpen(false)}
        scrollable={true}
        size="lg"
      >
        <AssessmentTemplateQuestionEditor
          // key={key}
          assessment_type={"impediment"}
          selectedCompetency={selectedCompetency?.competencyId || null}
          questions={[]}
          from={"competencies"}
          addQuestionRef={null} //{addQuestionRef}
          refreshQuestions={addImpedimentToCompetency} //{refreshQuestions}
          setQuestions={() => addImpedimentToCompetency()} //{setQuestions}
          modalItem={null}
          modalOpen={impedimentModalOpen}
          title={"Impediment "}
          description={"Add a new impediment below"}
          competencies={competencyListOptions}
        />
      </ModalBasic>
      <ModalBasic
        id="select-impediment-modal"
        modalOpen={selectImpedimentModalOpen}
        setModalOpen={() => setSelectImpedimentModalOpen(false)}
        title={
          selectedCompetency?.competencyId !== null
            ? "Impediments related to " +
              (selectedCompetency?.competencyId.Area +
                " - " +
                selectedCompetency?.competencyId.Name)
            : "Impediments"
        }
        showClose={true}
        showFooter
        OKbuttonText="Close this window"
        handleConfirmClick={() => setSelectImpedimentModalOpen(false)}
        scrollable={true}
        size="lg"
      >
        <Impediments />
      </ModalBasic>
    </>
  );
}
