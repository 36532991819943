import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition";
import Button from "./Button";
import DropdownImage from "../images/logo.jpg";
import DropdownImage01 from "../images/logo.jpg";
import DropdownImage02 from "../images/channel-02.png";
import DropdownImage03 from "../images/channel-03.png";
import {
  ArrowDownIcon,
  ArrowSmDownIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";

function DropdownFilters({
  align,
  buttonText,
  buttonIcon,
  buttonProperties,
  options,
  handleOptionSelect,
  defaultValue,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [option, setTenant] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [selected, setSelected] = useState(
    options.find((o) => o.value === defaultValue)
  );

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const handleSelect = (option) => {
    setSelected(option);
    handleOptionSelect(option);
    setDropdownOpen(false);
  };

  // useEffect(() => {
  //   setSelected({ id: profile.tenantId, name: profile.tenantName });
  // }, []);

  // useEffect(() => {
  //   tenantsToShow.sort((a, b) =>
  //     a.tenantId.tenantTitle.toLowerCase() >
  //     b.tenantId.tenantTitle.toLowerCase()
  //       ? 1
  //       : -1
  //   );
  //   setTenantList(tenantsToShow);
  // }, [tenantsToShow]);

  // // close on click outside
  // useEffect(() => {
  //   const clickHandler = ({ target }) => {
  //     if (!dropdown.current) return;
  //     if (
  //       !dropdownOpen ||
  //       dropdown.current.contains(target) ||
  //       trigger.current.contains(target)
  //     )
  //       return;
  //     setDropdownOpen(false);
  //   };
  //   document.addEventListener("click", clickHandler);
  //   return () => document.removeEventListener("click", clickHandler);
  // });

  // // close if the esc key is pressed
  // useEffect(() => {
  //   const keyHandler = ({ keyCode }) => {
  //     if (!dropdownOpen || keyCode !== 27) return;
  //     setDropdownOpen(false);
  //   };
  //   document.addEventListener("keydown", keyHandler);
  //   return () => document.removeEventListener("keydown", keyHandler);
  // });

  return (
    <div className="relative w-48">
      <Button
        color={buttonProperties.type}
        ref={trigger}
        fullWidth
        className="flex items-center justify-start"
        // className="w-24 justify-center items-center px-4 py-4 border border-gray-800 leading-5 font-medium "
        aria-haspopup="true"
        handleClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {/* <div className=" truncate align-center"> */}
        {selected && selected.icon && selected.icon}
        <div className="text-base">{selected && selected.name}</div>

        {/* </div> */}
      </Button>
      <Transition
        className={`origin-top-right z-50 absolute top-full min-w-60 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <ul
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className="overflow-y-auto max-h-96  scrollbar-thin scrollbar-thumb-slate-300 
          scrollbar-track-slate-100  scrollbar-track-rounded-full 
          scrollbar-thumb-rounded-full"
        >
          {options.map((option) => {
            return (
              <li className="py-1">
                <a
                  className="font-medium text-sm text-slate-600  block py-1.5 px-3"
                  href="#0"
                  onClick={() => handleSelect(option)}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center truncate">
                      {option.icon}
                      <div className="truncate">{option.name}</div>
                      {defaultValue && option.value == defaultValue && (
                        <span className="ml-2 text-xs text-gray-500 font-normal">
                          (default)
                        </span>
                      )}
                    </div>

                    {selected.value === option.value && (
                      <svg
                        className="w-3 h-3 shrink-0 fill-primary-900 text-primary-800 ml-2"
                        viewBox="0 0 12 12"
                      >
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                    )}
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </Transition>
    </div>
  );
}

export default DropdownFilters;
