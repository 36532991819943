/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
// import Logo from "../SVG/logowhite.svg";
import {
  CogIcon,
  CollectionIcon,
  HomeIcon,
  MenuAlt2Icon,
  PhotographIcon,
  PlusSmIcon,
  UserGroupIcon,
  ViewGridIcon,
  XMarkIcon,
  UsersIcon,
  BadgeCheckIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import logo from "../images/logo.jpg";
import HeaderLinks from "../partials/header/HeaderLinks";
import { Icon } from "@iconify/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NarrowSidebar({ navigationItems, profile }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  // const navigationItems = [
  //   {
  //     name: "Dashboard",
  //     icon: (
  //       <HomeIcon
  //         className="mr-4  h-6 w-6 text-primary-300 "
  //         aria-hidden="true"
  //       />
  //     ),
  //     href: "/home",
  //     current: true,
  //   },
  //   // {
  //   //   name: "People",
  //   //   icon: (
  //   //     <UsersIcon
  //   //       className="mr-4 flex-shrink-0 h-6 w-6 text-primary-300"
  //   //       aria-hidden="true"
  //   //     />
  //   //   ),
  //   //   href: "/profiles",
  //   //   count: 4,
  //   //   current: false,
  //   // },

  //   {
  //     name: "Audit",
  //     icon: (
  //       <BadgeCheckIcon
  //         className="mr-4 flex-shrink-0 h-6 w-6 text-primary-300"
  //         aria-hidden="true"
  //       />
  //     ),
  //     href: "/audit",
  //     count: 3,
  //     current: false,
  //   },
  //   {
  //     name: "Feature Flag",
  //     icon: (
  //       <FlagIcon
  //         className="mr-4 flex-shrink-0 h-6 w-6 text-primary-300"
  //         aria-hidden="true"
  //       />
  //     ),
  //     href: "/flags",
  //     count: 3,
  //     current: false,
  //   },

  //   // ,
  //   // {
  //   //   name: "Settings",
  //   //   icon: (
  //   //     <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
  //   //       <path
  //   //         className={`fill-current text-white-600 ${
  //   //           pathname.includes("settings") && "text-primary-500"
  //   //         }`}
  //   //         d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
  //   //       />
  //   //       <path
  //   //         className={`fill-current text-gray-400 ${
  //   //           pathname.includes("settings") && "text-primary-300"
  //   //         }`}
  //   //         d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
  //   //       />
  //   //       <path
  //   //         className={`fill-current text-white-600 ${
  //   //           pathname.includes("settings") && "text-primary-500"
  //   //         }`}
  //   //         d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
  //   //       />
  //   //       <path
  //   //         className={`fill-current text-gray-400 ${
  //   //           pathname.includes("settings") && "text-primary-300"
  //   //         }`}
  //   //         d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
  //   //       />
  //   //     </svg>
  //   //   ),
  //   //   href: "/settings",
  //   //   count: 0,
  //   //   current: false,
  //   // },
  // ];
  const typeIcon = (type) => {
    switch (type) {
      case "home":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        );
      case "dashboard":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
            />
          </svg>
        );
      case "employees":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        );
      case "regulation":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
            />
          </svg>
        );
      case "assessment":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            />
          </svg>
        );
      case "learning":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
            />
          </svg>
        );
      case "marketplace":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
            />
          </svg>
        );
      case "support":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        );
    }
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="h-full flex">
        {/* Narrow sidebar */}
        <div className="hidden w-28 bg-primary-700 overflow-y-auto md:block">
          <div className="w-full py-2 flex flex-col items-center">
            <div className="flex-shrink-0 flex items-center text-white">
              <img
                className=" px-5 w-24   text-primary-500  "
                src={logo}
                alt=""
              />
            </div>
            <div className="flex-1 mt-6 w-full px-2 space-y-1">
              {navigationItems.map(
                (item) =>
                  item.show && (
                    <NavLink
                      to={
                        item.status
                          ? item.status == "coming soon"
                            ? "#"
                            : item.href
                          : item.href
                      }
                      key={item.name}
                      className={classNames(
                        item.status == "coming soon"
                          ? "cursor-not-allowed bg-primary-600 hover:bg-primary-600 text-primary-200 text-opacity-25"
                          : pathname === item.href ||
                            (item.href == "/dashboard" &&
                              pathname == "/home" &&
                              profile.tenantInfo.type == "admin")
                          ? "bg-primary-800 text-white"
                          : "text-primary-100 hover:bg-primary-800 hover:text-white",
                        "group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium"
                      )}
                      aria-current={pathname === item.href ? "page" : undefined}
                    >
                      {item.icon && (
                        <div
                          className={classNames(
                            item.status == "coming soon"
                              ? "text-primary-100 text-opacity-25"
                              : pathname === item.href
                              ? "text-white"
                              : "text-primary-300 group-hover:text-white",
                            "items-center"
                          )}
                          aria-hidden="true"
                        >
                          {
                            item.iconify ? (
                              <Icon icon={item.iconify} className=" h-8 w-8" />
                            ) : // className="my-auto h-5 w-5 text-emerald-500"
                            // />
                            null
                            // <span>{typeIcon(item.icon)}</span>
                          }
                        </div>
                      )}

                      <div className="mt-2 text-center ">{item.name}</div>
                    </NavLink>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
