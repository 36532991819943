import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import Logo from "../images/logo.jpg";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import { _assessment } from "../modules/_assessment";
import { usePersistStore } from "../store/usestore";
import Button from "../components/Button";

function VerifyGuest(props) {
  const mvp = false;
  const history = useHistory();
  const { verifyGuestLogin } = usePersistStore();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [name, setName] = useState();
  const { token, userId, email, tenantId, type, inviteId } = useParams();
  const [loader, setLoader] = useState(false);

  const verifyGuest = async (event) => {
    setLoader(true);
    event.preventDefault();

    let path = "/home";

    let profile = await verifyGuestLogin(
      userId,
      token,
      name,
      email,
      tenantId,
      type,
      inviteId,
      "",
      history
    );
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="max-w-sm mx-auto px-4">
              <img className="max-w-18 mx-auto px-4 py-8" src={Logo} />
            </div>
            <div className="max-w-sm mx-auto px-4 ">
              <h1 className="text-2xl text-slate-800 font-bold mb-6">
                Enter your name to continue.
              </h1>
              {/* Form */}
              <form onSubmit={verifyGuest}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Name
                    </label>
                    <input
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-input w-full"
                      type="text"
                      autoComplete="on"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <Button
                    fullWidth
                    enabled={!loader}
                    loader={loader}
                    type="submit"
                    size="lg"
                    className="btn btn-primary"
                    // handleClick={null}
                  >
                    Begin Assessment
                  </Button>
                  {/* {loader ? (
                    <div class="flex justify-center items-center">
                    <img
                      className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                      src={Spinner}
                      width="32"
                      height="32"
                      alt="loading.."
                    />
                    </div>
                  ) : (
                    <button
                    type="submit"
                    class="btn bg-primary-500 hover:bg-primary-600 text-white ml-3"
                    // onClick={auth}
                  >
                    Verify
                  </button>
                  )} */}
                </div>
              </form>

              {/* Footer */}
              {isAuthenticated && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Your token is Invalid - Please contact admin.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default VerifyGuest;
