import { useEffect } from "react";
import { useState } from "react";
import QuestionToolTip from "../../components/QuestionToolTip";
import { utils } from "../../modules/_utils";
import { UserGroupIcon } from "@heroicons/react/20/solid";
import { Icon } from "@iconify/react";
import Tooltip from "../../components/Tooltip";
import Tabs from "../component/Tabs";
import { Tab } from "@headlessui/react";
import ImpedimentsCommentsPanel from "./ImpedimentsCommentsPanel";
import { _comments } from "../../modules/_comments";

export function RenderImpedimentSidebar(
  {
    from,
    readOnly,
    row,
    dedupeMode,
    updateData,
    profile,
    renderSubComponent,
    renderSubComponent1,
    setUpdatedId,
    RenderTextField,
    RenderTeamName,
    RenderNewReportedByTeams,
    RenderImpactedTeams,
    RenderCompetencyList,
    RenderImpedimentTypeList,
    RenderNumberColumn,
    RenderActions,
    RenderScopeList,
    renderActionPlanRTFEditor,
  },
  props
) {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(dedupeMode ? true : false);
  const [selectedTab, setSelectedTab] = useState("Details");
  const [commentCount, setCommentCount] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setShow(dedupeMode ? true : false);
  }, [dedupeMode]);

  useEffect(() => {
    setData(row);
    // setSelectedTab("Details");
  }, [row]);

  useEffect(() => {
    // setData(row);
    setSelectedTab("Details");
  }, [row?._id]);

  useEffect(() => {
    (async () => {
      let count = await _comments.getCommentCountByImpediment(row._id);
      setCommentCount(count);
    })();
  }, [row]);

  const updateCommentCount = (action) => {
    let newCount = commentCount;
    if (action == "add") {
      newCount = commentCount + 1;
    } else {
      newCount = commentCount - 1;
    }
    setCommentCount(newCount);
  };

  return (
    <aside
      //translate x from 0 to 100% to show and hide
      className={`w-[33vw] pt-0 pb-2   z-49
      transition-all duration-500 ease-in-out 
      transform ${show && show == true ? "translate-x-0" : "translate-x-full"}
      
h-full
    bg-gray-50 relative  bottom-0 right-0 top-0
    hidden 
    overflow-y-inherit border-l border-gray-200 xl:block scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
    >
      {data && Object.keys(data).length > 0 && (
        <div className="flex px-4 py-1 items-center bg-white border-b border-gray-200 justify-between  text-xs ">
          <Tabs
            // tabs={tabs || []}
            tabs={[
              {
                name: "Details",
                current: selectedTab,
                visible: true,
              },
              {
                name: "Comments",
                current: selectedTab,
                count: commentCount || 0,
                icon: "mdi:comment-outline",
                visible: true,
              },
              {
                name: "Action Plan",
                current: selectedTab,

                icon: "mdi:clipboard-list-outline",

                visible: from && from == "planbuilder",
              },
            ]}
            selectedTab={selectedTab}
            setSelectedTab={(tab) => {
              setSelectedTab(tab);
            }}
          />
        </div>
      )}
      {data && Object.keys(data).length > 0 ? (
        <div className="">
          <div id="sidebar-tab-container" className="sm:px-2 lg:px-4 xl:px-6">
            {selectedTab && selectedTab == "Details" && (
              <div id="sidebar-tab-content">
                <div
                  id="first_row"
                  className="flex justify-between pt-2 text-xs "
                >
                  <div className="flex items-center">
                    {row &&
                    row.is_team_specific &&
                    row.is_team_specific !== undefined &&
                    row.is_team_specific === true ? (
                      <Tooltip
                        title="Team Specific"
                        description="Only your team can see this impediment."
                      >
                        <Icon
                          icon="mdi:user-lock"
                          className="flex h-4 w-4 text-indigo-500 mr-3"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Public"
                        description="Any team can see this impediment and indicate that they have the same problem."
                        position={"bottom-right"}
                        size={"md"}
                      >
                        <UserGroupIcon className="flex h-4 w-4 text-indigo-500 mr-3" />
                      </Tooltip>
                    )}
                    IM-{row && row.Index}
                  </div>

                  <div className="flex items-center">
                    {RenderActions(row, updateData, setUpdatedId)}
                  </div>
                </div>
                <h2 className="pt-1 text-xl font-medium text-gray-900">
                  {row && row.Title}
                </h2>
                {!readOnly && (
                  <div
                    id="second_row"
                    className="flex flex-col justify-between mt-3 text-sm "
                  >
                    <div className="flex font-semibold">
                      Reported By
                      <QuestionToolTip
                        field={"Reported By"}
                        text={
                          "This will list all teams who have reported having this impediment."
                        }
                        position={"bottom"}
                      />
                    </div>
                    <div className="flex whitespace-nowrap -mt-3">
                      {data && RenderNewReportedByTeams(data)}
                    </div>
                  </div>
                )}
                <div
                  id="burden_effort"
                  className=" grid grid-cols-4 justify-start pt-4 text-sm "
                >
                  {" "}
                  <div className="col-span-2 flex">
                    <div className="flex flex-col ">
                      <p className="flex  text-sm font-medium text-slate-800 mb-2">
                        Competency{" "}
                        <QuestionToolTip
                          field={"Competency"}
                          text={
                            "This is the competency that is most impacted by this impediment."
                          }
                        />
                      </p>
                      {readOnly ? (
                        data &&
                        data.Competency &&
                        data.Competency.Area + "-" + data.Competency.Name
                      ) : (
                        <RenderCompetencyList row={data} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-center">
                    <div>
                      <p className="flex  text-sm font-medium text-slate-800 mb-2">
                        Burden{" "}
                        <QuestionToolTip
                          field={"Burden"}
                          text={
                            "How many hours per week are wasted on this impediment?"
                          }
                        />
                      </p>

                      {RenderNumberColumn(
                        row,
                        // handleTextColumnValueChange2,
                        // handleOnBlur,
                        "score"
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-center">
                    <div>
                      <p className="flex  text-sm font-medium text-slate-800 mb-2">
                        Effort{" "}
                        <QuestionToolTip
                          field={"Effort"}
                          text={
                            "How many days of effort required to resolve this impediment?"
                          }
                        />
                      </p>

                      {RenderNumberColumn(
                        row,
                        // handleTextColumnValueChange2,
                        // handleOnBlur,
                        "effort"
                      )}
                    </div>
                  </div>
                </div>
                {/* <div
                  id="status_section"
                  className="flex justify-between pt-4 text-sm "
                >
                  <div>
                    <div className="flex items-center font-medium">
                      Impacted Teams
                    </div>
                    <div className="flex items-center">
                      {data && RenderImpactedTeams(data)}
                    </div>
                  </div>
                </div> */}
                <div
                  id="description_section"
                  className="flex  flex-col justify-between pt-4 text-sm "
                >
                  <div className="flex items-center font-semibold">
                    Description
                  </div>
                  <div className="flex items-center">
                    {data && renderSubComponent(data, "Description")}
                  </div>
                </div>
                <div
                  id="categories_section"
                  className="flex justify-start  space-x-6 pt-4 text-sm "
                >
                  <div className="flex flex-col ">
                    <div className="flex font-semibold mb-2">
                      Impediment Type
                    </div>
                    <div className="flex">
                      {readOnly
                        ? data.Category
                        : RenderImpedimentTypeList(data, profile)}
                    </div>
                  </div>
                  <div className="flex flex-col ">
                    <div className="flex font-semibold mb-2">
                      Impediment Scope
                    </div>
                    <div className="flex">{data && RenderScopeList(data)}</div>
                  </div>
                </div>

                {from && from == "planbuilder" && (
                  <div
                    id="plan_section"
                    className="flex  flex-col h-full  pt-4 text-sm "
                  >
                    <div className="flex items-center font-semibold">
                      Action Plan
                    </div>
                    <div className="flex items-center h-full  ">
                      {data && renderSubComponent1(data, "action_plan_text")}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>Select an impediment to view details</div>
      )}

      {selectedTab && selectedTab == "Comments" && row && row._id && (
        <div id="sidebar-tab-comments" className="sm:px-2 lg:px-4 xl:px-6">
          <ImpedimentsCommentsPanel
            row={row}
            impedimentId={row._id}
            updateCommentCount={updateCommentCount}
          />
        </div>
      )}
      {selectedTab && selectedTab == "Action Plan" && row && row._id && (
        <div id="sidebar-tab-action-plan" className="sm:px-2 lg:px-4 xl:px-6">
          {from && from == "planbuilder" && (
            <div
              id="plan_section"
              className="flex  flex-col justify-between pt-4 text-sm "
            >
              <div className="flex items-center font-semibold">Action Plan</div>
              <div className="flex items-center">
                {/* {data && renderSubComponent1(data, "action_plan_text")} */}
                {data && renderActionPlanRTFEditor(data, "action_plan_rtf")}
              </div>
            </div>
          )}
        </div>
      )}
    </aside>
  );
}
