import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Button from "../components/Button";
import Input from "../components/Input";
import { useParams } from "react-router-dom";
import Skeleton from "../components/Skeleton";

function AcceptInvite() {
  // const navigate = useNavigate();
  // const { register } = useAuthStore();
  const history = useHistory();

  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const mvp = false;

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [otp, setOtp] = useState();

  const { token, id, tenantId } = useParams();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      setLoader(true);

      let profile = await _auth.acceptInvite(id, token, tenantId);

      if (profile === false) {
        setLoader(false);
        setIsAuthenticated(true);
      } else {
        setLoader(false);
        // history.push("/");
      }
    })();
  }, []);

  const handleClick = (event) => {
    // event.preventDefault();
    history.push("/signin");
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"></div>
            </div>

            {loader ? (
              <Skeleton type={loader} />
            ) : (
              <div className="max-w-md mx-auto px-4 py-8">
                <h1 className="text-3xl text-slate-800 font-bold mb-6">
                  You've accepted this invite!
                </h1>
                <div className="text-sm">
                  Go to the login page to get started!
                </div>

                {/* Form */}

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                  {/* <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}

                  {/* </div> */}
                  <div className="flex items-center justify-between mt-5">
                    {signUpErrorMessage !== "" && (
                      <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                        {signUpErrorMessage}
                      </div>
                    )}
                  </div>
                  <div className="mt-5">
                    <Button
                      // enabled={!loader}
                      loader={loading}
                      type="submit"
                      className="btn btn-primary"
                      // onClick={onRegister}
                      handleClick={(e) => handleClick(e.target.value)}
                    >
                      Back to Sign In
                    </Button>
                  </div>
                </div>
                {/* Footer */}
                <div className="pt-5 mt-6 border-t border-gray-200"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default AcceptInvite;
