import React from "react";
import { Link } from "react-router-dom";
import LineChart from "../../charts/LineChart01";
import Icon from "../../images/icon-01.svg";
import EditMenu from "../../components/DropdownEditMenu";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";

import Skeleton from "../../components/Skeleton";
import Steps from "../../components/Steps";
import StepsBullets from "../../components/StepsBullets";

function DashboardMaturityCard(props) {
  const loading = props.loading;
  const data = props.data;
  const unfinished = props.unFinishedAssessments;
  const scores = props.scores;
  const total = props.total;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const shadow = props.shadow ? props.shadow : true;
  const [steps, setSteps] = React.useState([{}]);
  // const [loading, setLoading] = React.useState(props.loading);
  const [updated, setUpdated] = React.useState(false);

  React.useEffect(() => {
    let stepOptions = [];
    // setLoading(true);
    if (data == "Beginner") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "current" },
        { name: "Intermediate", href: "#", status: "upcoming" },
        { name: "Advanced", href: "#", status: "upcoming" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Intermediate") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "current" },
        { name: "Advanced", href: "#", status: "upcoming" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Advanced") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "complete" },
        { name: "Advanced", href: "#", status: "current" },
        { name: "Expert", href: "#", status: "upcoming" },
      ];
    } else if (data == "Expert") {
      stepOptions = [
        { name: "Beginner", href: "#", status: "complete" },
        { name: "Intermediate", href: "#", status: "complete" },
        { name: "Advanced", href: "#", status: "complete" },
        { name: "Expert", href: "#", status: "current" },
      ];
    }

    //re-render after updating steps state

    setSteps(stepOptions);
  }, [data]);

  const stepData = steps;
  return (
    <div
      className={`flex  flex-col  sm:col-span-12 md:col-span-12 md:col-span-12  xl:col-span-${colSpan}  grow-0 row-span-${rowspan} bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <div className="px-5 pt-5 pb-5">
        <header className="flex justify-start items-start mb-0">
          {/* Icon */}
          {/* <img
            className="inline"
            src={Icon}
            width="32"
            height="32"
            alt="Icon 01"
          /> */}
          <h2 className="text-lg font-semibold text-slate-800 mb-2 ml-0">
            {props.title}
          </h2>
          {/* Menu button */}
          {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 1
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 2
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3"
                to="#0"
              >
                Remove
              </Link>
            </li>
          </EditMenu> */}
        </header>
        {loading ? (
          // <Skeleton type="spinner" color="gray" fill="purple" />
          <Skeleton type="spinner" />
        ) : (
          <div className="">
            <div className="flex text-3xl font-bold text-slate-800 mr-2 mb-2">
              {data}
            </div>
            <div className="text-3xl font-bold text-slate-800 mr-2">
              <StepsBullets steps={stepData} />
            </div>
          </div>
        )}
      </div>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {/* <div className="grow">
       
        <LineChart data={chartData} width={389} height={128} />
      </div> */}
    </div>
  );
}

export default DashboardMaturityCard;
