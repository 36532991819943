import { useState, useEffect } from "react";
import AccordionBasic from "./AccordionBasic";
import Tabs from "../pages/component/Tabs";

/*  

To Do: 
1. Create editor for existing policy tracker
https://zenoamaro.github.io/react-quill/

2. UI to Add/Delete policies

3. Clean up current policy tracker meta data

4. Confirm what is missing from current policy tracker meta data

*/

export default function StatePolicyViewer(props) {
  const modalItem = props.modalItem;

  const [loading, setLoading] = useState(true);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTabItem] = useState();

  const setSelectedTab = (item, index) => {
    setSelectedTabItem(item);
    setSelectedTabIndex(index);
  };

  useEffect(() => {
    if (modalItem.length > 0) {
      let tempTabs = [];
      modalItem.map((item, index) => {
        let title = item.title == undefined ? "Default" : item.title;
        tempTabs.push({
          name: title,
          current: index == 0 ? true : false,
        });
      });

      //
      setTabs(tempTabs);
      setSelectedTab(tempTabs[0].name);
      setSelectedTabIndex(0);
    }
    setLoading(false);
  }, [modalItem]);

  //   const modalTabs = loading ? [] : tabs;

  useEffect(() => {
    //when selected Tab changes, update selected accordion item
  }, [selectedTab]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full p-3">
        {tabs.length > 0 && (
          <Tabs
            tabs={tabs}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        )}
      </div>
      <div className="flex flex-col w-full">
        {Object.keys(modalItem[selectedTabIndex].accordionItems[0]).map(
          (key, index) => {
            return (
              <AccordionBasic
                key={index + key}
                title={key}
                content={modalItem[selectedTabIndex].accordionItems[0][key]}
                index={index}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: modalItem[selectedTabIndex].accordionItems[0][key],
                  }}
                />
              </AccordionBasic>
            );
          }
        )}
      </div>
    </div>
  );
}
