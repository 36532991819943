import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const _teams = {
  async getTeams() {
    let profile = _auth.getUserProfile();

    let data;
    data = {
      companyId: profile.companyId,
    };

    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("teams/get-teams", data);

    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },
  async getTeamNicknames() {
    let profile = _auth.getUserProfile();

    let data;
    data = {
      companyId: profile.companyId,
      all: true,
    };

    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("teams/get-teams", data);

    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },
  async getTeamsAdminView(export_data) {
    let profile = _auth.getUserProfile();

    let data;
    data = {
      companyId: profile.companyId,
      export_data: export_data,
    };

    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("tenants/get-admin-tenant-view", data);

    if (response) {
      return response.data.data.tenants;
    } else {
      return [];
    }
  },
};
