import React, { useEffect } from "react";
import { useState, useHistory } from "react";
import SearchForm from "../partials/actions/SearchForm";
import Tabs from "../pages/component/Tabs";
import { _marketplace } from "../modules/_marketplace";
import MarketplaceCard from "./MarketplaceCard";
import ModalBasic from "./ModalBasic";
import { _auth } from "../modules/_auth";
import ContactConfirm from "./ContactConfirm";
import Skeleton from "./Skeleton";

function MarketplacePanel(props) {
  let data = props.data;
  //

  const tabs = _marketplace.getMarketplaceTabs();

  const [selectedTab, setSelectedTab] = useState(props.selectedTab);
  const [showModal, setShowModal] = useState(false);
  const [companyValues, setCompanyValues] = useState();
  const [vendor_email, setVendorEmail] = useState();

  const setModalOpen = () => {
    setShowModal(false);
  };
  const getMarketplaceCards = () => {
    //To do: if selectedTab is View All, return all marketplace cards
    // else return marketplace cards filtered by selectedTab
    if (selectedTab === "View All") {
      return data.map((item, index) => (
        <MarketplaceCard
          key={index}
          data={item}
          setShowModal={setShowModal}
          setModalOpen={setModalOpen}
          setCompanyValues={setCompanyValues}
          setVendorEmail={setVendorEmail}
        />
      ));
    } else {
      return data
        .filter((item) => item.category.includes(selectedTab))
        .map((item, index) => (
          <MarketplaceCard
            key={index}
            data={item}
            setShowModal={setShowModal}
            setModalOpen={setModalOpen}
            setCompanyValues={setCompanyValues}
            setVendorEmail={setVendorEmail}
          />
        ));
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Marketplace
          </h1>
        </div>
      </div>

      <div className="mb-6">
        {/* Tabs */}
        <div className="mb-5">
          <Tabs
            tabs={tabs}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        </div>
      </div>

      {/* Marketplace Cards */}

      {data === "loading" ? (
        <Skeleton type="loader" />
      ) : (
        <section className="pb-6 border-b border-gray-200">
          <div className="grid grid-cols-12 gap-6">
            {/* Card Component */}
            {getMarketplaceCards(data)}
          </div>
        </section>
      )}
      {/* <ContactConfirm /> */}
      <ModalBasic
        id="marketplace_modal"
        modalOpen={showModal}
        setModalOpen={setModalOpen}
        title={"Connect with " + companyValues}
        showClose={false}
      >
        <ContactConfirm
          setModalOpen={setModalOpen}
          company={companyValues}
          email={_auth.getUserProfile().email}
          vendor_email={vendor_email}
          type="marketplace"
        />
      </ModalBasic>
    </div>
  );
}

export default MarketplacePanel;
