/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useState } from "react";

import HeroImage from "../../images/hero-cover1.jpg";

import { Hero } from "./parts/Hero";

// import HubsForm from "components/Hubsform";

import CenterBrandedCTA from "./parts/CenterBrandedCTA";
import CTASplitWithImage from "./parts/CTASplitWithImage";
import StatsLarge from "./parts/StatsLargeDark";
import FeatureList from "./parts/FeatureList";
import SecondaryFeatures from "./parts/SecondaryFeatures";
// import PartnerForm from "./parts/FormBannerPartner";
import ModalBasic from "../../components/ModalBasic";
import NotificationBanner from "../../components/NotificationBanner";
// import { _pricing } from "../modules/pricing";
import FeatureGrid from "./parts/FeatureGrid";
import ThreeColumnOnDark from "./parts/ThreeColumnOnDark";
import FourColumnStats from "./parts/FourColumnStats";
import { useHistory } from "react-router-dom";
// import { exec } from "child_process";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SPA() {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //   const pricing = _pricing.getPricing();
  const setModalOpen = () => {
    setShowModal(false);
  };
  const history = useHistory();
  const handleBannerClose = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    setOpen(false);
  };
  // const hubsForm = useRef();
  const executeScroll = (e) => {
    const element = document.getElementById(e);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };

  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}

        <Hero
          title="Welcome to JDO Connect"
          subtitle="Reach new heights in your DevOps Journey."
          image={HeroImage}
          description="From basecamp to summit, through the valleys and over the peaks, we're here to help you reach new heights in your DevOps journey.  "
          buttons=""
          executeScroll={executeScroll}
          setShowModal={setShowModal}
        />
        {/* Logo Cloud */}
        {/* <SmallBanner
          description={
            "BreachLink is designed to help enterprises and small businesses understand their security and compliance posture."
          }
        /> */}
        {/* <SmallBanner
          description={
            "Ready to make security assessments part of your offering? We've got you covered with reseller, whitelabel, and integration partner programs!"
          }
          partner={true}
          executeScroll={executeScroll}
          setShowModal={setShowModal}
        /> */}
        <div id="vetBanner"></div>
        {/* <HubsForm id="hubsForm" /> */}
        {/* <div
            style={{ maxHeight: "290px" }}
            className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8  overflow-hidden"
          >
            <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
              Built by veterans in eDiscovery, CyberSecurity, Privacy, and
              Breach
            </p>
          </div> */}
        {/* </div> */}
        {/* <SinglePriceWithDetails /> */}
        {/* <HubsForm id="hubsForm" /> */}
        <FeatureList id={"features"} />
        {/* <ThreePartFeature /> */}
        <StatsLarge />
        {/* <ThreeColumnOnDark variant={"light"} /> */}
        {/* <CenterBrandedCTA
          text={"Ready to get started?"}
          buttonText={"Get started"}
          executeScroll={executeScroll}
        /> */}

        {/* <div id="pricing" className="bg-slate-50">
          <div className="relative">
            <div className="relative mx-auto max-w-2xl py-16 px-4 sm:px-6 lg:max-w-7xl lg:py-16 lg:px-8">
              <div className="relative">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-5xl">
                  Pricing plans for teams of all sizes
                </h1>
                <p className="mt-6 max-w-2xl text-xl text-gray-500">
                  Choose a plan that will grow as your needs do.
                </p>
              </div>
            </div>
            <PricingModule pricing={pricing} setShowModal={setShowModal} />
          </div>
        </div> */}
        {/* <CenterBrandedCTA
          text={"Ready to get started?"}
          buttonText={"Get started"}
          executeScroll={executeScroll}
    />*/}
        <FourColumnStats />
        {/* <CenterBrandedCTA
          text={"Ready to accelerate your journey?"}
          buttonText={"Go to your dashboard"}
          executeScroll={executeScroll}
          bottomText={""}
        /> */}
        <CTASplitWithImage executeScroll={executeScroll} />
        <SecondaryFeatures showImages={true} />
        <CenterBrandedCTA
          text={"Ready to accelerate your journey?"}
          buttonText={"Go to your dashboard"}
          executeScroll={() => {
            history.push("/home");
          }}
          bottomText={""}
        />
        {/* <FeatureGrid /> */}

        {/* CTA Section */}
        {/* <div className="bg-white">
          <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to get started?</span>
              <span className="-mb-1 pb-1 block bg-purple-600 bg-clip-text text-transparent">
                Join our waiting list.
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
              <button
                onClick={() => {
                  executeScroll("vetBanner");
                }}
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-purple-800 bg-purple-50 hover:bg-purple-100"
              >
                Get started
              </button>
            </div>
          </div>
        </div> */}
      </main>
      <ModalBasic
        id="partner_modal"
        modalOpen={showModal}
        setModalOpen={setModalOpen}
        title="Get in touch!"
      >
        {/* <PartnerForm id="partnerForm" /> */}
      </ModalBasic>
      {/* <ModalBasic
        id="client_modal"
        modalOpen={showClientModal}
        setModalOpen={setClientModalOpen}
        title="Join today!"
      >
        <HubsForm id="hubsForm" />
      </ModalBasic> */}
    </div>
  );
}
