// src/Table.js
// To do:
// - [ ] Show/Hide filters
// - [ ] Show/Hide columns
// - [ ] Re-order columns
// - [ ] Change sorting to alphanumeric
// - [ ] Implement infinite scrolling
// - [ ] Use server-side pagination instead of client-side
// - [ ] Add a "select all" checkbox
// - [ ] Add skeleton loader for table

import React from "react";
import { useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { classNames } from "../utils/Utils";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Button, PageButton } from "../components/PaginationButtons";
import Button2 from "../components/Button";
import DropdownFull from "./DropdownFull";
import ListBoxSelector from "./ListBoxSelector";
import ComboBoxSelector from "./ComboBoxSelector";
import { handleResetListBox } from "./ListBoxSelector";
import { Switch } from "@headlessui/react";

//3 TanStack Libraries!!!
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "react-table";
import {
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useVirtual } from "@tanstack/react-virtual";
import Skeleton from "./Skeleton";

export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="relative inline cursor-pointer items-center w-full">
      <div>
        {/* <label
          htmlFor="search"
          className="block text-sm font-medium text-slate-700"
        >
          Search
        </label> */}
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 pr-5 mr-3">
            <span className="text-slate-500 sm:text-sm">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-slate-400 cursor-pointer"
                aria-hidden="true"
              />
            </span>
          </div>

          <input
            id={"search"}
            type="text"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={"Search"}
            className={`block w-full rounded-md border-gray-300 pl-10 pr-12 
            hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] 
            hover:shadow-secondary-500 hover:border-secondary-500
            focus:border-secondary-500 focus:outline-none focus:ring-0
           
            sm:text-sm`}
          />
          <div className="absolute inset-y-0 right-3 flex items-center">
            {value === "" || value === undefined ? null : (
              <XMarkIcon
                onClick={() => {
                  setValue("");
                  onChange("");
                }}
                className="h-5 w-5 text-slate-400 cursor-pointer"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
  filters,
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const handleFilter = (e) => {
    if (e == "Filter by " + render("Header")) {
      setFilter(undefined);
    } else {
      setFilter(e);
    }
  };

  const options = React.useMemo(() => {
    const options = new Set();
    options.add("Filter by " + render("Header"));
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    // options.unshift("All");
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box

  return (
    // <div className="mr-5 w-96">
    // <ComboBoxSelector
    //   options={options}
    //   handleFilter={handleFilter}
    //   label={render("Header")}
    //   // value={filterValue}
    // />
    <ListBoxSelector
      options={options}
      handleFilter={handleFilter}
      label={render("Header")}
      filters={filters}
      // filters={filters}
    />
  );
}

export function Toggle({ value, onToggleChange, ...props }) {
  const [checked, setChecked] = React.useState(value);
  const onChange = (checked) => {
    setChecked(checked);
    onToggleChange(checked);
    //save choice in local storage
    localStorage.setItem("View all impediments", checked);
  };

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={checked}
        onChange={onChange}
        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full "
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className="pointer-events-none absolute h-full w-full rounded-md bg-white"
        />
        <span
          aria-hidden="true"
          className={classNames(
            checked ? "bg-secondary-600" : "bg-gray-200",
            "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-slate-900">View All</span>
        {/* <span className="text-sm text-slate-500">(Save 10%)</span> */}
      </Switch.Label>
    </Switch.Group>
  );
}

export function ToggleButtons({
  value,
  text1,
  text2,
  onToggleChange,
  ...props
}) {
  const [checked, setChecked] = React.useState(value);
  const handleClick = (checked) => {
    setChecked(checked);
    onToggleChange(checked);
    //save choice in local storage
    localStorage.setItem("View all impediments", checked);
  };
  return (
    <div className="w-full col-span-2 text-sm flex items-start justify-left   ">
      <span className=" isolate inline-flex ">
        <button
          onClick={(e) => handleClick(false)}
          type="button"
          value="table"
          className={`h-10 relative inline-flex items-center 
          
          shadow-slate-400
          rounded-l border-r-none border-l border-t border-b  ${
            checked !== true
              ? "bg-accent-600 border-accent-600 text-white shadow-sm shadow-slate-400 "
              : "bg-white text-slate-500 border-none shadow-sm shadow-slate-400 "
          } 
          
          px-4 py-2 text-sm font-medium  `}
        >
          {text1}
        </button>

        <button
          onClick={(e) => handleClick(true)}
          type="button"
          value="grid"
          className={`h-10 relative inline-flex items-center 
          shadow-sm
        
        rounded-r border-r border-t border-b  
        ${
          checked == true
            ? "bg-accent-600 border-accent-600 text-white shadow-sm shadow-slate-400"
            : "bg-white text-slate-500 shadow-sm shadow-slate-400 border-none "
        } px-4 py-2 text-sm font-medium  `}
        >
          {text2}
        </button>
      </span>
    </div>
  );
}

function Table(
  {
    columns,
    data,
    renderSubComponent,
    hasSubComponent,
    subComponentColSpan,
    onToggleChange,
    toggleValue,
    SubComponent,
    updatedId,
    loading,
    updateMyData,
    skipReset,
    autoResetGlobalFilter,
  },
  props
) {
  const colSpan = 3;
  const rowspan = 1;
  const showFooter = props.showFooter;
  const hasSub = hasSubComponent ? true : false;
  const subColSpan = subComponentColSpan ? subComponentColSpan : 5;
  updatedId = updatedId ? updatedId : null;
  loading = loading ? loading : false;
  toggleValue = toggleValue ? toggleValue : false;
  data = data ? data : [];

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    //new
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows, // new
    setGlobalFilter,
    setAllFilters,
    state, // new
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["Claimed By"],
      },
      autoResetPage: false,
      autoResetGlobalFilter: false,
      autoResetAll: false,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination // new,
    //resize
  );

  const [filters, setFilters] = React.useState([]);

  return (
    <>
      {/* <div className="w-full mb-4"> */}
      <div className="relative flex items-center px-3 mb-2 gap-2 grid grid-cols-8 sm:grid-cols-12">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div
                key={column.id}
                className="relative flex items-center col-span-2"
              >
                {column.render("Filter", { filters })}
              </div>
            ) : null
          )
        )}
        <div className="relative flex items-center col-span-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>

        <div className="grid grid-flow-col col-start-12 auto-cols-max justify-end  gap-2">
          {/* Check if there are any filters applied */}
          {state.filters.length > 0 && (
            <Button2
              handleClick={() => {
                setAllFilters([]);
                setFilters([]);
              }}
              rounded
              size="sm"
              color="accent"
            >
              {" "}
              Clear Filters
            </Button2>
          )}
        </div>
      </div>

      <div
        style={{ height: "calc(100vh - 335px)" }}
        className="border-t border-gray-300 overflow-x-auto overflow-y-auto 
        overflow-x-scroll scrollbar-thin scrollbar-thumb-slate-300 
        scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full "
      >
        <table
          className="min-w-full divide-y divide-gray-200 table-fixed"
          {...getTableProps()}
        >
          <thead className="sticky  z-10 top-0 text-xs font-semibold uppercase text-slate-500 bg-gray-100 border-b  border-gray-300">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  //console.log column

                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props

                  <th
                    scope="col"
                    className="px-6 py-3  text-left text-xs font-medium text-slate-500 uppercase tracking-wider"
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                    style={{
                      width: column.minWidth,
                      maxWidth: column.maxWidth,
                      minWidth: column.minWidth,

                      position: column.sticky ? "sticky" : null,
                      right: "0px",
                      zIndex: column.sticky ? "1" : null,
                      backgroundColor: column.sticky ? "#e2e8f0" : null,
                      borderLeft: column.sticky ? "1px solid #e2e8f0" : null,
                      // Don't forget this, required for the stickiness
                    }}
                    // column.getSortByToggleProps({ title: undefined })
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ▼"
                          : " ▲"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {loading ? (
            <div
              className={`w-screen 
              grid min-w-full h-full m-auto"
             }`}
            >
              <div className="m-auto">
                <Skeleton type="spinner" color="gray" fill="purple" />
              </div>
            </div>
          ) : toggleValue == false && data.length == 0 ? (
            <tr colspan="5">
              <td colspan="5" className="p-10 font-medium text-slate-600">
                Your team has not claimed any impediments. Switch to company
                view and claim your impediments.
              </td>
            </tr>
          ) : (
            page.map((row, i) => {
              //replace rows with page

              prepareRow(row);
              return (
                <>
                  <tbody
                    className={`bg-white divide-y divide-gray-300 relative 
                  
                  `}
                    {...getTableBodyProps()}
                  >
                    <tr className="py-4" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        //

                        return (
                          <td
                            rowSpan={cell.column.sticky ? "2" : null}
                            style={{
                              // width: column.minWidth,
                              // maxWidth: column.maxWidth,
                              // minWidth: column.minWidth,
                              verticalAlign: cell.column.sticky ? "top" : null,
                              paddingTop: cell.column.sticky ? "27px" : null,
                              position: cell.column.sticky ? "sticky" : null,
                              right: "0px",
                              zIndex: cell.column.sticky ? "1" : null,
                              backgroundColor: cell.column.sticky
                                ? "#f9fafb"
                                : null,
                              rowSpan: cell.column.sticky ? "2" : null,
                              borderLeft: cell.column.sticky
                                ? "1px solid #eee"
                                : null,
                              boxShadow: cell.column.sticky
                                ? "-10px 0px 10px 1px #aaaaaa"
                                : null,
                              borderCollapse: cell.column.sticky
                                ? "separate"
                                : null,
                              // Don't forget this, required for the stickiness
                            }}
                            className="items-left px-6 py-2 text-sm text-slate-700
                          font-medium "
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell", row)}
                          </td>
                        );
                      })}
                    </tr>
                    {hasSub && (
                      <tr className="border-none">
                        <td></td>
                        <td
                          className=" border-none px-6 pt-3 pb-4 text-sm  text-slate-700 "
                          colSpan={subColSpan}
                        >
                          {renderSubComponent({ row })}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              );
            })
          )}
        </table>
      </div>
      {/* Pagination */}
      {/* <div className=""> */}
      {/* <div className="flex-1 flex justify-between sm:hidden px-5">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </div> */}
      {/* {showFooter && showFooter == true && ( */}
      <div
        id="footer"
        className=" px-6 py-2 bg-white  border-t border-gray-200 items-center justify-between "
      >
        <div className=" sm:flex sm:items-center sm:justify-between">
          <div className="flex  items-center">
            <span className="text-sm text-slate-700">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of{" "}
              <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              <select
                className="text-sm mt-1 ml-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100, 250].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
      {/* )} */}
      {/* </div> */}
    </>
  );
}

export default Table;
