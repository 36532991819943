import { utils } from "./_utils";
import { postRequest, getRequest } from "./ApiRequest";

export const contactus = {
  async contact_email(params) {
    try {
      const response = await postRequest("email", params);
      if (response && response.data && response.data.status === 200) {
        // history.push("/home")
        return response;
      } else {
      }
    } catch (e) {}
  },
  async marketplace_email(params) {
    try {
      const response = await postRequest("email", params);
      if (response && response.data && response.data.status === 200) {
        // history.push("/home")
        return response;
      } else {
      }
    } catch (e) {}
  },
};
