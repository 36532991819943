import Paragraph from "../../../components/Paragraph";

/* This example requires Tailwind CSS v2.0+ */
export default function CenterBrandedCTA(
  { executeScroll, text, buttonText, bottomText },
  props
) {
  // const executeScroll = props.executeScroll;
  return (
    <div className="bg-slate-900">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="font-display text-3xl tracking-tight text-white text-opacity-80 sm:text-4xl">
          {text}
        </h2>
        {/* <p className="mt-4 text-lg leading-6 text-indigo-200">
          See how easy it is to help your customers tackle one of their top
          risks as a part of your offering.
        </p> */}
        <button
          //   href="#"
          onClick={() => {
            executeScroll("vetBanner");
          }}
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-slate-800 bg-white hover:bg-white sm:w-auto"
        >
          {buttonText}
        </button>
        <div>
          <Paragraph variant={"dark"}>{bottomText && bottomText}</Paragraph>
        </div>
      </div>
    </div>
  );
}
