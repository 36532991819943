import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Tabs from "../component/Tabs";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import DeleteButton from "../../partials/actions/DeleteButton";
import DateSelect from "../../components/DateSelect";
import FilterButton from "../../components/DropdownFilter";
import CustomersTable from "../../partials/customers/CustomersTable";
import PaginationClassic from "../../components/PaginationClassic";
import { customers } from "../../modules/_customers";
import ModalForm from "../../components/ModalForm";
import { _assessment } from "../../modules/_assessment";
import ModalBasic from "../../components/ModalBasic";
import AnswerList from "../../components/AnswerList";
import InviteComponent from "../../components/InviteComponent";
import { FeatureFlag } from "../../components/FeatureFlag";
import Button from "../../components/Button";
import AssessmentSelect from "../AssessmentSelect";
import { ListSelector } from "../../components/ListSelector";
import { useStore } from "../../store/usestore";
import { ArrowDownCircleIcon } from "@heroicons/react/24/solid";

const XLSX = require("xlsx");

const exportToExcel = (data, name) => {
  let exportObj = [];
  data.forEach((item) => {
    let obj = {
      "Tenant ID": item.tenant_id._id,
      "Tenant Name": item.tenant_id.tenantTitle,
      Status: item.assessment_status,
    };
    exportObj.push(obj);
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(exportObj);
  XLSX.utils.book_append_sheet(wb, ws, name);
  XLSX.writeFile(wb, name + ".xlsx");
};

function Customers() {
  const tabs = [
    { name: "Regulations", href: "../campaigns", current: false },
    { name: "Assessment history", href: "ecommerce/customers", current: true },
  ];
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [done, setDone] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [datacheck, setDataCheck] = useState(null);
  const [modalItem, setModalItems] = useState({
    questions: [{ questions: [] }],
  });
  const [finishedCount, setFinishedCount] = useState(0);
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const productID = 323;
  const { sendAssessment } = useStore();
  useEffect(() => {
    (async () => {
      const data = await _assessment.getAllAssessmentHistory();
      if (data) {
        setDataCheck(data);
      }
    })();
  }, []);

  useEffect(() => {
    if (datacheck && datacheck !== null) {
      //remove duplicate tenant_id from datacheck
      let unique = [...new Set(datacheck.map((item) => item.tenant_id))];

      let finishedCount = 0;
      datacheck.forEach((item) => {
        if (item.assessment_status === "Finished") {
          finishedCount++;
        }
      });
      setFinishedCount(finishedCount);
    }
  }, [datacheck]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const onShowShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  const setModalItem = (item) => {
    setModalItems(item);
    showHideModal();
  };

  const showHideModal1 = () => {
    setShowModal(false);
  };

  const showHideModal = () => {
    setShowModal(!showModal);
  };

  const showHideShareModal1 = () => {
    setShowShareModal(!showModal);
    // setShowShareModal(false);
  };

  const showHideShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  const handleSelectAssessment = (e, item) => {
    e.preventDefault();

    setSelectedAssessment(item);
    // showHideShareModal();
  };

  const onDone = (e, people, type) => {
    sendAssessment(people, type);
  };

  return (
    <div className="w-full px-6 max-w-10xl space-y-2">
      <div className="flex justify-between items-center pt-1 space-y-2 pb-2">
        <div className="sm:flex sm:justify-between items-center ">
          <h2 className="text-xl flex-1 font-medium text-slate-800">
            Assessment History{" "}
          </h2>
        </div>
        <div
          id="right-grid"
          className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
        >
          {" "}
          {/* Delete button */}
          {/* <DeleteButton selectedItems={selectedItems} /> */}
          {/* Dropdown */}
          {/* <DateSelect /> */}
          {/* Filter button */}
          {/* <FilterButton align="right" /> */}
          {/* Add customer button */}
          <Link to="../assessmentselect">
            <button className="btn bg-secondary-600 hover:bg-secondary-700 text-white">
              <span className="hidden xs:block ml-2">Take Assessment</span>
            </button>
          </Link>
        </div>
      </div>
      {/* Table */}
      <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4">
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            <div className="mb-4 sm:mb-0">
              <h2 className="font-semibold text-slate-800">
                Self Assessments
                <span className="ml-2 text-slate-400 font-medium">
                  {datacheck !== null && datacheck.length}
                  {finishedCount > 0 && (
                    <span className="text-slate-400 font-medium">
                      {" "}
                      ({finishedCount} completed)
                    </span>
                  )}
                </span>
                {/* <Button
                  color="primary"
                  handleClick={() => exportToExcel(datacheck, "Assessments")}
                >
                  <ArrowDownCircleIcon className="h-5 w-5" />
                  <span className="hidden xs:block ml-2">Export</span>
                </Button> */}
              </h2>
            </div>
          </div>
        </header>
        <CustomersTable
          selectedItems={handleSelectedItems}
          data={datacheck !== null && datacheck}
          setModalItem={setModalItem}
          fromDashboard={false}
          // goToActionPlan={goToActionPlan}
          // counts={counts}
        />
      </div>
      {/* Pagination */}
      {/* <div className="mt-8">
        <PaginationClassic />
      </div> */}
      <ModalBasic
        id="scrollbar-modal"
        modalOpen={showModal}
        setModalOpen={showHideModal1}
        title="All Questions"
        size="lg"
        scrollable={true}
      >
        {/* Modal content */}
        <div className="px-5 py-4">
          <div className="text-sm">{<AnswerList questions={modalItem} />}</div>
        </div>
        {/* Modal footer */}
        <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
              onClick={(e) => {
                e.stopPropagation();
                showHideModal();
              }}
            >
              Done
            </button>
            {/* <button className="btn-sm bg-primary-500 hover:bg-primary-600 text-white">
              I Understand
            </button> */}
          </div>
        </div>
      </ModalBasic>
      <ModalBasic
        id="share-assessment-modal"
        modalOpen={showShareModal}
        setModalOpen={showHideShareModal}
        title="Share Assessment"
        showClose={false}
      >
        {/* Modal content */}
        <div className="px-5 py-4">
          <InviteComponent
            done={done}
            onModal={() => onShowShareModal()}
            modal={showShareModal}
          />
        </div>
        {/* Modal footer */}
      </ModalBasic>
      <ModalBasic
        id="assessment-modal"
        modalOpen={assessmentModalOpen}
        setModalOpen={setAssessmentModalOpen}
        title="Take Assessment"
        showClose={true}
        showFooter
        size="lg"
        scrollable={true}
        closeButtonText="Cancel"
      >
        <AssessmentSelect />
        {/* closeModal={setAssessmentModalOpen(false)} */}
      </ModalBasic>
    </div>

    //     </main>

    //   </div>

    // </div>
  );
}

export default Customers;
