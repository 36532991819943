import React from "react";
import Skeleton from "../../components/Skeleton";
import { NavLink } from "react-router-dom";

function DashboardCard10(props) {
  const loading = props.loading;
  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;

  const data = props.data;
  const noShadow = props.noShadow ? true : false;
  const arr_history = [];

  // data !== "loading" &&
  //   props.from !== "employees" &&
  // data.map((item) => {
  //   const histObj = {};
  //   //
  //   histObj["id"] = item.assessment_id;
  //   histObj["type"] = item.assessment_type;
  //   histObj["status"] = item.assessment_status;
  //   histObj["score"] = item.risk_score;
  //   histObj["person"] = item.taken_by;
  //   histObj["date"] = item.assessment_date;
  //   arr_history.push(histObj);
  // });

  // data.map((item) => {
  //   const histObj = {};
  //   //
  //   histObj["name"] = item[0];
  //   histObj["burden"] = item[1];
  //   histObj["effort"] = item[1];

  //   arr_history.push(histObj);
  // });

  return (
    <div
      className={`row-span-${rowspan}  col-span-full xl:col-span-${colSpan} bg-white ${
        noShadow ? "" : "shadow-lg border border-gray-200"
      } rounded-sm  overflow-hidden grow-0`}
    >
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="flex items-left font-semibold text-slate-800">
          {title}
        </h2>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <div className="p-3">
          {data && data.length > 0 ? (
            <div>
              <ul className="my-1">
                {/* Item */}
                {data.map((i) => {
                  return (
                    <li className="flex px-2">
                      <div
                        className={`items-center m-auto justify-center text-center p-auto w-9 h-9 rounded-full shrink-0 ${
                          i.status == "Finished"
                            ? "bg-green-500"
                            : "bg-green-500"
                        } my-2 mr-3`}
                      >
                        {i.status && i.status == "Finished" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-green-50 ml-1.5 mt-1.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        ) : (
                          // <svg
                          //   xmlns="http://www.w3.org/2000/svg"
                          //   class="text-primary-50 m-auto"
                          //   fill="none"
                          //   // viewBox="0 0 40 40"
                          //   stroke="currentColor"
                          //   stroke-width="2"
                          // >
                          //   <path
                          //     stroke-linecap="round"
                          //     stroke-linejoin="round"
                          //     d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          //   />
                          // </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 text-primary-50 ml-2.5 mt-2.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                          // <svg
                          //   className="w-9 h-9 fill-current text-primary-50"
                          //   // viewBox="0 0 36 36"
                          // >
                          //   <path d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                          // </svg>
                        )}
                      </div>
                      <div className="grow flex items-center border-b border-gray-100 text-sm py-2">
                        <div className="grow flex justify-between">
                          <div className="self-center">
                            <a
                              className="font-medium text-slate-800 hover:text-slate-900"
                              href="#0"
                            ></a>{" "}
                            We suggest working on{" "}
                            <span className="font-bold">{i.Name} </span>because
                            the current burden is{" "}
                            <span className="font-bold">
                              {i.Score} hours per week
                            </span>{" "}
                            and the effort is{" "}
                            <span className="font-bold">{i.Effort} days</span> .
                            You will break even{" "}
                            <span className="font-bold">
                              {i["calculations"][
                                "Break even point in weeks"
                              ].toFixed(2)}{" "}
                              weeks after completion
                            </span>{" "}
                            saving you{" "}
                            <span className="font-bold">
                              {i["calculations"][
                                "First year savings (days)"
                              ].toFixed(2)}{" "}
                              days
                            </span>{" "}
                            the remainder of the year.
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div>
              Claim your impediments and enter your burden and effort to see
              suggestions
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardCard10;
