import { useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

// import "./styles.css";

function Tooltip({
  children,
  description,
  helpIcon,
  title,
  size,
  bg,
  position,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const sizeClasses = (size) => {
    switch (size) {
      case "lg":
        return "max-w-72  p-3";
      case "md":
        return "max-w-56 p-3";
      case "sm":
        return "max-w-44 p-2";
      default:
        return "max-w-56 p-3";
    }
  };

  return (
    <>
      <button ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </button>
      <FloatingPortal>
        {isOpen && (
          <div
            className={`rounded overflow-hidden w-56 text-slate-300 bg-slate-800  ${sizeClasses(
              size
            )} `}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="flex flex-col">
              {/* <div className="flex  justify-between"> */}
              <div className="text-sm font-semibold">{title}</div>
              <div className="text-xs  font-normal text-slate-300">
                {description}
              </div>
              {/* </div> */}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

export default Tooltip;
