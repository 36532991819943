/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const people = [
  { id: 0, name: "Select a role" },
  { id: 1, name: "Super Admin", value: "Super Admin", view: ["Super Admin"] },
  {
    id: 2,
    name: "Administrator",
    value: "Administrator",
    view: ["Super Admin"],
  },
  {
    id: 3,
    name: "Manager",
    value: "Manager",
    view: ["Super Admin", "Administrator", "Manager"],
  },
  {
    id: 4,
    name: "Collaborator",
    value: "Collaborator",
    view: ["Super Admin", "Administrator", "Manager"],
  },
  {
    id: 5,
    name: "Observer",
    value: "Observer",
    view: ["Super Admin"],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InputList(props) {
  const [selected, setSelected] = useState(people[0]);

  const handleChange = (e) => {
    setSelected(e);
    // setSelected(e.target.value);
    // props.setSelectedRole(e.target.value);
    props.onChangeDropDown(e, "role");
  };

  return (
    <Listbox value={selected} onChange={(e) => handleChange(e)}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-slate-700">
            Assigned to
          </Listbox.Label> */}
          <div className="relative mt-1">
            <Listbox.Button
              // onFocus={setOpen(true)}
              // onKeyDown={setOpen(true)}

              className={`relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-0 sfocus:ring-secondary-500 sm:text-sm hover:border-1 hover:border-secondary-500  sm:text-sm 
              ${open && "border-secondary-500"}
              hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-orange-500`}
            >
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-slate-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {people.map(
                  (person) =>
                    person.id != 0 &&
                    //is my role the person.view array?
                    person.view.includes(props.profile.role) && (
                      <Listbox.Option
                        key={person.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-secondary-600"
                              : "text-slate-900",
                            "relative cursor-default select-none py-2 pl-8 pr-4"
                          )
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {person.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-primary-600",
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
