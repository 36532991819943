import React from "react";
import { useState, useEffect } from "react";
import Tabs from "../pages/component/Tabs";
import UsersTable from "./UsersTable";
import UserImport from "../pages/UserImport";
import { _user } from "../modules/_user";
import FormPage from "../pages/component/FormPage";
import UserAddPanel from "./UserAddPanel";

export default function UsersPanel(props) {
  const [data, setData] = useState([]);
  const tabs = _user.getUserPanelTabs();
  const profile = props.profile;

  const [selectedTab, setSelectedTab] = useState("My team's users");

  const getUsers = (type) => {
    if (type === "all") {
      return data;
    } else if (type === "my") {
      //filter data by users.tenants.tenantId === profile.tenantId
      return data.filter((user) => {
        return user.tenants.tenantId === profile.tenantId;
      });
    }
  };

  return (
    <>
      <div className="px-10 sm:px-6 lg:px-8 py-10 w-full max-w-9xl mx-auto">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Welcome banner */}
          <div className="mb-0 sm:mb-0 inline-flex ">
            <h2 className="text-xl md:text-2xl text-slate-800 font-bold">
              Users
            </h2>
          </div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Filter button */}
            {/* <FilterButton align="right" /> */}
            {/* Datepicker built with flatpickr */}
            {/* <Datepicker align="right" /> */}
            {/* Take Assessment */}
            {/* <Link to="../assessmentselect"> */}
            {/* <button className="btn bg-lime-500 hover:bg-lime-600 text-white">
              <svg
                className="w-4 h-4 fill-current opacity-50 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="hidden xs:block ml-2">Add Users</span>
            </button> */}
            {/* </Link> */}
            {/* Add view button */}

            {/* <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add view</span>
          </button> */}
          </div>
        </div>
        <div className="">
          <div className="mb-6">
            {/* Tabs */}
            <div className="mb-5">
              <Tabs
                tabs={tabs}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                secure={true}
                profile={profile}
              />
            </div>
          </div>

          <section className="pb-6 border-t border-gray-200">
            {selectedTab === "My team's users" && (
              <UsersTable tab={selectedTab} />
            )}
            {selectedTab === "Company Users" && (
              <UsersTable tab={selectedTab} />
            )}
            {selectedTab === "Invite my team" && (
              <UserAddPanel type="new" profile={profile} />
            )}
            {selectedTab === "Create new teams" && (
              <UserAddPanel type="add" profile={profile} />
            )}
          </section>
        </div>
      </div>
    </>
  );
}
