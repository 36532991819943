/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline";

const features = [
  {
    name: "Find your basecamp with self-assessments.",
    description: "",
  },
  {
    name: "Set your course with a roadmap.",
    description: "",
  },
  {
    name: "Understand the obstacles that lie ahead.",
    description: "",
  },
  {
    name: "Collaborate with others who are on the same journey.",
    description: "",
  },

  {
    name: "Track your progress and celebrate your wins.",
    description: "",
  },
  {
    name: "Show your work and share your story.",
    // description: "Eliminate misunderstandings, delays, and wasted time.",
  },
];

export default function FeatureList() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className="col-span-2">
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">
            Everything you need
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            Accelerate your journey
          </p>
          <p className="mt-4 text-lg text-gray-500">
            JDO Connect is designed to help accelerate your devops journey.
          </p>
        </div>
        <div className="mt-12 lg:mt-12 lg:col-span-3">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
