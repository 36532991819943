/* This example requires Tailwind CSS v2.0+ */
import Hiking1 from "../../../images/hiking1.jpg";
import Hiking from "../../../images/hiking.jpg";
const metrics = [
  {
    id: 1,
    stat: "97%",
    emphasis: "",
    rest: "We can put a statistic here about burnout",
  },
  {
    id: 2,
    stat: "78%",
    emphasis: "",
    rest: "We can put one here about the amount of manual effort resolved",
  },
  {
    id: 3,
    stat: "100%",
    emphasis: "",
    rest: "We can put one here about something else",
  },
  {
    id: 4,
    stat: "100%",
    emphasis: "",
    rest: "And put one here about something else",
  },
];

export default function StatsLarge() {
  return (
    <div className="relative bg-gray-900">
      <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <img
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src={Hiking}
              alt="Man standing at summit of mountain range"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold text-indigo-300 tracking-wide uppercase">
            Did you know??
          </h2>
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-3xl">
            Speed and efficiency are your competitive advantage
          </h2>
          <p className="mt-5 text-lg text-gray-300">
            We've spent two years talking to the best devops shops in the world
            and here's what we've learned.
          </p>
          <div
            id="ptrBanner"
            className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2"
          >
            {metrics.map((item) => (
              <p key={item.id}>
                <span className="block text-2xl font-bold text-white">
                  {item.stat}
                </span>
                <span className="mt-1 block text-base text-gray-300">
                  <span className="font-medium text-white">
                    {item.emphasis}
                  </span>{" "}
                  {item.rest}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
