import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const Plan = {
  async getAllPlans(scope) {
    let profile = _auth.getUserProfile();
    //how do we know which one to get?
    // if the user is a company admin, get all impediments for the company
    let data;

    data = {
      scope: scope,
      tenantId: profile.tenantId,
      companyId: profile.companyId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/plans/get-all-plans", data);

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getPlan(planId) {
    let profile = _auth.getUserProfile();
    //how do we know which one to get?
    // if the user is a company admin, get all impediments for the company
    let data;

    data = {
      planId: planId,
      tenantId: profile.tenantId,
      companyId: profile.companyId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/plans/get-plan", data);

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async addCompetencyToPlan(plan_id, new_level, competency) {
    let profile = _auth.getUserProfile();
    let data;

    //get index of new_level in competency.answer_options
    let new_level_index = competency?.answer_options.findIndex(
      (option) => option === new_level
    );

    let current_level_index = competency?.score;

    let competency_obj = {
      competencyId: competency._id,
      goal_date: "2021-06-30T00:00:00.000Z",
      current_level: current_level_index,
      target_level: new_level_index,
      status: "Not Started",
      // impediment_cards: impediments,
    };

    data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,
      competency: competency,
      planId: plan_id,
      updates: competency_obj,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/plans/add-competency-to-plan", data);

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async removeCompetencyFromPlan(plan_id, competencyId) {
    let profile = _auth.getUserProfile();
    let data;

    data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,
      competencyId: competencyId,
      planId: plan_id,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/plans/remove-comp-from-plan", data);

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async UpdateSortOrder(plan_id, competency, cards) {
    let profile = _auth.getUserProfile();
    let data;

    let competency_obj = {
      competencyId: competency.competencyId._id,
    };
    if (cards && cards.length > 0) {
      competency_obj.Cards = cards;
    }

    data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,
      competency: competency,
      planId: plan_id,
      updates: competency_obj,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/plans/update-sort-order", data);

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async UpdateCompSortOrder(plan_id, competencies) {
    let profile = _auth.getUserProfile();
    let data;

    data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,

      planId: plan_id,
      updates: competencies,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/plans/update-comp-sort-order", data);

      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async createPlan(planName, goal_date) {
    let profile = _auth.getUserProfile();
    let data;

    data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,
      plan: {
        name: planName,
        goal_date: "2021-06-30T00:00:00.000Z",
        status: "Not Started",
      },
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let response = await postRequest("/plans/create-plan", data);

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
};
