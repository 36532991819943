import { useHistory } from "react-router-dom";
import React from "react";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import Button from "./Button";

function ProfileSidebar({
  profileSidebarOpen,
  setProfileSidebarOpen,
  ...props
}) {
  const [selected, setSelected] = React.useState(props.selected);
  const data = props.data;
  const title = props.title;
  const history = useHistory();
  const allData = props.allData;
  const handleSelectEmployee = (e, d) => {
    e.preventDefault();

    setSelected(d._id);
    props.setData(d);
  };

  const handleSearchList = (e) => {
    e.preventDefault();

    const searchList = allData.filter((d) => {
      return (
        d.Name !== undefined &&
        d.Name.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    props.setList(searchList);
  };

  return (
    <div
      id="profile-sidebar"
      className={`absolute z-20 top-0 bottom-0 w-full md:w-auto md:static md:top-auto md:bottom-auto -mr-px md:translate-x-0 transform transition-transform duration-200 ease-in-out ${
        profileSidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="sticky top-16 bg-white overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-r border-slate-200 md:w-72 xl:w-80 h-[calc(100vh-64px)]">
        {/* Profile group */}
        <div>
          {/* Group header */}
          <div className="sticky top-0 z-10">
            <div className="flex items-center bg-white border-b border-slate-200 px-5 h-16">
              <div className="w-full flex items-center justify-between">
                <div className="flex items-center">
                  <h3 className="text-lg font-semibold truncate">{title}</h3>
                </div>

                {/* Profile image */}
                {/* <div className="relative">
                  <div className="grow flex items-center truncate">
                    
                    <div className="truncate">
                      <Button rounded color="purple" size="sm" fullWidth handleClick={() => history.push("/employees")}>
                      
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>

                      <span className="f ml-3">{title}</span>
                      </Button>
                    </div>
                  </div>
                </div> */}
                {/* Add button */}
                {/* <button className="p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm ml-2">
                  <svg className="w-4 h-4 fill-current text-primary-500" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1Z" />
                  </svg>
                </button> */}
              </div>
            </div>
          </div>
          {/* Group body */}
          <div className="px-5 py-4">
            {/* Search form */}
            {/* <form className="relative">
              <label htmlFor="profile-search" className="sr-only">Search</label>
              <input id="profile-search" className="form-input w-full pl-9 focus:border-slate-300" type="search" placeholder="Search…"
              onChange={handleSearchList } />
              <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                  <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                </svg>
              </button>
            </form> */}
            {/* Team members */}
            <div className="mt-4">
              <div className="text-xs font-semibold text-slate-400 uppercase mb-3">
                {title}
              </div>
              <ul className="mb-6">
                {data.map((item) => (
                  <li key={item._id} className="-mx-2">
                    <button
                      className={`w-full p-2 rounded ${
                        item._id == selected
                          ? "bg-primary-100"
                          : "bg-white hover:bg-slate-50"
                      } `}
                      onClick={(event) => handleSelectEmployee(event, item)}
                    >
                      <div className="flex items-center">
                        <div className="relative mr-2">
                          {/* <img className="w-8 h-8 rounded-full" src={UserImage08} width="32" height="32" alt="User 08" /> */}
                        </div>
                        <div className="truncate">
                          <span className="text-sm font-medium text-slate-800">
                            {item.Description}
                          </span>
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
                {/* <li key={"item.id"} className="-mx-2"> */}

                {/* <button className="w-full p-2 rounded bg-white" onClick={() => setProfileSidebarOpen(false)}>
    <div className="flex items-center">
      <div className="relative mr-2">
       
      </div>
      <div className="truncate">
        <span className="text-sm font-medium text-slate-800">Carolyn McNeail</span>
      </div>
    </div>
  </button> */}
                {/* </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSidebar;
