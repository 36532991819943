// import { PlusIcon } from '@heroicons/react/20/solid'
import { PlusIcon } from "@heroicons/react/20/solid";
import Button from "./Button";

export default function Divider(props) {
  const handleClick = props.handleClick;
  return (
    <div className="relative">
      <div
        className="absolute inset-0 flex items-center px-3"
        aria-hidden="true"
      >
        <div className="w-full border-t border-primary-300 " />
      </div>
      <div className="relative flex justify-center">
        <button
          type="button"
          onClick={() => {
            handleClick();
          }}
          className={`inline-flex items-center rounded-full border 
          border-gray-300 bg-white px-4 py-1.5 text-sm font-medium leading-5 text-slate-600 
          shadow-sm
          hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-secondary-500 
             
          hover:bg-gray-50  hover:border-secondary-500 
          focus:outline-none focus:ring-1 focus:ring-secondary-500 `}
        >
          <PlusIcon
            className="-ml-1.5 mr-1 h-5 w-5 text-slate-500"
            aria-hidden="true"
          />
          <span>{props.buttonText ? props.buttonText : "Button text"}</span>
        </button>
      </div>
    </div>
  );
}
