import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const _competencies = {
  async getCompetencies() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/competencies/get", data);

      if (response) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getGlobalCompetencies() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "/competencies/get-global-competencies-summary1",
        data
      );

      if (response) {
        return response.data.data.comps;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },

  async getCompetencyList() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      team_id: profile.teamId ? profile.teamId : "63c0494e5c0c7375425c061c",
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "competencies/get-competency-list",
        data
      );

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },

  async getTeamCompetencies() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      // team_id: profile.teamId ? profile.teamId : "63c0494e5c0c7375425c061c",
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "/competencies/get-team-competencies",
        data
      );

      if (response && response.data) {
        return response.data.data;
      } else {
        return undefined;
      }
    } catch (e) {
      return [];
    }
  },
  async getTeamCompetenciesFromSurvey(id) {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: id ? id : profile.tenantId,
      team_id: id ? id : profile.tenantId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "/competencies/get-latest-team-competencies",
        data
      );

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getGlobalCompetenciesFromSurvey() {
    let profile = _auth.getUserProfile();
    let data = {
      companyId: profile.companyId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "/competencies/get-global-competencies-summary",
        data
      );

      if (response && response.data) {
        return response.data.data.resArray;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getCompetencyAreas() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/competencies/get", data);

      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async changeCompetencyLevel(assessmentId, questionId, your_answer) {
    let profile = _auth.getUserProfile();

    let data = {
      assessmentId: assessmentId,
      questionId: questionId,
      your_answer: your_answer,
    };

    // try {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest(
      "competencies/update-competency-level",
      data
    );

    if (response && response.data) {
      return response.data.data.data;
    } else {
      return [];
    }
    // } catch (e) {
    //   return [];
    // }
  },
};
