import React, { useRef, useEffect } from "react";

import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";

// Import utilities
import { tailwindConfig, formatValue, formatNumber } from "../utils/Utils";

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip
);

function RiskOverTimeChart({ data, width, height }) {
  const canvas = useRef(null);
  const legend = useRef(null);
  //   {
  //     "labels": [
  //         "07-22-2022",
  //         "07-25-2022",
  //         "07-26-2022",
  //         "08-01-2022",
  //         "08-02-2022",
  //         "08-09-2022"
  //     ],
  //     "datasets": [
  //         {
  //             "label": "Adoption",
  //             "data": [
  //                 6,
  //                 7,
  //                 1,
  //                 8,
  //                 5,
  //                 1
  //             ],
  //             "borderColor": "#60a5fa",
  //             "fill": false,
  //             "borderWidth": 2,
  //             "tension": 0,
  //             "pointRadius": 0,
  //             "pointHoverRadius": 3,
  //             "pointBackgroundColor": "#60a5fa"
  //         }
  //     ]
  // }

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const chart = new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            min: 0,
            grid: {
              drawBorder: false,
              beginAtZero: true,
            },
            ticks: {
              maxTicksLimit: 10,
              callback: (value) => formatNumber(value),
            },
          },
          x: {
            type: "time",
            time: {
              parser: "MM-DD-YYYY",
              unit: "day",
              displayFormats: {
                month: "MMM YY",
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatNumber(context.parsed.y),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [
        // {
        //   id: "htmlLegend",
        //   afterUpdate(c, args, options) {
        //     const ul = legend.current;
        //     if (!ul) return;
        //     // Remove old legend items
        //     while (ul.firstChild) {
        //       ul.firstChild.remove();
        //     }
        //     // Reuse the built-in legendItems generator
        //     const items = c.options.plugins.legend.labels.generateLabels(c);
        //     items.slice(0, 2).forEach((item) => {
        //       const li = document.createElement("li");
        //       li.style.marginLeft = tailwindConfig().theme.margin[3];
        //       // Button element
        //       const button = document.createElement("button");
        //       button.style.display = "inline-flex";
        //       button.style.alignItems = "center";
        //       button.style.opacity = item.hidden ? ".3" : "";
        //       button.onclick = () => {
        //         c.setDatasetVisibility(
        //           item.datasetIndex,
        //           !c.isDatasetVisible(item.datasetIndex)
        //         );
        //         c.update();
        //       };
        //       // Color box
        //       const box = document.createElement("span");
        //       box.style.display = "block";
        //       box.style.width = tailwindConfig().theme.width[3];
        //       box.style.height = tailwindConfig().theme.height[3];
        //       box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
        //       box.style.marginRight = tailwindConfig().theme.margin[2];
        //       box.style.borderWidth = "3px";
        //       box.style.borderColor =
        //         c.data.datasets[item.datasetIndex].borderColor;
        //       box.style.pointerEvents = "none";
        //       // Label
        //       const label = document.createElement("span");
        //       label.style.color = tailwindConfig().theme.colors.gray[500];
        //       label.style.fontSize = tailwindConfig().theme.fontSize.sm[0];
        //       label.style.lineHeight =
        //         tailwindConfig().theme.fontSize.sm[1].lineHeight;
        //       const labelText = document.createTextNode(item.text);
        //       label.appendChild(labelText);
        //       li.appendChild(button);
        //       button.appendChild(box);
        //       button.appendChild(label);
        //       ul.appendChild(li);
        //     });
        //   },
        // },
      ],
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <div className="">
        <div className="flex flex-wrap justify-between items-end">
          <div className="flex items-start">
            {/* <div className="text-3xl font-bold text-slate-800 mr-2">$1,482</div>
            <div className="text-sm font-semibold text-white px-1.5 bg-yellow-500 rounded-full">
              -22%
            </div> */}
          </div>
          <div className="grow ml-2 mb-1">
            <ul ref={legend} className="flex flex-wrap justify-end"></ul>
          </div>
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>
    </React.Fragment>
  );
}

export default RiskOverTimeChart;
