import React from "react";
// import { Card } from "../../components/Card";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";

const UserImport = (props) => {
  const location = useLocation();

  // const ImportRole = () => {
  //   if (location.state.variant === "User") {
  //     return (
  //       <ul
  //         role="list"
  //         className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200"
  //       >
  //         <li>
  //           <div className="relative group py-4 flex items-start space-x-3">
  //             <div className="flex-shrink-0">
  //               <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-pink-500">
  //                 <svg
  //                   className="h-6 w-6 text-white"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                   aria-hidden="true"
  //                 >
  //                   <path
  //                     stroke-linecap="round"
  //                     stroke-linejoin="round"
  //                     stroke-width="2"
  //                     d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //             <div className="min-w-0 flex-1">
  //               <div className="text-sm font-medium text-slate-900">
  //                 <NavLink to="#">
  //                   <span
  //                     className="absolute inset-0"
  //                     aria-hidden="true"
  //                   ></span>
  //                   Import CSV or Add Manually
  //                 </NavLink>
  //               </div>
  //               <p className="text-sm text-slate-500">
  //                 Import your employees from CSV or add them Manually.
  //               </p>
  //             </div>
  //             <div className="flex-shrink-0 self-center">
  //               <svg
  //                 className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //                 fill="currentColor"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   fill-rule="evenodd"
  //                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
  //                   clip-rule="evenodd"
  //                 />
  //               </svg>
  //             </div>
  //           </div>
  //         </li>

  //         <li>
  //           <div className="relative group py-4 flex items-start space-x-3">
  //             <div className="flex-shrink-0">
  //               <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-purple-500">
  //                 <svg
  //                   className="h-6 w-6 text-white"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                   aria-hidden="true"
  //                 >
  //                   <path
  //                     stroke-linecap="round"
  //                     stroke-linejoin="round"
  //                     stroke-width="2"
  //                     d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //             <div className="min-w-0 flex-1">
  //               <div className="text-sm font-medium text-slate-900">
  //                 <NavLink to="#">
  //                   <span
  //                     className="absolute inset-0"
  //                     aria-hidden="true"
  //                   ></span>
  //                   Azure Active Directory
  //                 </NavLink>
  //               </div>
  //               <p className="text-sm text-slate-500">
  //                 Import employees from Azure Active Directory via one-time
  //                 import or sync.
  //               </p>
  //             </div>
  //             <div className="flex-shrink-0 self-center">
  //               <svg
  //                 className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //                 fill="currentColor"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   fill-rule="evenodd"
  //                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
  //                   clip-rule="evenodd"
  //                 />
  //               </svg>
  //             </div>
  //           </div>
  //         </li>

  //         <li>
  //           <div className="relative group py-4 flex items-start space-x-3">
  //             <div className="flex-shrink-0">
  //               <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-yellow-500">
  //                 <svg
  //                   className="h-6 w-6 text-white"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                   aria-hidden="true"
  //                 >
  //                   <path
  //                     stroke-linecap="round"
  //                     stroke-linejoin="round"
  //                     stroke-width="2"
  //                     d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //             <div className="min-w-0 flex-1">
  //               <div className="text-sm font-medium text-slate-900">
  //                 <NavLink to="#">
  //                   <span
  //                     className="absolute inset-0"
  //                     aria-hidden="true"
  //                   ></span>
  //                   Google Workspace
  //                 </NavLink>
  //               </div>
  //               <p className="text-sm text-slate-500">
  //                 Import employees from Google Workspace via one-time import or
  //                 sync.
  //               </p>
  //             </div>
  //             <div className="flex-shrink-0 self-center">
  //               <svg
  //                 className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //                 fill="currentColor"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   fill-rule="evenodd"
  //                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
  //                   clip-rule="evenodd"
  //                 />
  //               </svg>
  //             </div>
  //           </div>
  //         </li>
  //         <li>
  //           <div className="relative group py-4 flex items-start space-x-3">
  //             <div className="flex-shrink-0">
  //               <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-green-500">
  //                 <svg
  //                   className="h-6 w-6 text-white"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                   aria-hidden="true"
  //                 >
  //                   <path
  //                     stroke-linecap="round"
  //                     stroke-linejoin="round"
  //                     stroke-width="2"
  //                     d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //             <div className="min-w-0 flex-1">
  //               <div className="text-sm font-medium text-slate-900">
  //                 <NavLink to="#">
  //                   <span
  //                     className="absolute inset-0"
  //                     aria-hidden="true"
  //                   ></span>
  //                   Single Sign-on
  //                 </NavLink>
  //               </div>
  //               <p className="text-sm text-slate-500">
  //                 Connect to an SSO provider such as Okta.
  //               </p>
  //             </div>
  //             <div className="flex-shrink-0 self-center">
  //               <svg
  //                 className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //                 fill="currentColor"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   fill-rule="evenodd"
  //                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
  //                   clip-rule="evenodd"
  //                 />
  //               </svg>
  //             </div>
  //           </div>
  //         </li>
  //       </ul>
  //     );
  //   } else if (location.state.variant === "Employess") {
  //     return (
  //       <ul
  //         role="list"
  //         className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200"
  //       >
  //         <li>
  //           <div className="relative group py-4 flex items-start space-x-3">
  //             <div className="flex-shrink-0">
  //               <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-pink-500">
  //                 {/* <!-- Heroicon name: outline/speakerphone --> */}
  //                 <svg
  //                   className="h-6 w-6 text-white"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                   aria-hidden="true"
  //                 >
  //                   <path
  //                     stroke-linecap="round"
  //                     stroke-linejoin="round"
  //                     stroke-width="2"
  //                     d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //             <div className="min-w-0 flex-1">
  //               <div className="text-sm font-medium text-slate-900">
  //                 <NavLink to="#">
  //                   <span
  //                     className="absolute inset-0"
  //                     aria-hidden="true"
  //                   ></span>
  //                   Import CSV or Add Manually
  //                 </NavLink>
  //               </div>
  //               <p className="text-sm text-slate-500">
  //                 Import your employees from CSV or add them Manually.
  //               </p>
  //             </div>
  //             <div className="flex-shrink-0 self-center">
  //               {/* <!-- Heroicon name: solid/chevron-right --> */}
  //               <svg
  //                 className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //                 fill="currentColor"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   fill-rule="evenodd"
  //                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
  //                   clip-rule="evenodd"
  //                 />
  //               </svg>
  //             </div>
  //           </div>
  //         </li>

  //         <li>
  //           <div className="relative group py-4 flex items-start space-x-3">
  //             <div className="flex-shrink-0">
  //               <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-purple-500">
  //                 {/* <!-- Heroicon name: outline/terminal --> */}
  //                 <svg
  //                   className="h-6 w-6 text-white"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                   aria-hidden="true"
  //                 >
  //                   <path
  //                     stroke-linecap="round"
  //                     stroke-linejoin="round"
  //                     stroke-width="2"
  //                     d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //             <div className="min-w-0 flex-1">
  //               <div className="text-sm font-medium text-slate-900">
  //                 <NavLink to="#">
  //                   <span
  //                     className="absolute inset-0"
  //                     aria-hidden="true"
  //                   ></span>
  //                   Azure Active Directory
  //                 </NavLink>
  //               </div>
  //               <p className="text-sm text-slate-500">
  //                 Import employees from Azure Active Directory via one-time
  //                 import or sync.
  //               </p>
  //             </div>
  //             <div className="flex-shrink-0 self-center">
  //               {/* <!-- Heroicon name: solid/chevron-right --> */}
  //               <svg
  //                 className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //                 fill="currentColor"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   fill-rule="evenodd"
  //                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
  //                   clip-rule="evenodd"
  //                 />
  //               </svg>
  //             </div>
  //           </div>
  //         </li>

  //         <li>
  //           <div className="relative group py-4 flex items-start space-x-3">
  //             <div className="flex-shrink-0">
  //               <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-yellow-500">
  //                 {/* <!-- Heroicon name: outline/calendar --> */}
  //                 <svg
  //                   className="h-6 w-6 text-white"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                   aria-hidden="true"
  //                 >
  //                   <path
  //                     stroke-linecap="round"
  //                     stroke-linejoin="round"
  //                     stroke-width="2"
  //                     d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //                   />
  //                 </svg>
  //               </span>
  //             </div>
  //             <div className="min-w-0 flex-1">
  //               <div className="text-sm font-medium text-slate-900">
  //                 <NavLink to="#">
  //                   <span
  //                     className="absolute inset-0"
  //                     aria-hidden="true"
  //                   ></span>
  //                   Googl Workspace
  //                 </NavLink>
  //               </div>
  //               <p className="text-sm text-slate-500">
  //                 Import employees from Google Workspace via one-time import or
  //                 sync.
  //               </p>
  //             </div>
  //             <div className="flex-shrink-0 self-center">
  //               {/* <!-- Heroicon name: solid/chevron-right --> */}
  //               <svg
  //                 className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //                 fill="currentColor"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   fill-rule="evenodd"
  //                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
  //                   clip-rule="evenodd"
  //                 />
  //               </svg>
  //             </div>
  //           </div>
  //         </li>
  //       </ul>
  //     );
  //   }
  // };

  return (
    <>
      <div className="px-10 sm:px-6 lg:px-8 py-10 w-full max-w-9xl mx-auto">
        <div className="mb-0 sm:mb-0 inline-flex ">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Add Users
          </h1>
        </div>
        <div className="sm:flex sm:items-center sm:items-center mb-8">
          <div className="min-h-full flex flex-col justify-center align-items-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-xlg">
              {/* <Card
            body={ */}
              <div className="max-w-xlg bg-white align-items-center p-10 rounded overflow-hidden shadow-lg">
                <ul
                  role="list"
                  className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200"
                >
                  <li>
                    <div className="relative group py-4 flex items-start space-x-3">
                      <div className="flex-shrink-0">
                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-pink-500">
                          <svg
                            className="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div className="text-sm font-medium text-slate-900">
                          <NavLink to="/AddUser">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            Import CSV or Add Manually
                          </NavLink>
                        </div>
                        <p className="text-sm text-slate-500">
                          Import your employees from CSV or add them Manually.
                        </p>
                      </div>
                      <div className="flex-shrink-0 self-center">
                        <svg
                          className="h-5 w-5 text-slate-400 group-hover:text-slate-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* {ImportRole()} */}
                <div className="mt-4 flex">
                  <NavLink
                    to="/home"
                    className="text-sm font-medium text-primary-600 hover:text-primary-500"
                  >
                    Take me to my home page.
                    <span aria-hidden="true"> &rarr;</span>
                  </NavLink>
                </div>
              </div>
              {/* }
            
          /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserImport;
