import React, { useEffect } from "react";
import Skeleton from "../../components/Skeleton";
import { NavLink } from "react-router-dom";
import StackedListTwoColumn from "../../components/StackedListTwoColumn";
import RightActionArea from "../../components/ActionAreaRight";
import { _impediments } from "../../modules/impediments";
import Tabs from "../../pages/component/Tabs";

function CompetencyLeaderboard(props) {
  //   const loading = props.loading;

  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const data = props.data;
  const noShadow = props.noShadow ? true : false;
  const arr_history = [];
  const [initialImpediments, setInitialImpediments] = React.useState([]);
  const [impediments, setImpediments] = React.useState([]);
  const [competencies, setCompetencies] = React.useState(props.data);
  const [loading, setLoading] = React.useState(true);
  const [selectedTab, setSelectedTab] = React.useState("Highest Maturity");

  const tabs = [
    {
      name: "Lowest Maturity",
      href: "#",
      current: true,
    },
    {
      name: "Highest Maturity",
      href: "#",
      current: true,
    },
  ];

  useEffect(() => {
    (async () => {
      // setInitialImpediments(props.data);
      // setCompetencies(props.data);
      // setImpediments(props.data);

      const impediments = await _impediments.getImpedimentSummary();
      if (impediments && impediments.data) {
        setInitialImpediments(impediments.data.resArray);
        setImpediments(
          impediments.data.resArray
            .sort((a, b) => {
              return b.resolvedBurden - a.resolvedBurden;
            })
            .slice(0, 5)
        );
        setLoading(false);
      }
    })();
  }, []);

  const handleSetSelectedTab = (tab, index) => {
    setSelectedTab(tab);
  };

  return (
    <div
      className={`row-span-${rowspan}  col-span-full xl:col-span-${colSpan} bg-white ${
        noShadow ? "" : "shadow-lg border border-gray-200"
      } rounded-sm  overflow-hidden grow-0
      `}
    >
      <header className="flex items-center justify-between  px-5 py-4 border-b border-gray-100">
        <h2 className="  font-semibold text-slate-800">{title}</h2>
        <RightActionArea>
          <Tabs
            tabs={tabs}
            setSelectedTab={handleSetSelectedTab}
            selectedTab={selectedTab}
          />
        </RightActionArea>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <div
          className="p-3 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full grow-0"
        >
          <div>
            {selectedTab === "Lowest Maturity" && (
              <StackedListTwoColumn
                key={"stacked_list"}
                data={JSON.parse(JSON.stringify(props.data))
                  .sort((a, b) => {
                    return a[1] - b[1];
                  })
                  .slice(0, 5)}
                type={"competency_scores"}
              />
            )}
            {selectedTab === "Highest Maturity" && (
              <StackedListTwoColumn
                key={"stacked_list"}
                data={JSON.parse(JSON.stringify(props.data))
                  .sort((a, b) => {
                    return b[1] - a[1];
                  })
                  .slice(0, 5)}
                type={"competency_scores"}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CompetencyLeaderboard;
