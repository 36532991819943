import React, { useState } from "react";
import { useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import { _competencies } from "../../modules/competencies";

import RightActionArea from "../../components/ActionAreaRight";

import { Icon } from "@iconify/react";
import { _auth } from "../../modules/_auth";
import { audit } from "../../modules/_audit";
import Skeleton from "../../components/Skeleton";
import { _value_drivers } from "../../modules/_value_drivers";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";

function ValueDrivers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [questions, setQuestions] = useState([{}]);
  const [teamCompetencies, setTeamCompetencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [view, setView] = useState("grid");
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const [viewImpedimentModalOpen, setViewImpedimentModalOpen] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [selectedImpediment, setSelectedImpediment] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [thisAssessment, setThisAssessment] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const history = useHistory();
  const stc = true;

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const { data } = await _value_drivers.getValueDrivers();
      setDrivers(data);
      // setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto space-y-6">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          <div className="mb-0 sm:mb-0 inline-flex ">
            <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
              Value Drivers
            </h1>
          </div>
          <RightActionArea></RightActionArea>
        </div>
        {/* map areas and create div for each area */}

        {view !== "table" && (
          <div className="bg-white text-sm  items-center border border-gray-200  justify-between p-4 space-y-8">
            <div className="grid grid-cols-8 gap-0 divide-y divide-x">
              <div className="col-span-1 px-2 py-2">Value Driver</div>
              <div className="col-span-2 px-2 py-2">Description</div>
              {drivers &&
                drivers !== [] &&
                drivers[0] &&
                drivers[0].metrics &&
                Object.keys(drivers[0].metrics).map((metric) => (
                  <div className="col-span-1 px-2 py-2 capitalize">
                    {metric}
                  </div>
                ))}
              {drivers.map((driver) => (
                <>
                  <div className="col-span-1 px-2 py-2">{driver.name}</div>
                  <div className="col-span-2 px-2 py-2">
                    {driver.description}
                  </div>

                  {Object.keys(driver.metrics).map((metric) => (
                    <div className="col-span-1 pb-4 px-2">
                      <div className="">
                        {driver.metrics[metric].map((entry) => (
                          <div className="flex items-top pb-4">
                            <span className="flex">
                              {entry.impact == "up" ? (
                                <ArrowUpIcon className="w-5 h-5 mr-2 mt-1 flex" />
                              ) : (
                                <ArrowDownIcon className="w-5 h-5 mr-2 mt-1 flex" />
                              )}{" "}
                            </span>
                            <span className="flex">{entry.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default ValueDrivers;
