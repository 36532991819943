export default function Paragraph({ children, variant, hidden }) {
  return (
    <p
      className={`mt-3 mb-3 text-sm font-normal  ${
        variant == "dark" ? "text-slate-300" : "text-slate-700"
      }`}
    >
      {children}
    </p>
  );
}
