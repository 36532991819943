import { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "./Skeleton";
import { Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import QuestionToolTip from "./QuestionToolTip";
import Badge from "./Badge";
import { UserIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import { RenderTextField } from "../pages/Impediments";
import { utils } from "../modules/_utils";

export default function ImpedimentList({
  project,
  size_limit,
  fetch_limit,
  dedupe,
  // items,
  setSelectedRow,
  setUpdatedId,
  columns,
  profile,
  updatedId,
  updateData,
  competencies,
  loading,
  fromDashboard,
  showFooter,
  toggleValue,
  hasSubComponent,
  onToggleChange,
  selectedRow,
  handleTextColumnValueChange2,
  handleOnBlur,
  renderSubComponent,
  RenderTextField,
  RenderTeamName,
  RenderImpactedTeams,
  RenderCompetencyList,
  RenderImpedimentTypeList,
  RenderNumberColumn,
  RenderActions,
  RenderScopeList,
  ...props
}) {
  // const { items } = props;
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(data.length);
  const [hasMore, setHasMore] = useState(true);
  const [dedupeMode, setDedupeMode] = useState(dedupe);

  const [loader, setLoader] = useState(loading);

  const infiniteRef = useRef(null);
  let scrollParentRef = useRef(null);

  useEffect(() => {
    setHasMore(true);
  }, [data]);

  useEffect(() => {
    setDedupeMode(dedupe);
  }, [dedupe]);

  useEffect(() => {
    setOffset(data.length);
  }, [offset]);

  useEffect(() => {
    setAllData(props.items); //all data
    setData(props.initialData); //sliced

    // setData(props.items.slice(0, LIMIT));
  }, [props.items]);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const handleItemClick = (e, item) => {
    e.preventDefault();
    // props.onItemClick(project, item);
  };

  const handleLoadMore1 = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      setData(data.concat(Array.from({ length: 20 })));
      // this.setState({
      //   items: items.concat(Array.from({ length: 20 }))
      // });
    }, 1500);
  };

  const handleLoadMore = () => {
    const newData = allData.slice(0, data.length + 10);
    // const combine = [...data, ...allData.slice(data.length, data.length + 50)];
    setData([...data, ...allData.slice(data.length, data.length + 50)]);
    setHasMore(newData.length < allData.length);
    return newData;
  };

  // const handleLoadMore = () => {

  //   setLoader(true);
  //   //take the next 20 from the allData array and add it to the data array
  //   // setInitialLoad(true);
  //   const LIMIT = data.length + fetch_limit;
  //   setOffset(offset + LIMIT);
  //   const d = allData.slice(offset, LIMIT);
  //   const combined = [...data, ...d];

  //   setTimeout(() => {
  //     setData(combined);
  //     setLoader(false);
  //   }, 1500);
  // };

  const combineArrays = (arr1, arr2) => {
    let nextData = arr2.slice(arr1.length, arr1.length + 10);
    return [...arr1, ...nextData];
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setLoader(true);
    setTimeout(() => {
      setData(combineArrays(data, allData));

      setLoader(false);
    }, 1000);
  };

  return (
    <div
      // when changing dedupeMod, the width of the table changes to accomodate the dedupe column
      // this causes the table to shift left and right.  This transition helps to smooth out the shift
      className={`${dedupeMode && "pr-[33vw]"} 
      
      transition-all duration-500 ease-in-out
      `}
    >
      {loader && (
        // toast notication indicates loading
        <div
          className="fixed inset-x-0 inset-y-12 flex items-end justify-center 
        pl-4 py-6  pointer-events-none sm:p-6 sm:items-start sm:justify-end"
        >
          <div
            className="max-w-sm w-full 
           pointer-events-auto overflow-hidden"
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {/* <!-- Heroicon name: check-circle --> */}
                  <Skeleton type="spinner" color="indigo" forButton />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    Loading...
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Please wait while we load the data for you.
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    className="inline-flex text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close</span>
                    {/* <!-- Heroicon name: x --> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        id="scrollableDiv"
        style={{ height: "calc(100vh - 11rem)" }}
        className=" bg-white sm:rounded-md  scrollbar-thin scrollbar-thumb-slate-300 
        scrollbar-track-slate-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full overflow-visible"
      >
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={true}
          loader={() => {
            return (
              <div>
                <Skeleton type="spinner" />
              </div>
            );
          }}
          scrollableTarget="scrollableDiv"
        >
          <ul role="list" className="divide-y divide-gray-200">
            {data &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <li
                    className={`px-3 ${
                      profile.tenantInfo.type !== "admin" ? "py-3" : "py-1"
                    }
                    ${
                      selectedRow && selectedRow._id == item._id
                        ? "bg-gray-100"
                        : "bg-white"
                    } hover:bg-gray-50 cursor-pointer
                    transition-all duration-500 ease-in-out
                   
                    
                    `}
                    key={item._id}
                    onClick={(e) => {
                      setSelectedRow(item);
                    }}
                  >
                    {/* <div> */}
                    {/* grid grid-cols-12  */}
                    <div className="  transition-all duration-500 ease-in-out">
                      <div className="flex flex-col col-span-9 items-center  space-x-6 sm:space-y-1 md:flex-row px-4 items-start justify-between  ">
                        <div className="flex w-3/4 text-sm items-center">
                          {item &&
                          item.is_team_specific &&
                          item.is_team_specific !== undefined &&
                          item.is_team_specific === true ? (
                            <Icon
                              icon="mdi:user-lock"
                              className="flex h-6 w-6 text-indigo-500 mr-3"
                            />
                          ) : (
                            <UserGroupIcon className="flex h-6 w-6 text-indigo-500 mr-3" />
                          )}
                          <div className="flex whitespace-nowrap mr-3">
                            IM-{item.Index}
                          </div>
                          {RenderTextField(item, "Title")}
                          {/* {renderSubComponent(item, "Title")} */}
                        </div>

                        <div className="col-span-3 flex items-start  justify-start">
                          {RenderActions(item, updateData, setUpdatedId)}
                        </div>
                      </div>

                      {!dedupe && (
                        <div
                          className={`flex flex-col md:flex-row px-4  items-center justify-between
                       
                        `}
                        >
                          <div>
                            <div className="flex items-center text-xs font-medium text-slate-800">
                              Reported by {RenderTeamName(item)}
                            </div>
                            {RenderImpactedTeams(item)}
                          </div>
                        </div>
                      )}
                      {!dedupe && (
                        <div className="flex flex-col py-4 md:flex-row px-4 items-center justify-between  ">
                          <div className="col-span-2 flex items-center justify-center">
                            <div>
                              <p className="flex text-sm font-medium text-slate-800 mb-2">
                                Related Competency{" "}
                                <QuestionToolTip
                                  field={"Related Competency"}
                                  text={
                                    "Which competency is blocked by this impediment?  Note that this is an impediment-wide category and not a team-specific category."
                                  }
                                />
                              </p>
                              <div className=" text-sm font-medium text-slate-800">
                                {RenderCompetencyList(item)}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 flex items-center justify-center">
                            <div>
                              <p className="flex text-sm font-medium text-slate-800  mb-2">
                                Impediment Type{" "}
                                <QuestionToolTip
                                  field={"Impediment Type"}
                                  text={
                                    "This is the type of impediment that is being reported.  Note that this field may be disabled if you are not an Administrator. "
                                  }
                                />
                              </p>
                              <div className=" text-sm font-medium text-slate-800">
                                {RenderImpedimentTypeList(item, profile)}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 flex items-center justify-center">
                            <div>
                              <p className="flex text-sm font-medium text-slate-800 mb-2">
                                Impediment Scope{" "}
                                <QuestionToolTip
                                  field={"Impediment Scope"}
                                  text={
                                    "The scope of the impediment relates to the impact it has and who is best able to resolve it. Note that this field may be disabled if you are not an Administrator."
                                  }
                                />
                              </p>
                              <div className=" text-sm font-medium text-slate-800">
                                {RenderScopeList(item)}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 flex items-center justify-center">
                            <div>
                              <p className="flex  text-sm font-medium text-slate-800 mb-2">
                                Burden{" "}
                                <QuestionToolTip
                                  field={"Burden"}
                                  text={
                                    "How many hours per week are wasted on this impediment?"
                                  }
                                />
                              </p>

                              {RenderNumberColumn(
                                item,
                                // handleTextColumnValueChange2,
                                // handleOnBlur,
                                "score"
                              )}
                            </div>
                          </div>
                          <div className="col-span-1 flex items-center justify-center">
                            <div>
                              <p className="flex  text-sm font-medium text-slate-800 mb-2">
                                Effort{" "}
                                <QuestionToolTip
                                  field={"Effort"}
                                  text={
                                    "How many days of effort required to resolve this impediment?"
                                  }
                                />
                              </p>

                              {RenderNumberColumn(
                                item,
                                // handleTextColumnValueChange2,
                                // handleOnBlur,
                                "effort"
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {!dedupe && (
                        <div className="grid grid-cols-12 px-4 py-0 sm:px-6 gap-x-6 ">
                          <div className="col-span-8">
                            <p className="inline-flex text-sm font-medium text-slate-800">
                              Description
                            </p>
                            <div className="flex w-full items-start text-sm text-gray-500 sm:mt-0">
                              {renderSubComponent(item, "Description")}
                            </div>
                          </div>
                          {profile.tenantInfo.type == "admin" && (
                            <div className="col-span-4  ">
                              <p className="inline-flex text-sm font-medium text-slate-800">
                                Legacy Fields
                              </p>
                              <div className="grid grid-cols-2">
                                {item &&
                                  item.legacy &&
                                  //map keys and values to display
                                  Object.keys(item.legacy).map((key, index) => {
                                    return (
                                      <div className="  w-full items-start text-sm text-gray-700 sm:mt-0">
                                        <div className="col-span-1">
                                          <span className="font-semibold">
                                            {utils.cleanUpKeys(key)}
                                          </span>{" "}
                                          :{" "}
                                          <span className="ml-2">
                                            {item.legacy[key]}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}

                          {/* <div className="sm:flex">Test</div> */}
                        </div>
                      )}
                    </div>
                  </li>
                  // </div>
                );
              })}
          </ul>
        </InfiniteScroll>
      </div>
      {/* </ul> */}
    </div>
  );
}
