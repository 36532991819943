/* This example requires Tailwind CSS v2.0+ */
// import { ExternalLinkIcon } from "@heroicons/react/solid";
import CTAImage from "../../../images/cta-image-1.jpg";
import { HeadingTwo } from "../../../components/HeadingTwo";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { Icon } from "@iconify/react";

export default function CTASplitWithImage() {
  const history = useHistory();
  return (
    <div className="relative bg-gray-800">
      <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img className="w-full h-full object-cover" src={CTAImage} alt="" />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <HeadingTwo variant={"light"}>
            Ready to accelerate your journey?
          </HeadingTwo>
          {/* <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            Connect with DSU Today
          </p> */}
          <p className="mt-3 text-lg text-gray-300">
            If you're ready to take the leap, we're here to support you. Your
            journey is our journey. Let's get started.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <Button
                fullWidth={true}
                color={"white"}
                handleClick={() => {
                  history.push("/home");
                }}
                // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Go to your home page{" "}
                <Icon icon="mdi:arrow-right" className="ml-2 items-center" />{" "}
                {/* <ExternalLinkIcon
                  className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                /> */}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
