import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

import ProfileSidebar from "../components/ProfileSidebar";
import Button from "../components/Button";
import ProfileBody from "../components/ProfileBody";
import ActionPlanPage from "./ActionPlanPage";
import { _assessment } from "../modules/_assessment";

function ActionPlans(...props) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [list, setList] = useState([
    { _id: 1, Name: "Full CD" },
    { _id: 2, Name: "Culture" },
  ]);

  const fullList = [
    { id: 1, Name: "Full CD" },
    { id: 2, Name: "Culture" },
  ];

  return (
    <main>
      <div className="relative flex bg-white">
        {/* Profile sidebar */}
        <ProfileSidebar
          profileSidebarOpen={profileSidebarOpen}
          setProfileSidebarOpen={setProfileSidebarOpen}
          data={list}
          allData={fullList}
          // title={data.Name}
          selected={data._id}
          // setSelected={setSelected}
          setData={setData}
          setList={null}
          title="Action Plans"
        />

        {/* Profile body */}
        <ActionPlanPage />
        {/* <ProfileBody 
            className=""
            profileSidebarOpen={profileSidebarOpen} 
            setProfileSidebarOpen={setProfileSidebarOpen} 
            data={data} 
            title={data.Name} 
            manager = {data.manager}
            assessmentHistory={assessmentHistory}
             /> */}
      </div>
    </main>
  );
}

export default withRouter(ActionPlans);
