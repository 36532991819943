import React, { useState } from "react";
import CustomersTable from "../../partials/customers/CustomersTable";
import PaginationClassic from "../../components/PaginationClassic";
import { customers } from "../../modules/_customers";
import { NavLink } from "react-router-dom";
import Skeleton from "../../components/Skeleton";

function RiskAssessmentHistoryCard(props) {
  const data = props.data;
  const employeeData = props.employeeData ? props.employeeData : null;
  const loading = props.loading;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const reversed = data; // data.length > 0 ? data.slice(0).reverse() : [];

  const [selectedItems, setSelectedItems] = useState([]);
  // const data = customers.getCustomers();
  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  return (
    <div
      className={`col-span-full xl:col-span-${colSpan} xl:row-span-${rowspan}  bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-slate-800 inline-flex">
          Most Recent Assessment History
        </h2>
        <div className="float-right text-primary-500">
          <NavLink to="/assessments">See All -&gt;</NavLink>{" "}
        </div>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <div className="p-3">
          {/* Table */}
          <CustomersTable
            selectedItems={handleSelectedItems}
            data={reversed.slice(0, 10).reverse()}
            fromDashboard={true}
          />
        </div>
      )}
    </div>
  );
}
export default RiskAssessmentHistoryCard;
