import React, { useState } from "react";
import { useEffect } from "react";

import { _user } from "../../modules/_user";
import { NavLink } from "react-router-dom";
import { _auth } from "../../modules/_auth";
import Skeleton from "../../components/Skeleton";

function FormPage(props) {
  const type = props.type;
  const [success, setSuccess] = useState(false);

  const [file, setFile] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setDisabled(false);
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let user = _auth.getUserProfile();

    let done = _user.bulkUserUpdate(file, props.type);
    if (done) {
      setSuccess(true);
      setLoading(false);
    }
  };

  const uploadAgain = () => {
    setFile([]);
    setDisabled(true);
    setSuccess(false);
    setLoading(false);
  };

  return (
    <div className="mx-auto px-2 py-0 space-y-6 ">
      <div className="flex justify-between items-center mb-10">
        <div className="flex items-center">
          {props.type == "add" ? (
            <div>
              <h1 className="text-2xl text-slate-800 font-bold">
                Create new teams
              </h1>
              <p className="text-base text-slate-900 mt-4">
                Use this form to upload a CSV of team managers. This will invite
                them to your company, create a new account for them, and create
                a team where they can invite their developers. Do this once for
                each team manager. They will be able to add additional teams
                once they login.
              </p>
            </div>
          ) : (
            <div>
              <h1 className="text-2xl text-slate-800 font-bold">
                Add new users to your team
              </h1>
              <p className="text-base text-slate-900 mt-4">
                Use this form to upload a CSV of team members. This will NOT
                create new teams. It will add these users to your team so you
                can collaborate.
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        {/* <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
          {!success ? "Bulk add users" : "Upload Successful"}
        </h2> */}
      </div>
      <div className="text-sm mb-10 w-3/4">
        {!success
          ? "You can import up to 100 users at a time using our CSV import."
          : "Your file uploaded successfully. We'll start inviting your users. If there are any problems, you will receive an email."}
      </div>
      <div className="grid">
        <form className="space-y-10">
          {!success && (
            <input
              class="block w-full text-sm text-slate-500 
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100"
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
          )}

          {!success ? (
            <div className="m-1.5">
              <button
                onClick={(e) => {
                  handleOnSubmit(e);
                }}
                disabled={disabled}
                className={`btn btn-sm  bg-primary-500 hover:bg-primary-600 text-white ${
                  file.length === 0 &&
                  "bg-gray-200 hover:bg-gray-200 active:bg-gray-200 disabled:bg-gray-200"
                }`}
              >
                {loading && <Skeleton forButton={true} />}
                Upload
              </button>
            </div>
          ) : (
            <>
              <div className="m-1.5">
                <button
                  onClick={() => {
                    uploadAgain();
                  }}
                  className={`btn btn-sm  bg-primary-500 hover:bg-primary-600 text-white ${
                    file.length === 0 &&
                    "bg-gray-200 hover:bg-gray-200 active:bg-gray-200 disabled:bg-gray-200"
                  }`}
                >
                  Upload another file
                </button>
              </div>

              <div>
                {/* <h3 className="text-sm font-medium text-primary-600 hover:text-primary-500">
                  Your file uploaded successfully. We'll startin
                  inviting your users. If there are any problems, you
                  will receive an email.
                </h3> */}
              </div>
              {/* <div className="mt-4 flex">
                <NavLink
                  to="/home"
                  className="text-sm font-medium text-primary-600 hover:text-primary-500"
                >
                  Take me to my home page.
                  <span aria-hidden="true"> &rarr;</span>
                </NavLink>
              </div> */}
            </>
          )}
        </form>

        <div></div>
      </div>
    </div>
  );
}

export default FormPage;

// <div className="space-y-8 mt-8">
// {/* Input Types */}
// <div>
//   <h2 className="text-2xl text-slate-800 font-bold mb-6">
//     Input Manually
//   </h2>
//   <div className="grid gap-5 md:grid-cols-3">
//     <div>
//       {/* Start */}
//       <div>
//         <label
//           className="block text-sm font-medium mb-1"
//           htmlFor="default"
//         >
//           Default
//         </label>
//         <input
//           id="default"
//           className="form-input w-full"
//           type="text"
//         />
//       </div>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <div>
//         <div className="flex items-center justify-between">
//           <label
//             className="block text-sm font-medium mb-1"
//             htmlFor="tooltip"
//           >
//             W/ Tooltip
//           </label>
//           <Tooltip className="ml-2" bg="dark" size="md">
//             <div className="text-sm text-slate-200">
//               Excepteur sint occaecat cupidata non proident, sunt.
//             </div>
//           </Tooltip>
//         </div>
//         <input
//           id="tooltip"
//           className="form-input w-full"
//           type="text"
//         />
//       </div>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <div>
//         <label
//           className="block text-sm font-medium mb-1"
//           htmlFor="mandatory"
//         >
//           Mandatory <span className="text-red-500">*</span>
//         </label>
//         <input
//           id="mandatory"
//           className="form-input w-full"
//           type="text"
//           required
//         />
//       </div>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <div>
//         <label
//           className="block text-sm font-medium mb-1"
//           htmlFor="prefix"
//         >
//           W/ Prefix
//         </label>
//         <div className="relative">
//           <input
//             id="prefix"
//             className="form-input w-full pl-12"
//             type="text"
//           />
//           <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
//             <span className="text-sm text-slate-400 font-medium px-3">
//               USD
//             </span>
//           </div>
//         </div>
//       </div>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <div>
//         <label
//           className="block text-sm font-medium mb-1"
//           htmlFor="suffix"
//         >
//           W/ Suffix
//         </label>
//         <div className="relative">
//           <input
//             id="suffix"
//             className="form-input w-full pr-8"
//             type="text"
//           />
//           <div className="absolute inset-0 left-auto flex items-center pointer-events-none">
//             <span className="text-sm text-slate-400 font-medium px-3">
//               %
//             </span>
//           </div>
//         </div>
//       </div>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <div>
//         <label
//           className="block text-sm font-medium mb-1"
//           htmlFor="placeholder"
//         >
//           W/ Placeholder
//         </label>
//         <input
//           id="placeholder"
//           className="form-input w-full"
//           type="text"
//           placeholder="Something cool..."
//         />
//       </div>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <form>
//         <label
//           className="block text-sm font-medium mb-1"
//           htmlFor="icon"
//         >
//           W/ Icon
//         </label>
//         <div className="relative">
//           <input
//             id="icon"
//             className="form-input w-full pl-9"
//             type="text"
//           />
//           <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
//             <svg
//               className="w-4 h-4 fill-current text-slate-400 shrink-0 ml-3 mr-2"
//               viewBox="0 0 16 16"
//             >
//               <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
//             </svg>
//           </div>
//         </div>
//       </form>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <div>
//         <div>
//           <label
//             className="block text-sm font-medium mb-1"
//             htmlFor="supporting-text"
//           >
//             W/ Supporting Text
//           </label>
//           <input
//             id="supporting-text"
//             className="form-input w-full"
//             type="text"
//           />
//         </div>
//         <div className="text-xs mt-1">
//           Supporting text goes here!
//         </div>
//       </div>
//       {/* End */}
//     </div>

//     <div>
//       {/* Start */}
//       <form>
//         <label
//           className="block text-sm font-medium mb-1"
//           htmlFor="form-search"
//         >
//           Search
//         </label>
//         <div className="relative">
//           <input
//             id="form-search"
//             className="form-input w-full pl-9"
//             type="search"
//           />
//           <button
//             className="absolute inset-0 right-auto group"
//             type="submit"
//             aria-label="Search"
//           >
//             <svg
//               className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2"
//               viewBox="0 0 16 16"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
//               <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
//             </svg>
//           </button>
//         </div>
//       </form>
//       {/* End */}
//     </div>
//   </div>
// </div>

// {/* Input Sizes */}
// </div>
