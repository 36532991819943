import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "./charts/ChartjsConfig";
import "./css/style.scss";
//Import Layout
import Layout from "./layouts/layout";
import LoginLayout from "./layouts/loginLayout";
import ActionPlan from "./pages/ActionPlan";
import Analytics from "./pages/Analytics";
import Assessment from "./pages/Assessment";
import AssessmentHub from "./pages/AssessmentHub";
import AssessmentSelect from "./pages/AssessmentSelect";
import Calendar from "./pages/Calendar";
import Campaigns from "./pages/Campaigns";
import AccordionPage from "./pages/component/AccordionPage";
import AlertPage from "./pages/component/AlertPage";
import AvatarPage from "./pages/component/AvatarPage";
import BadgePage from "./pages/component/BadgePage";
import BreadcrumbPage from "./pages/component/BreadcrumbPage";
import ButtonPage from "./pages/component/ButtonPage";
import DropdownPage from "./pages/component/DropdownPage";
import FormPage from "./pages/component/FormPage";
import IconsPage from "./pages/component/IconsPage";
import ModalPage from "./pages/component/ModalPage";
import PaginationPage from "./pages/component/PaginationPage";
import TabsPage from "./pages/component/TabsPage";
import TooltipPage from "./pages/component/TooltipPage";
import ContactPage from "./pages/Contact";
// Import pages
import Dashboard from "./pages/Dashboard";
import Cart from "./pages/ecommerce/Cart";
import Cart2 from "./pages/ecommerce/Cart2";
import Customers from "./pages/ecommerce/Customers";
import Invoices from "./pages/ecommerce/Invoices";
import Orders from "./pages/ecommerce/Orders";
import Pay from "./pages/ecommerce/Pay";
import Product from "./pages/ecommerce/Product";
import Shop from "./pages/ecommerce/Shop";
import Shop2 from "./pages/ecommerce/Shop2";
import Employees from "./pages/Employees";
// import EmployeeProfile from "./pages/employees/EmployeeProfile";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Inbox from "./pages/Inbox";
import LearningHub from "./pages/LearningHub";
import Marketplace from "./pages/Marketplace";
import Messages from "./pages/Messages";
import Onboard from "./pages/Onboard";
import Onboarding01 from "./pages/Onboarding01";
import Onboarding02 from "./pages/Onboarding02";
import Onboarding03 from "./pages/Onboarding03";
import Onboarding04 from "./pages/Onboarding04";
import Preassessment01 from "./pages/Preassessment-01";
import Preassessment02 from "./pages/Preassessment-02";
import Preassessment03 from "./pages/Preassessment-03";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordEmail from "./pages/ResetPasswordEmail";
import Account from "./pages/settings/Account";
import Apps from "./pages/settings/Apps";
import Billing from "./pages/settings/Billing";
import Feedback from "./pages/settings/Feedback";
import Notifications from "./pages/settings/Notifications";
import Plans from "./pages/settings/Plans";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import SelectTenant from "./pages/SignInSelectTenant";
import Tasks from "./pages/Tasks";
import Profile from "./pages/team/Profile";
import TeamTabs from "./pages/team/TeamTabs";
import TeamTiles from "./pages/team/TeamTiles";
import UserImport from "./pages/UserImport";
import Changelog from "./pages/utility/Changelog";
import EmptyState from "./pages/utility/EmptyState";
import Faqs from "./pages/utility/Faqs";
import PageNotFound from "./pages/utility/PageNotFound";
import Roadmap from "./pages/utility/Roadmap";
import SignupConfirm from "./pages/Signup Confirm";
import MFA from "./pages/MFA";
import PrivateRoute from "./routes/PrivateRoute";
import VerifyGuest from "./pages/VerifyGuest";
import ThankYou from "./pages/ThankYou";
import AssessmentLayout from "./layouts/assessmentLayout";
import EmployeeProfile from "./pages/EmployeeProfile";
import ToastNotification from "./components/ToastNotification";
import { sockets } from "./modules/_notifications";
import Header from "./partials/Header";
//JDO
import Impediments from "./pages/Impediments";
import Competencies from "./pages/Competencies";
import ActionPlansPage from "./pages/ActionPlansPage";
import ImpedimentDetails from "./pages/impedimentDetails";
import Teams from "./pages/Teams";
import AuditPage from "./pages/AuditPage";
import ValueDrivers from "./pages/valuedrivers/ValueDrivers";
import { SPA } from "./pages/spa/spa";
import MyPlanBoard from "./pages/MyPlan/board";
import AcceptInvite from "./pages/AcceptInvite";
import MyWorkBoard from "./pages/MyPlan/MyWorkBoard";

const socket = sockets.connect();

function Routes() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      {" "}
      {/* <Header /> */}
      <Switch>
        <Route exact path="/">
          <LoginLayout>
            <Signin />
          </LoginLayout>
        </Route>
        <Route exact path="/welcome">
          <AssessmentLayout>
            <SPA />
          </AssessmentLayout>
        </Route>
        <Route exact path="/signin">
          <LoginLayout>
            <Signin />
          </LoginLayout>
        </Route>
        <Route exact path="/signup">
          <LoginLayout>
            <Signup />
          </LoginLayout>
        </Route>
        <Route exact path="/signupconfirmation">
          <LoginLayout>
            <SignupConfirm />
          </LoginLayout>
        </Route>
        <Route exact path="/forgot-password">
          <LoginLayout>
            <ResetPassword />
          </LoginLayout>
        </Route>
        <Route exact path="/reset-password">
          <LoginLayout>
            <ForgotPassword />
          </LoginLayout>
        </Route>
        <Route exact path="/accept-invite/:token/:id/:tenantId">
          <LoginLayout>
            <AcceptInvite />
          </LoginLayout>
        </Route>
        <Route exact path="/reset-password-email/:token/:id">
          <LoginLayout>
            <ResetPasswordEmail />
          </LoginLayout>
        </Route>
        <Route
          exact
          path="/verify-guest/:token/:email/:tenantId/:type/:userId/:inviteId"
        >
          <LoginLayout>
            <VerifyGuest />
          </LoginLayout>
        </Route>
        <Route exact path="/mfa">
          <LoginLayout>
            <MFA />
          </LoginLayout>
        </Route>
        <PrivateRoute exact path="/select-tenant">
          <LoginLayout>
            <SelectTenant />
          </LoginLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contact">
          <Layout>
            <ContactPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/home">
          <Layout>
            <Dashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard">
          <Layout>
            <Dashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/competencies">
          <Layout>
            <Competencies />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/myplan">
          <Layout>
            <MyPlanBoard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/mywork">
          <Layout>
            <MyWorkBoard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/teams">
          <Layout>
            <Teams />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/valuedrivers">
          <Layout>
            <ValueDrivers />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/impediments">
          <Layout>
            <Impediments />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/impediments/details">
          <Layout>
            <ImpedimentDetails />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/audit">
          <Layout>
            <AuditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/actionplans">
          <Layout>
            <ActionPlansPage />
          </Layout>
        </PrivateRoute>
        <Route exact path="/assessmenthub">
          <Layout>
            <AssessmentHub />
          </Layout>
        </Route>
        <PrivateRoute exact path="/learn/regulations">
          <Layout>
            <Profile />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/learn">
          <Layout>
            <Campaigns />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/learninghub">
          <Layout>
            <LearningHub />
          </Layout>
        </PrivateRoute>
        <Route exact path="/assessmentselect">
          <LoginLayout>
            <AssessmentSelect />
          </LoginLayout>
        </Route>
        <Route exact path="/assessment">
          <AssessmentLayout>
            <Assessment />
          </AssessmentLayout>
        </Route>{" "}
        <Route exact path="/thankyou">
          <LoginLayout>
            <ThankYou />
          </LoginLayout>
        </Route>
        <Route exact path="/actionplan">
          <Layout>
            <ActionPlan />
          </Layout>
        </Route>
        <Route exact path="/analytics">
          <Layout>
            <Analytics />
          </Layout>
        </Route>
        <Route exact path="/employees">
          <Layout>
            <Employees />
          </Layout>
        </Route>
        <Route exact path="/employees/profile">
          <Layout>
            <EmployeeProfile />
          </Layout>
        </Route>
        <Route exact path="/team/profile">
          <Layout>
            <Profile />
          </Layout>
        </Route>
        <Route exact path="/assessments">
          <Layout>
            <Customers />
          </Layout>
        </Route>
        <Route exact path="/ecommerce/customers">
          <Layout>
            <Customers />
          </Layout>
        </Route>
        <Route exact path="/ecommerce/orders">
          <Layout>
            <Orders />
          </Layout>
        </Route>
        <Route exact path="/campaigns">
          <Layout>
            <Campaigns />
          </Layout>
        </Route>
        <Route exact path="/ecommerce/invoices">
          <Layout>
            {" "}
            <Invoices />
          </Layout>
        </Route>
        <PrivateRoute exact path="/marketplace">
          <Layout>
            {" "}
            <Marketplace />
          </Layout>
        </PrivateRoute>
        <Route exact path="/preassessment-01">
          <Preassessment01 />
        </Route>
        <Route exact path="/preassessment-02">
          <Preassessment02 />
        </Route>
        <Route exact path="/preassessment-03">
          <Preassessment03 />
        </Route>
        <Route exact path="/ecommerce/shop">
          <Shop />
        </Route>
        <Route exact path="/ecommerce/shop-2">
          <Shop2 />
        </Route>
        <Route exact path="/ecommerce/product">
          <Product />
        </Route>
        <Route exact path="/ecommerce/cart">
          <Cart />
        </Route>
        <Route exact path="/ecommerce/cart-2">
          <Cart2 />
        </Route>
        <Route exact path="/ecommerce/pay">
          <Pay />
        </Route>
        <Route exact path="/team/team-tabs">
          <TeamTabs />
        </Route>
        <Route exact path="/team/team-tiles">
          <TeamTiles />
        </Route>
        <Route exact path="/messages">
          <Messages />
        </Route>
        <Route exact path="/tasks">
          <Tasks />
        </Route>
        <Route exact path="/inbox">
          <Inbox />
        </Route>
        <Route exact path="/calendar">
          <Calendar />
        </Route>
        <PrivateRoute exact path="/settings/account">
          <LoginLayout>
            <Account />
          </LoginLayout>
        </PrivateRoute>
        {/* <Route exact path="/settings/account">
          <Account />
        </Route> */}
        <Route exact path="/settings/notifications">
          <Notifications />
        </Route>
        <Route exact path="/settings/apps">
          <Apps />
        </Route>
        <Route exact path="/settings/plans">
          <Plans />
        </Route>
        <Route exact path="/settings/billing">
          <Billing />
        </Route>
        <Route exact path="/settings/feedback">
          <Feedback />
        </Route>
        <Route exact path="/utility/changelog">
          <Changelog />
        </Route>
        <Route exact path="/utility/roadmap">
          <Roadmap />
        </Route>
        <Route exact path="/utility/faqs">
          <Faqs />
        </Route>
        <Route exact path="/utility/empty-state">
          <EmptyState />
        </Route>
        {/* <Route exact path="/utility/404">
          <PageNotFound />
        </Route> */}
        <Route exact path="/reset-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/forgot-password">
          <ResetPassword />
        </Route>
        <PrivateRoute exact path="/onboarding">
          <Onboard />
        </PrivateRoute>
        <PrivateRoute exact path="/onboarding-02">
          <Onboarding02 />
        </PrivateRoute>
        <PrivateRoute exact path="/onboarding-03">
          <Onboarding03 />
        </PrivateRoute>
        <PrivateRoute exact path="/onboarding-04">
          <Onboarding04 />
        </PrivateRoute>
        <Route exact path="/component/button">
          <ButtonPage />
        </Route>
        <PrivateRoute exact path="/User">
          <Layout>
            <UserImport />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/AddUser">
          <Layout>
            <FormPage />
          </Layout>
        </PrivateRoute>
        <Route exact path="/component/dropdown">
          <DropdownPage />
        </Route>
        <Route exact path="/component/alert">
          <AlertPage />
        </Route>
        <Route exact path="/component/modal">
          <ModalPage />
        </Route>
        <Route exact path="/component/pagination">
          <PaginationPage />
        </Route>
        <Route exact path="/component/tabs">
          <TabsPage />
        </Route>
        <Route exact path="/component/breadcrumb">
          <BreadcrumbPage />
        </Route>
        <Route exact path="/component/badge">
          <BadgePage />
        </Route>
        <Route exact path="/component/avatar">
          <AvatarPage />
        </Route>
        <Route exact path="/component/tooltip">
          <TooltipPage />
        </Route>
        <Route exact path="/component/accordion">
          <AccordionPage />
        </Route>
        <Route exact path="/component/icons">
          <IconsPage />
        </Route>
        <Route path="*">
          <LoginLayout>
            <Signin />
          </LoginLayout>
        </Route>
      </Switch>
      <ToastNotification show={true} socket={socket} />
      {/* </Layout> */}
      {/* </Route> */}
      {/* </Switch> */}
    </>
  );
}

export default Routes;
