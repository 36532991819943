import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";
import setAuthToken from "../config/setAuthToken";
import jwt_decode from "jwt-decode";
import { _auth } from "./_auth";
import { usePersistStore } from "../store/usestore";

export const audit = {
  isProd() {
    return true;
    // return process.env.NODE_ENV === "production";
  },

  profile() {
    return _auth.getUserProfile();
  },

  async logAction(action, details) {
    let profile = this.profile();

    let data = {};

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    data.action = action;
    data.role = profile ? profile.role : "Guest";
    data.email = profile ? profile.email : "";
    data.name = profile ? profile.name : "";
    data.userId = profile ? profile.user_id : "";
    data.tenantId = profile ? profile.tenantId : "";
    data.activityDate = today;
    data.details = details;
    data.company = profile ? profile.company : "";

    if (audit.isProd()) {
      //send to server

      let response = await postRequest("audit/log", data);
      if (response) {
        // return response.data;
        return true;
      } else {
        return false;
      }
    } else {
      //log to console
    }
  },
  getAuditLog() {
    let body = {};

    let data = getRequest("audit/get", body);
    if (data) {
    }

    return data;
  },
};
