import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/24/outline";
import Skeleton from "./Skeleton";
import Button from "./Button";
import { useEffect } from "react";

export default function DialogBox(props) {
  const [open, setOpen] = useState(props.open);
  const [loading, setLoading] = useState(props.loading);
  const [success, setSuccess] = useState(props.success);
  useEffect(() => {
    setOpen(props.open);
    setLoading(props.loading);
    setSuccess(props.success);
  }, [props.open, props.loading, props.success]);

  const cancelButtonRef = useRef(null);
  const {
    title,
    message,
    OKbuttonText,
    CancelButton,
    icon,
    iconColor,
    iconBgColor,
    audit,
    action,
    data,
    auditDetails,
    children,
    cleanUpState,
  } = props;

  const handleConfirmClick = async (e) => {
    setLoading(true);
    await props.handleConfirmClick();

    if (success == true) {
      setTimeout(() => {
        setOpen(false);
        setSuccess(false);
      }, 3000);
    } else {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setOpen(false);
    setLoading(null);
    setSuccess(null);
    cleanUpState && cleanUpState();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleCancelClick}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div id="dialog-body" className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="w-full bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-slate-900"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2 space-y-6">
                        <p className="text-sm text-slate-500">{message}</p>
                        {children}
                        {/* <p className="text-sm text-slate-500">
                          Note that this will not delete any assessments that
                          have already been taken.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4 px-4 py-3 space-x-4 sm:space-x-reverse sm:flex sm:flex-row-reverse sm:px-6">
                  <Button
                    color="red"
                    audit={audit}
                    action={action ? action : ""}
                    details={auditDetails}
                    // className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    handleClick={() => handleConfirmClick(data)}
                  >
                    {OKbuttonText}
                  </Button>
                  {CancelButton && (
                    <Button
                      type="button"
                      color="white"
                      // className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-slate-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      handleClick={() => handleCancelClick()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
