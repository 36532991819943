/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline";

const features = [];

export default function LandingIntro() {
  return (
    <div>
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Welcome to JDO Connect!
          </h1>
        </div>
      </div>
      <div className="">
        <div className="max-w-3xl mx-auto text-left">
          <p className="mt-4  ">
            JDO Connect is a new application designed specifically for the
            Journey to DevOps process. JDO Staff will be able to import
            competency, impediment, and toil data and enable Teams and their
            Managers to effectively select and prioritize their work.
          </p>
          {/* <p className="mt-4 text-md ">Follow this wizard to get started.</p> */}

          {/* <p className="mt-4  ">
            {" "}
            Let's start by completing the onboarding wizard so we can get to
            know you better.{" "}
          </p> */}
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-md leading-6 font-medium text-slate-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base ">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
