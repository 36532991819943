import { LightBulbIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function Tip(props) {
  const { children, color, className, ...rest } = props;
  //   const classes = useStyles();
  const getColor = (color) => {
    switch (color) {
      case "primary":
        return " bg-primary-50 border-primary-500 text-primary-600";
      case "secondary":
        return " bg-secondary-500 bg-opacity-5 border-secondary-500   text-primary-600";
      case "tertiary":
        return " bg-tertiary-200  text-primary-600";
      case "accent":
        return "border-accent-700  bg-opacity-20 bg-accent-500 bg-opacity-20  text-accent-700";
      case "purple":
        return " bg-primary-200 text-primary-600";
      case "orange":
        return " bg-orange-200  text-primary-600";
      case "red":
        return " bg-red-200  text-primary-600";
      case "light-purple":
        return " bg-primary-200  text-purple-500";
      case "lime":
        return " bg-lime-500  text-primary-600 ";
      case "white":
        return " bg-white border-gray-200  text-slate-200 ";
      case "text":
        return "text-slate-500  mx-3 p-1";
      case "link":
        return "text-blue-500 ";
      default:
        return (
          "bg-indigo-50 border-indigo-500   text-primary-600" + props.className
        );
    }
  };
  return (
    <div className="">
      <p
        className={`p-2  ${getColor(
          color
        )}  border-2 border-dashed  text-sm font-normal text-slate-600`}
      >
        {props.showIcon && (
          <LightBulbIcon className="my-auto inline-flex w-5 h-5 mr-1 fill-yellow-400" />
        )}
        {children}
      </p>
    </div>
  );
}
