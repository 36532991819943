import { useEffect, useState } from "react";
import { _user } from "../modules/_user";
import Table from "./Table";
import TableItem from "./TableItem_OLD";

export default function UsersTable(props) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(props.tab);

  useEffect(() => {
    (async () => {
      let users = [];
      if (selectedTab === "My team's users") {
        users = await _user.getTenantUsers(page);
      } else {
        users = await _user.getAllUser(page);
      }

      if (users && users.length > 0) {
        setUserData(users);
      } else {
        setUserData([]);
      }
    })();
  }, [props.tab]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };
  return (
    <div className="absolute">
      <Table
        selectedItems={handleSelectedItems}
        data={userData}
        selectedTab={selectedTab}
        // setModalItem={setModalItem}
        fromDashboard={false}
        setUserData={setUserData}
      />
    </div>
  );
}
