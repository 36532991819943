import React, { useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import ProfileSidebar from "../../partials/team/ProfileSidebar";
import ProfileBody from "../../partials/team/ProfileBody";

function Profile(props) {
  // const title = props.title;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);

  const location = useLocation();
  const data = location.state.data;
  const title = data.title;
  const initials = data.initials;

  useEffect(() => {
    // result: '/secondpage'
    // result: '?query=abc'
    // result: 'some_value'
  }, [location]);

  return (
    <div className="relative flex">
      {/* Profile sidebar */}
      {/* Off for V1 */}
      {/* <ProfileSidebar
        data={data}
        profileSidebarOpen={profileSidebarOpen}
        setProfileSidebarOpen={setProfileSidebarOpen}
      /> */}

      {/* Profile body */}
      <ProfileBody
        data={data}
        profileSidebarOpen={profileSidebarOpen}
        setProfileSidebarOpen={setProfileSidebarOpen}
      />
    </div>
  );
}

export default Profile;
