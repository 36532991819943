export function HeadingTwo({ children, variant }) {
  return (
    <h2
      className={`font-display text-3xl tracking-tight ${
        variant == "dark" ? "text-slate-900" : "text-slate-300"
      } sm:text-4xl`}
    >
      {children}
    </h2>
  );
}
