import React, { useEffect } from "react";
import Comments from "../../components/Comments";
import { _comments } from "../../modules/_comments";
import Tooltip from "../../components/Tooltip";

export default function ImpedimentsCommentsPanel(props) {
  const { impedimentId } = props;
  const [commentText, setCommentText] = React.useState("");
  const [comments, setComments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    (async () => {
      const comments = await _comments.getCommentsByImpediment(impedimentId);
      setComments(comments);
    })();
  }, [props.row]);

  useEffect(() => {}, [comments]);

  useEffect(() => {}, [commentText]);

  const handleAddComment = async () => {
    setLoading(true);
    setTimeout(() => {
      (async () => {
        if (!commentText) return;
        if (commentText == "") return;
        const comment = await _comments.addComment(impedimentId, commentText);
        setComments([...comments, comment]);
        setCommentText("");
        props.updateCommentCount("add");
        setLoading(false);
      })();
    }, 500);
  };

  return (
    <div>
      <h2 className="pt-1 text-xl font-medium text-gray-900">
        Comments{" "}
        <Tooltip
          size="xl"
          bg="dark"
          position={"left"}
          title={"Beta"}
          description={
            "This is a very basic beta version of the comments. We're working on making it more robust."
          }
        >
          <div className="flex text-xs text-accent-700 underline">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="inline mr-2 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </Tooltip>
        <span className="ml-2 capitalize rounded bg-indigo-100 px-2 text-xs font-semibold leading-5 text-indigo-800">
          Beta
        </span>
      </h2>
      <Comments
        comments={comments}
        setCommentText={setCommentText}
        handlePost={handleAddComment}
        audit={true}
        action="Add Comment"
        commentText={commentText}
        auditDetails={{
          impedimentId: impedimentId,
          impediment: props.row.Title,
          commentText: commentText,
        }}
        loading={loading}
      />
    </div>
  );
}
