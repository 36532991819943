import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

const timeline = [
  {
    id: 1,
    content: "Invite your team",
    description:
      "It's easy. Just go to settings, click on the Users tab, and invite them.  They'll receive an email with a link to join your team.",

    iconBackground: "bg-blue-500",
  },
  {
    id: 1,
    content: "Take the Self Assessment",
    description:
      "Take the Self Assessment to identify your competencies. Start and stop it anytime you need.",

    iconBackground: "bg-blue-500",
  },
  {
    id: 1,
    content: "Review the action plan",
    description:
      "We want to help you succeed, so we've created and are constantly updating the action plan.",

    iconBackground: "bg-blue-500",
  },
  {
    id: 2,
    content: "Claim your impediments",
    description:
      "Claim each impediment you experience. This is a critical step to helping you calculate your time savings. Don't see your impediment? Add it!",

    iconBackground: "bg-blue-500",
  },
  {
    id: 3,
    content: "Link your impediments to competencies",
    description:
      "Linking your impediments to competencies will help you understand how impediments affect your ability to perform, which will help you determine which competencies to focus on.",

    iconBackground: "bg-blue-500",
  },
  {
    id: 4,
    content: "Estimate your burden and effort",
    description:
      "Don't overthink it - but do give it some thought.  We'll use your estimates to calculate your time savings and help you understand how much time you can save by improving your competencies and eliminating the impediments that block them.",

    iconBackground: "bg-blue-500",
  },
  {
    id: 5,
    content: "Make progress",
    description:
      "Keep an eye on the dashboard and as you make progress, head over to the competencies page, where you can update your level without taking the assessment again.",

    iconBackground: "bg-blue-500",
  },
  {
    id: 6,
    content: "Repeat with your other teams",
    description:
      "You can create as many teams as you need.  Each team has its own dashboard, impediments, and competencies.",
    iconBackground: "bg-blue-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GettingStarted() {
  return (
    <div
      className={`col-span-full  row-span-1  bg-white p-2  overflow-hidden grow-0`}
    >
      {/* <header className="flex items-center justify-between  px-5 py-4 border-b border-gray-100">
        <h2 className="  font-semibold text-slate-800">Getting Started</h2>
      </header> */}

      <div className="flow-root ">
        <ul role="list" className="-mb-8">
          {timeline.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        "text-white h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      {eventIdx + 1}
                      {/* <event.icon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      /> */}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-700 font-medium">
                        {event.content}{" "}
                      </p>
                      <p className="text-sm text-gray-500">
                        {event.description}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>

    // <div className="bg-white shadow p-6 overflow-hidden sm:rounded-lg">
    //   <div className="flow-root ">
    //     <ul role="list" className="-mb-8">
    //       {timeline.map((event, eventIdx) => (
    //         <li key={event.id}>
    //           <div className="relative pb-8">
    //             {eventIdx !== timeline.length - 1 ? (
    //               <span
    //                 className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
    //                 aria-hidden="true"
    //               />
    //             ) : null}
    //             <div className="relative flex space-x-3">
    //               <div>
    //                 <span
    //                   className={classNames(
    //                     event.iconBackground,
    //                     "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
    //                   )}
    //                 >
    //                   <event.icon
    //                     className="h-5 w-5 text-white"
    //                     aria-hidden="true"
    //                   />
    //                 </span>
    //               </div>
    //               <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
    //                 <div>
    //                   <p className="text-sm text-gray-500">
    //                     {event.content}{" "}
    //                     {/* <a
    //                       href={event.href}
    //                       className="font-medium text-gray-900"
    //                     >
    //                       {event.target}
    //                     </a> */}
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    // </div>
  );
}
