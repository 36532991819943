import React from "react";
import Tooltip from "./Tooltip";
import { Icon } from "@iconify/react";

export default function QuestionToolTip(props) {
  const { field, text, children, color, position, className, ...rest } = props;

  return (
    // <div>
    //   <Icon
    //     data-tooltip-target="tooltip-animation"
    //     type="button"
    //     icon="carbon:help"
    //     className="text-secondary-600"
    //   />
    //   <div
    //     id="tooltip-animation"
    //     role="tooltip"
    //     class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
    //   >
    //     Tooltip content
    //     <div class="tooltip-arrow" data-popper-arrow></div>
    //   </div>
    // </div>

    <Tooltip
      className={" text-xs text-slate-500 whitespace-normal inline-flex"}
      position={position ? position : "top"}
      size="md"
      description={text}
      title={field}
    >
      <Icon icon="carbon:help" className="ml-2 z-0  text-secondary-600" />
    </Tooltip>
  );
}
