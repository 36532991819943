import React from "react";

import { useState } from "react";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Input from "../components/Input";
import InputList from "./InputList";

import { _user } from "../modules/_user";

export function InviteUser(props) {
  const type = props.type;
  const [open, setOpen] = useState(true);
  const [isValidForSignup, setIsValidForSignup] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeInvalid, SetAccessCodeInvalid] = useState(false);
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState("");
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageRole, setErrorMessageRole] = useState("");
  const [role, setRole] = useState("");
  const [errorMessageCompanyName, setErrorMessageCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const currentUserTenant = _auth.getUserProfile();

  const profile = props.profile;
  const onChangeText = (value, variant) => {
    setSignUpErrorMessage("");

    if (variant === "email") {
      setEmail(value);
      setErrorMessageEmail("");
    } else if (variant === "username") {
      setFullName(value);
      setErrorMessageName("");
    } else if (variant === "password") {
      setPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "confirmpassword") {
      setConfirmPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "companyName") {
      setCompanyName(value);
      setErrorMessageCompanyName("");
    } else if (variant === "accessCode") {
      setAccessCode(value);
      setAccessCodeErrorMessage(false);
    } else if (variant === "role") {
      setRole(value.value);
      setErrorMessageRole("");
      //   setSignUpErrorMessage(value.name);
    }
  };
  const onChangeDropDown = (value, variant) => {
    if (variant === "role") {
      setRole(value.value);
      setErrorMessageRole("");
      //   setSignUpErrorMessage(value.name);
    }
  };

  const sgn = async () => {
    // let resp = await _auth.addUser();
    (async () => {
      //

      let companyRole = null;
      let sysRole = null;
      if (role === "Super Admin") {
        sysRole = "Super Admin";
        companyRole = "Administrator";
        setRole("Manager");
      } else if (role === "Administrator") {
        sysRole = null;
        companyRole = "Administrator";
        setRole("Manager");
      } else if (role === "Manager") {
        sysRole = null;
        companyRole = "User";
        setRole("Manager");
      } else if (role === "Collaborator") {
        sysRole = null;
        companyRole = "User";
        setRole("Collaborator");
      } else if (role === "Observer") {
        sysRole = null;
        companyRole = "Observer";
        setRole("Observer");
      } else {
        sysRole = null;
        companyRole = "User";
        setRole("Manager");
      }

      let data = {
        name: fullName,
        email: email,
        role: role,
        sysRole: sysRole,
        companyRole: companyRole,
        tenantId: props.type == "add" ? null : currentUserTenant.tenantId,
        admin: currentUserTenant.name,
        tenantName: props.type == "add" ? null : currentUserTenant.tenantName,
        company: currentUserTenant.company,
        companyName: currentUserTenant.company,
        companyId: currentUserTenant.companyId,
        type: props.type,
        createTenant: props.type == "add" ? true : false,
        createCompany: false,
      };

      const response = await _user.addSingleUser(data);

      if (response && response.data) {
        setSuccess(true);
        setLoading(false);
        setSignUpErrorMessage("Add users successful");
        // return response;
      } else {
        setSuccess(false);
        setLoading(false);
        setSignUpErrorMessage(response.response.data.errorMessage);
        // return response;
      }

      //   return response;
    })();
  };

  const inviteAgain = () => {
    setSuccess(false);
    setEmail("");
    setPassword("");
    setFullName("");
    setCompanyName("");
    setAccessCode("");
    setRole("");
    setErrorMessageCompanyName("");
    setErrorMessageEmail("");
    setErrorMessageName("");
    setErrorMessagePassword("");
    setErrorMessageRole("");
    setAccessCodeErrorMessage("");
    setSignUpErrorMessage("");
  };

  const onRegister = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let amIvalid = true;
    setLoading(true);

    setIsValidForSignup(true);
    const isValid = new Promise((resolve, reject) => {
      let emailRegex = new RegExp(/\S+@\S+\.\S+/);

      if (!fullName) {
        setErrorMessageName("Please enter your full name");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!email || !emailRegex.test(email)) {
        setErrorMessageEmail("Please enter a valid email address");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      if (!email || !emailRegex.test(email)) {
        setErrorMessageEmail("Please use a company email address");
        amIvalid = false;
        setIsValidForSignup(false);
      }
      //   if (!companyName) {

      //     setErrorMessageCompanyName("Please enter your company name");
      //     amIvalid = false;
      //     setIsValidForSignup(false);
      //   }
      // if (!password) {

      //   setErrorMessagePassword("Please enter a password");
      //   setIsValidForSignup(false);
      // }

      // if (password.toString() !== confirmPassword.toString()) {

      //   setErrorMessagePassword("The passwords you entered do not match");
      //   setIsValidForSignup(false);
      // }

      // I have done this because you are using promises and I want to use async await - and this is know as IEEE function

      resolve("Success!");
    })
      .then(() => {
        const isNaughtyList = new Promise((resolve, reject) => {
          let isNaughty = _auth.checkNaughtyList(email);
          resolve(isNaughty);
        })
          .then((naughty) => {
            if (naughty === true) {
              amIvalid = false;
              setErrorMessageEmail("Personal emails are not allowed");
              console.info(
                "Found in naughty list, setting IsValidForSignup to false"
              );
              setIsValidForSignup(false);
            } else {
              console.info(
                "Not found in naughty list, setting IsValidForSignup to true"
              );
              setIsValidForSignup(true);
            }
          })
          .then(() => {
            if (amIvalid === false) {
              console.info(
                "Cannot start creation, not valid",
                isValidForSignup
              );
              setLoading(false);
            } else {
              setLoading(true);
              console.info(
                "Everything passed, signing up",
                email,
                fullName,
                role
              );
              sgn();
              // .then((resp) => {

              //   if (resp) {

              //     setLoading(false);
              //     setSuccess(true);
              //     // history.push("/signupconfirmation");
              //   } else {

              //     setLoading(false);
              //     setSignUpErrorMessage(resp.errorMessage);
              //     setSuccess(true);
              //   }
              // })
              // .catch((error) => {
              //   setLoading(false);
              //   setSuccess(false);
              //
              //   setSignUpErrorMessage(error.errorMessage);
              // });
            }
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };
  return (
    <div className="max-w-md mx-auto px-4 py-0">
      <div className="flex justify-between items-center mb-10">
        <div className="flex items-center">
          {
            //check whether I should be using 'add' or something else
            props.type == "add" ? (
              <div>
                <h1 className="text-2xl text-slate-800 font-bold">
                  Add single user
                </h1>
                <p className="text-base text-slate-900 mt-4">
                  Use this form invite a single manager to create an account and
                  set up a team.
                </p>
              </div>
            ) : (
              <div>
                <h1 className="text-2xl text-slate-800 font-bold">
                  Add single user
                </h1>
                <p className="text-base text-slate-900 mt-4">
                  Use this form to invite a single user to join your team to
                  collaborate with you.
                </p>
              </div>
            )
          }
        </div>
      </div>
      {/* <NotificationBanner
        className={"sticky top-0"}
        setOpen={setOpen}
        text={"Not working yet"}
        showClose={false}
      /> */}
      {/* <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
        {success ? "Invitation sent!" : "Invite a user"}
      </h2> */}
      {!success ? (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <form className="space-y-6" method="post">
            <Input
              type="text"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm "
              label="Full Name"
              placeholder="Full name"
              id="input"
              value={fullName}
              onChange={(e) => onChangeText(e.target.value, "username")}
              errorText={errorMessageName ? errorMessageName : undefined}
            />
            <Input
              type="text"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm"
              label="Work Email address"
              placeholder="Work email address"
              id="input"
              value={email}
              onChange={(e) => onChangeText(e.target.value, "email")}
              errorText={errorMessageEmail ? errorMessageEmail : undefined}
            />
            <InputList
              profile={profile}
              // setSelectedRole={(e) => onChangeText(e.target.value, "role")}
              onChangeDropDown={(e) => onChangeDropDown(e, "role")}
            />
          </form>

          <div className="flex items-center justify-between mt-5">
            {signUpErrorMessage !== "" && (
              <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                {signUpErrorMessage}
              </div>
            )}
          </div>
          <div className="mt-5">
            <Button
              color="secondary"
              fullWidth={true}
              disabled={
                fullName.length == 0 || email.length == 0 ? true : false
              }
              loader={loading}
              type="submit"
              className="btn btn-primary"
              onClick={onRegister}
              handleClick={onRegister}
              audit={true}
              action="Invite User"
              details={{ user: fullName, email: email, role: role }}
            >
              Send Invitation
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-sm mb-10 ">
          {""}
          <div className="mt-5">
            <Button
              // enabled={!loader}
              loader={loading}
              type="submit"
              className="btn btn-primary"
              onClick={inviteAgain}
              handleClick={inviteAgain}
            >
              Invite another person
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
