import HeroImage from "../../../images/road-map.jpg";
const timeline = [
  {
    name: "Establish your basecamp.",
    description:
      "Knowing where you are is the first step to knowing how to get where you want to go. Taking the time to establish your basecamp will help you understand your starting point and give you a clear picture of your current situation.",
    date: "Aug 2021",
    dateTime: "2021-08",
  },
  {
    name: "Set your goals.",
    description:
      "Once you know where you are, you can start to think about where you want to go. Setting goals will help you define your destination and give you a clear picture of your desired situation.",
    date: "Dec 2021",
    dateTime: "2021-12",
  },
  {
    name: "Identify your obstacles.",
    description:
      "Knowing where you are and where you want to go is great, but you also need to know what's in your way. Identifying your obstacles will help you understand what you need to overcome in order to reach your destination.",
    date: "Feb 2022",
    dateTime: "2022-02",
  },
  {
    name: "Estimate your effort.",
    description:
      "Once you know what's in your way, you can start to think about how to get around it. Estimating your effort will help you understand what it will take to overcome your obstacles and reach your destination.",
    date: "Dec 2022",
    dateTime: "2022-12",
  },
  {
    name: "Understand your potenial.",
    description:
      "Another benefit to understanding your obstacles is that it will help you understand your potential. Knowing where you spend your time and energy will help you see where you will get time and energy back once you overcome your obstacles. This will help you understand your potential and give you a clear picture of what you can achieve once you reach your destination.",
    date: "Dec 2022",
    dateTime: "2022-12",
  },
  {
    name: "Prioritize your milestones.",
    description:
      "Once you know what you want to achieve and what it will take to achieve it, you can start to think about how to get there. Prioritizing your milestones will help you understand what you need to do first and what you can do later. This will help you create a clear path to your destination and give you a clear picture of how to get there.",
    date: "Dec 2022",
    dateTime: "2022-12",
  },
  {
    name: "Create your roadmap.",
    description:
      "Now that you know where you are, where you want to go, what's in your way, what it will take to get around it, what you will get back, and what you need to do first, you can start to think about how to get there. Creating your roadmap will help you understand what you need to do and when you need to do it. This will help you create a clear path to your destination and give you a clear picture of how to get there.",
    date: "Dec 2022",
    dateTime: "2022-12",
  },
  {
    name: "Clear the path.",
    description:
      "The final step is to clear the path.  Remove the obstacles that are in your way and start working towards your destination once competency and impediment at a time.  ",
    date: "Dec 2022",
    dateTime: "2022-12",
  },
];

export default function FourColumnStats() {
  return (
    <div className="w-full h-full relative isolate   bg-slate-900 ">
      <img
        src={HeroImage}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />

      <div className="h-full bg-white bg-opacity-90  py-20 sm:py-20">
        <div className="h-full mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-full md:text-left pb-10">
            <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
              The basic roadmap
            </h2>
            <p className="mt-4 text-lg tracking-tight text-slate-700">
              There's a lot to understand and we know it's not easy. We've
              created a simple framework to help you get started!
            </p>
          </div>
          <div
            className="h-full mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-y-auto lg:mx-0 lg:max-w-none lg:grid-cols-2 3xl:grid-cols-4 scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
          >
            {timeline.map((item) => (
              <div key={item.name}>
                {/* <time
                dateTime={item.dateTime}
                className="flex items-center text-sm font-semibold leading-6 text-indigo-600"
              >
                <svg
                  viewBox="0 0 4 4"
                  className="mr-4 h-1 w-1 flex-none"
                  aria-hidden="true"
                >
                  <circle cx={2} cy={2} r={2} fill="currentColor" />
                </svg>
                {item.date}
                <div
                  className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                  aria-hidden="true"
                />
              </time> */}
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                  {item.name}
                </p>
                <p className="mt-1 text-base leading-7 text-gray-600">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
