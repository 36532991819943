import React, { useState } from "react";
import { useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import Table, {
  SelectColumnFilter,
  GlobalFilter,
} from "../components/ReactTable";

import Button from "../components/Button";
import { FeatureFlag } from "../components/FeatureFlag";
import {
  ChevronDoubleDownIcon,
  DocumentPlusIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { _competencies } from "../modules/competencies";
import SimpleCard from "../components/SimpleCard";
import RightActionArea from "../components/ActionAreaRight";
import ButtonGroup from "../components/ButtonGroup";
import AssessmentSelect from "./AssessmentSelect";
import ModalBasic from "../components/ModalBasic";
import Skeleton from "../components/Skeleton";
import AssessmentTemplateCreator from "../components/AssessmentTemplatesCreator";
import AssessmentTemplateQuestionEditor from "../components/AssessmentTemplatesQuestEditor";
import { Icon } from "@iconify/react";
import { _auth } from "../modules/_auth";
import { audit } from "../modules/_audit";
import { Plan } from "../modules/plan";
import DialogBox from "../components/Dialog";
import DataEntryDialog from "../components/DataEntryDialog";
import PlanSelectCard from "./MyPlan/parts/planSelectCard";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { Switch } from "@headlessui/react";
const XLSX = require("xlsx");
const areas = [
  "Culture",
  "Plan",
  "Code",
  "Build",
  "Test",
  "Release",
  "Deploy",
  "Operate",
  "Monitor",
  "Respond",
];
const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

function Competencies(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allCompetencies, setAllCompetencies] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [questions, setQuestions] = useState([{}]);
  const [teamCompetencies, setTeamCompetencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [view, setView] = useState("grid");
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const [viewImpedimentModalOpen, setViewImpedimentModalOpen] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [selectedImpediment, setSelectedImpediment] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [thisAssessment, setThisAssessment] = useState(null);
  const [selectedNewLevel, setSelectedNewLevel] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(
    props.selectedPlan ? props.selectedPlan : null
  );
  const [selectedTargetDate, setSelectedTargetDate] = useState(null);
  const [competencyListOptions, setCompetencyListOptions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [showSelector, setShowSelector] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [descriptionSearchValue, setDescriptionSearchValue] = useState("");
  const [onlyShowKeyCompetencies, setOnlyShowKeyCompetencies] = useState(
    sessionStorage.getItem("onlyShowKeyCompetencies")
      ? sessionStorage.getItem("onlyShowKeyCompetencies") === "true"
        ? true
        : false
      : false
  );
  const modal = props.modal ? props.modal : false;
  const updatePlan = props.updatePlan ? props.updatePlan : false;

  const [isDraft, setIsDraft] = useState(false);
  const history = useHistory();
  const stc = true;

  const profile = _auth.getUserProfile();

  const addQuestionRef = useRef(null);

  //get employees from api

  useEffect(() => {
    (async () => {
      if (profile.tenantInfo.type !== "admin") {
        let teamCompList = await _competencies.getTeamCompetenciesFromSurvey();

        if (teamCompList && teamCompList.data && teamCompList.data.length > 0) {
          let newCompList = teamCompList.data;
          setAssessmentId(teamCompList.assessmentId);
          setAllCompetencies(newCompList);
          // Check if onlyShowKeyCompetencies is true
          if (sessionStorage.getItem("onlyShowKeyCompetencies") === "true") {
            newCompList = newCompList.filter((comp) => comp.isKeyCompetency);
          }

          setCompetencies(newCompList);

          setThisAssessment(teamCompList.thisAssessment);
        } else {
          setIsDraft(
            teamCompList && teamCompList.assessment_status == "Draft"
              ? true
              : false
          );
          setThisAssessment(teamCompList.thisAssessment);
          let compList = await _competencies.getGlobalCompetencies();
          if (compList) {
            setAllCompetencies(compList);
            // Check if onlyShowKeyCompetencies is true
            if (sessionStorage.getItem("onlyShowKeyCompetencies") === "true") {
              compList = compList.filter((comp) => comp.isKeyCompetency);
            }

            setCompetencies(compList);
          }
        }
      } else {
        let compList = await _competencies.getGlobalCompetencies();
        if (compList) {
          setAllCompetencies(compList);
          // Check if onlyShowKeyCompetencies is true
          if (sessionStorage.getItem("onlyShowKeyCompetencies") === "true") {
            compList = compList.filter((comp) => comp.isKeyCompetency);
          }

          setCompetencies(compList);
        }
      }
    })();
  }, [stc]);

  useEffect(() => {
    //map areas and add each area to expanded array
    let a = [
      "Culture",
      "Plan",
      "Code",
      "Build",
      "Test",
      "Release",
      "Deploy",
      "Operate",
      "Monitor",
      "Respond",
    ];
    setExpanded(a);
  }, [areas]);

  useEffect(() => {
    //execute search 1 after 1 second

    if (searchValue.length < 3) {
      if (descriptionSearchValue !== "") {
        setCompetencies(competencies);
      } else {
        setCompetencies(allCompetencies);
      }
      return;
    }
    let data;

    if (descriptionSearchValue !== "") {
      data = competencies;
    } else {
      data = allCompetencies;
    }

    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      searchValue.length > 2
        ? setCompetencies(
            data.filter((item) =>
              item.Name.toLowerCase().includes(searchValue.toLowerCase())
            )
          )
        : setCompetencies(data);
    }, 300);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
    setDescriptionSearchValue("");

    //filter competencies based on onlyShowKeyCompetencies using isKeyComptency field
    if (onlyShowKeyCompetencies && onlyShowKeyCompetencies === true) {
      setCompetencies(
        competencies.filter((item) => item.isKeyCompetency === true)
      );
    } else {
      setCompetencies(allCompetencies);
    }
  }, [onlyShowKeyCompetencies]);

  useEffect(() => {
    //execute search 1 after 1 second
    if (descriptionSearchValue.length < 3) {
      if (searchValue !== "") {
        setCompetencies(competencies);
      } else {
        setCompetencies(allCompetencies);
      }
      return;
    }
    let data;
    if (searchValue !== "") {
      data = competencies;
    } else {
      data = allCompetencies;
    }

    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      descriptionSearchValue.length > 2
        ? setCompetencies(
            allCompetencies.filter(
              (item) =>
                //filter item.answer_options array for match
                item.answer_options.filter((option) =>
                  option
                    .toLowerCase()
                    .includes(descriptionSearchValue.toLowerCase())
                ).length > 0
            )
          )
        : setCompetencies(allCompetencies);
    }, 300);
  }, [descriptionSearchValue]);

  useEffect(() => {
    (async () => {
      let allplans = await Plan.getAllPlans();

      if (allplans) {
        setPlans(allplans);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let compList = await _competencies.getCompetencyList();
      if (compList) {
        setCompetencyListOptions(compList);
      } else {
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let teamCompList = await _competencies.getTeamCompetenciesFromSurvey();

      if (teamCompList && teamCompList.data && teamCompList.data.length > 0) {
        setAssessmentId(teamCompList.assessmentId);
        // setCompetencies(newCompList);
        setThisAssessment(teamCompList.thisAssessment);
      }
    })();
  }, [competencies]);

  const addImpedimentToCompetency = (impediment) => {
    const newCompetency = { ...selectedCompetency };
    newCompetency.impediments.push(impediment);
    // find and remove old competency and add new one
    const newCompetencies = competencies.map((c) => {
      if (c._id === newCompetency._id) {
        return newCompetency;
      }
      return c;
    });
    setCompetencies(newCompetencies);
  };

  const removeQuestionByIndex = (index) => {
    const newQuestions = questions.filter((q, i) => i !== index);
    setQuestions(newQuestions);
    // props.setQuestions(newQuestions);
  };
  const handleChangeCompetencyLevel = async (data, new_answer) => {
    const updated = await _competencies.changeCompetencyLevel(
      assessmentId,
      data._id,
      new_answer
    );

    if (updated) {
      setCompetencies((old) =>
        old.map((row, index) => {
          if (row._id === data._id) {
            return {
              ...row,
              your_answer: new_answer,
            };
          }
          return row;
        })
      );
    }
    const auditDetails = {
      assessmentId: assessmentId,
      user: profile.email,
      area: data.Area,
      name: data.Name,
      old_answer: data.your_answer,
      new_answer: new_answer,
    };
    audit.logAction("Change Competency Level", auditDetails);
  };
  const getLevelName = (level) => {
    switch (level) {
      case 0:
        return "Beginner";
      case 1:
        return "Intermediate";
      case 2:
        return "Advanced";
      case 3:
        return "Expert";
      default:
        return "Not Scored";
    }
  };
  const getLevelIndex = (score) => {
    let originalScore = score;
    score = score - 1;
    let level;
    let levelName = "";
    // 0 to 1.9 = Beginner
    // 2 to 2.9 = Intermediate
    // 3 to 3.9 = Advanced
    // 4 = Expert
    if (score >= 0 && score < 2) {
      level = 0;
    } else if (score >= 2 && score < 3) {
      level = 1;
    } else if (score >= 3 && score < 4) {
      level = 2;
    } else if (score >= 4) {
      level = 3;
    } else {
      level = 0;
    }
    // levelName = getLevelName(level);

    return level;
  };
  const exportToExcel = (data, name) => {
    //put the data into a flat format for excel
    // const flatData = data.map((item) => {
    //   let flatItem = {};
    //   flatItem.Team = item.Team;
    let flatData = [];

    data.forEach((item) => {
      let flatItem = {};
      flatItem.Area = item.Area;
      flatItem.Key = item.key;
      flatItem.Name = item.Name;
      flatItem.Score = item.score;
      flatItem.maturity = getLevelIndex(item.score);
      flatItem.myAnswer = item.answer_options[item.score - 1];
      flatItem.Beginner = item.answer_options[0];
      flatItem.Intermediate = item.answer_options[1];
      flatItem.Advanced = item.answer_options[2];
      flatItem.Expert = item.answer_options[3];
      flatData.push(flatItem);
    });

    // create a new sheet with 1 row for each competency and highlight the answer option that matches the best answer

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(flatData);
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, name + ".xlsx");
  };

  function createCompetencySheet(data, name) {
    const workbook = XLSX.utils.book_new();
    const competencySheetName = "Competency Sheet";
    const header = [
      "Area",
      "Key",
      "Name",
      "Score",
      "Maturity",
      "My Answer",
      "Beginner",
      "Intermediate",
      "Advanced",
      "Expert",
    ];

    const sheetData = data.map((item) => {
      const bestAnswerIndex = getLevelIndex(item.score);
      const myLevel = getLevelName(bestAnswerIndex);
      const rowData = [
        item.Area,
        item.key,
        item.Name,
        bestAnswerIndex + 1,
        myLevel,
        item.answer_options[bestAnswerIndex],
        item.answer_options[0],
        item.answer_options[1],
        item.answer_options[2],
        item.answer_options[3],
      ];

      // Highlight the best answer by adding a style to the cell
      rowData[6] = {
        t: "s", // 's' indicates the value is a shared string (for styling)
        v: rowData[6],
        s: { font: { bold: true, color: { rgb: "FFFF00" } } }, // Highlighted cell style
      };

      return rowData;
    });

    const competencySheet = XLSX.utils.aoa_to_sheet([header, ...sheetData]);
    XLSX.utils.book_append_sheet(
      workbook,
      competencySheet,
      competencySheetName
    );

    // Save the workbook to a file or return it, depending on your needs
    // Example to save it to a file:
    XLSX.writeFile(workbook, "competency_sheet.xlsx");
  }

  const handleAddToMyPlan = async (selected, b, c) => {
    //if this is open from modal, directly add to plan without selecting a plan
    //assume the plan id is props.planId
    if (modal && modal === true) {
      addToMyPlan(null, selected, b);
      setSelectedPlan(props.plan);
      setSelectedCompetency(selected);
      setSelectedNewLevel(b);

      //
      // const added = await Plan.addCompetencyToPlan(
      //   props.planId,
      //   selectedNewLevel,
      //   selectedCompetency
      // );
      //
      // if (added) {
      //   props.updatePlan(added);
      //   cleanUpState();
      // }
    } else {
      //open dialog to select plan
      setPlanModalOpen(true);
      setSelectedCompetency(selected);
      setSelectedNewLevel(b);
    }
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
    // setShowSelector(false);
  };

  const addToMyPlan = async (e, sComp, sLevel) => {
    e?.preventDefault();
    //also need new_answer level and current level
    const plan = selectPlan;

    const added = await Plan.addCompetencyToPlan(
      selectedPlan ? selectedPlan._id : props.plan ? props.plan._id : null,
      sLevel ? sLevel : selectedNewLevel,
      sComp ? sComp : selectedCompetency
    );

    if (added) {
      props.updatePlan(added);
      setPlanModalOpen(false);
      setShowSelector(true);
      // const auditDetails = {
      //   email: profile.email,
      //   name: profile.name,
      //   action: "Add Competency To Plan",
      //   details: {
      //     competencyId: assessmentId,

      //     plan_id: plan_id,
      //   },
      // };
      // audit.logAction("Add Competency To Plan", auditDetails);
      setSelectedCompetency(null);
      cleanUpState();
    }
  };

  const cleanUpState = () => {
    setSelectedCompetency(null);
    setSelectedNewLevel(null);
    setSelectedPlan(null);
    setSelectedTargetDate(null);
    setShowSelector(true);
  };

  const updateQuestionsByIndex = (index, update) => {
    const field = Object.keys(update)[0];
    const value = Object.values(update)[0];

    setQuestions((questions) => {
      const newQuestions = [...questions];
      newQuestions[index][field] = value;
      return newQuestions;
    });
  };

  const replaceQuestion = (question) => {
    const newQuestions = questions.map((q) => {
      if (q._id == question._id) {
        return question;
      } else {
        return q;
      }
    });
    setQuestions(newQuestions);
  };

  const viewDetails = (row, list) => {
    //
    let state = { data: row, list: list };
    let path = "/employees/profile";
    return history.push({
      pathname: path,
      state: state,
    });
  };

  const claim = (e, row, list) => {
    e.preventDefault();
    //
    //set loading for 2 second
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handleClick = (viewType) => {
    setView(viewType);
  };

  const handleSetImpedimentModalOPen = (data) => {
    setImpedimentModalOpen(true);
    setSelectedCompetency(data);
  };

  const handleSetViewImpedimentModalOpen = (data) => {
    setViewImpedimentModalOpen(true);
    setSelectedCompetency(data);
    setSelectedImpediment(data.impediments);
  };

  const goToActionPlan = () => {
    return history.push({
      pathname: "/actionPlan",

      state: {
        test: "props.counts",
        data: "props.counts",
        thisAssessment: thisAssessment,
      },
    });
  };

  const resumeAssessment = () => {
    let state = {
      isPreassessment: false,

      thisAssessment: thisAssessment,
      assessment_type: thisAssessment.assessment_type,
      resume: true,
    };

    // getUserAssessmentHistory()

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };

  const handleExpandCollapseClick = (area) => {
    if (expanded.includes(area)) {
      //remove from array
      const newExpanded = expanded.filter((a) => a !== area);
      setExpanded(newExpanded);
    } else {
      //add to array
      setExpanded([...expanded, area]);
    }
  };
  const handleClearAllSelections = (field) => {
    if (field === "Name") {
      setSearchValue("");

      // setData(employees);
    }
    if (field === "Description") {
      setDescriptionSearchValue("");

      // setData(employees);
    }
  };

  const handleSearchList = (e, field) => {
    if (field === "Name") {
      setSearchValue(e.target.value);
    }
    if (field === "Description") {
      setDescriptionSearchValue(e.target.value);
    }
  };

  return (
    <>
      {/* <div className="bg-white px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto space-y-6"> */}
      <div className=" pt-3 px-6 h-24  space-y-2 pb-2 mb-0">
        {!modal && (
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            <div className="sm:flex sm:justify-between items-center ">
              <h2 className="text-xl flex-1 font-medium text-slate-800">
                Competencies
              </h2>
            </div>

            {!modal && (
              <RightActionArea>
                {profile.tenantInfo.type == "admin" && (
                  <Button
                    color="accent"
                    size="sm"
                    handleClick={() =>
                      createCompetencySheet(competencies, "My Results")
                    }
                    className="ml-4"
                  >
                    <Icon
                      //download
                      icon="carbon:download"
                      className="h-4 w-4"
                      aria-hidden="true"
                    />
                    <span className="hidden xs:block ml-2">
                      Export Results{" "}
                    </span>
                  </Button>
                )}
                {/* <ButtonGroup handleClick={(viewType) => handleClick(viewType)} /> */}

                {thisAssessment &&
                  thisAssessment !== null &&
                  thisAssessment !== undefined &&
                  thisAssessment.assessment_status == "Finished" && (
                    <Button
                      color="lime"
                      size="sm"
                      handleClick={() => goToActionPlan()}
                      className="ml-4"
                    >
                      {/* <DocumentPlusIcon className="h-6 w-6" /> */}
                      <span className="hidden xs:block ml-2">
                        View My Results{" "}
                      </span>
                    </Button>
                  )}

                {thisAssessment &&
                thisAssessment !== null &&
                thisAssessment !== undefined &&
                thisAssessment.assessment_status &&
                thisAssessment.assessment_status !== "Finished" ? (
                  <Button
                    rounded
                    size="sm"
                    color="indigo"
                    type="button"
                    handleClick={() => resumeAssessment()}

                    // className="inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-accent-600 hover:bg-accent-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                  >
                    <PencilIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                    Resume Assessment
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    size="sm"
                    handleClick={() => setAssessmentModalOpen(true)}
                    className="ml-4"
                  >
                    <DocumentPlusIcon className="h-6 w-6" />
                    <span className="hidden xs:block ml-2">
                      Take Assessment
                    </span>
                  </Button>
                )}
              </RightActionArea>
            )}
          </div>
        )}
        <div className="flex items-center space-x-12">
          <div className="relative w-1/4 inline-flex mr-4 ">
            <input
              id="search"
              name="search"
              type="search"
              autoComplete="search"
              placeholder={
                onlyShowKeyCompetencies
                  ? "Search by name (disabled)"
                  : descriptionSearchValue == ""
                  ? "Search by name"
                  : "Search by name (disabled)"
              }
              disabled={
                onlyShowKeyCompetencies
                  ? true
                  : descriptionSearchValue == ""
                  ? false
                  : true
              }
              value={searchValue}
              onChange={(e) => {
                handleSearchList(e, "Name");
              }}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-3 flex items-center">
              {searchValue && searchValue.length > 0 && (
                <XMarkIcon
                  className="cursor-pointer inline h-5 w-5 text-gray-400"
                  onClick={() => {
                    handleClearAllSelections("Name");
                  }}
                />
              )}
            </div>
          </div>
          <div className="relative w-1/4 inline-flex mr-4 ">
            <input
              id="search"
              name="search"
              type="search"
              autoComplete="search"
              placeholder={
                onlyShowKeyCompetencies
                  ? "Search by description (disabled)"
                  : searchValue == ""
                  ? "Search by description"
                  : "Search by description (disabled)"
              }
              disabled={
                onlyShowKeyCompetencies
                  ? true
                  : searchValue == ""
                  ? false
                  : true
              }
              value={descriptionSearchValue}
              onChange={(e) => {
                handleSearchList(e, "Description");
              }}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-3 flex items-center">
              {descriptionSearchValue && descriptionSearchValue.length > 0 && (
                <XMarkIcon
                  className="cursor-pointer inline h-5 w-5 text-gray-400"
                  onClick={() => {
                    handleClearAllSelections("Description");
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {(profile.sysRole === "Super Admin" ||
              profile.role === "Administrator") && (
              <>
                <Switch
                  id={"keyCompetencies"}
                  checked={onlyShowKeyCompetencies}
                  onChange={(e) => {
                    setOnlyShowKeyCompetencies(e);
                    //set local storage
                    sessionStorage.setItem("onlyShowKeyCompetencies", e);
                  }}
                  className={classNames(
                    onlyShowKeyCompetencies && onlyShowKeyCompetencies === true
                      ? "bg-accent-600"
                      : "bg-gray-200",
                    "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      onlyShowKeyCompetencies &&
                        onlyShowKeyCompetencies === true
                        ? "translate-x-3.5"
                        : "translate-x-0",
                      "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <label
                  htmlFor="showTestData"
                  className="flex items-center cursor-pointer mr-2 "
                >
                  Only show key competencies
                </label>
              </>
            )}
          </div>
        </div>
        {/* map areas and create div for each area */}
        {loading ? (
          <Skeleton type="spinner" fill="primary" />
        ) : (
          view !== "table" &&
          areas.map((area) => (
            <div className="bg-white shadow-lg  items-center border border-gray-200  justify-between p-4 space-y-8">
              <div className="flex items-center justify-between space-x-6">
                <div className="flex">
                  <h2 className="text-xl font-semibold text-slate-800">
                    {area}
                  </h2>
                </div>
                <div className="flex justify-between">
                  <Button
                    iconButton
                    handleClick={() => {
                      handleExpandCollapseClick(area);
                    }}
                  >
                    {expanded.includes(area) ? (
                      <ChevronUpIcon className="h-6 w-6" />
                    ) : (
                      <ChevronDownIcon className="h-6 w-6" />
                    )}
                  </Button>
                </div>
              </div>
              {competencies.map(
                (competency) =>
                  competency.Area === area && (
                    <>
                      {expanded.includes(area) && (
                        <div>
                          <SimpleCard
                            setImpedimentModalOpen={() =>
                              handleSetImpedimentModalOPen(competency)
                            }
                            setViewImpedimentModalOpen={() =>
                              handleSetViewImpedimentModalOpen(competency)
                            }
                            setSelectedCompetency={(comp) =>
                              setSelectedCompetency(comp)
                            }
                            handleChangeCompetencyLevel={
                              handleChangeCompetencyLevel
                            }
                            handleAddToMyPlan={handleAddToMyPlan}
                            data={competency}
                            view={view}
                            profile={profile}
                            competencyListOptions={competencyListOptions}
                          />
                        </div>
                      )}
                    </>
                  )
              )}
            </div>
          ))
        )}
      </div>
      <ModalBasic
        id="plan-modal"
        modalOpen={planModalOpen}
        setModalOpen={setPlanModalOpen}
        title="Select a plan"
        showClose={false}
        showFooter={true}
        closeButtonText="Close"
        handleConfirmClick={(e) => addToMyPlan(e)}
        // selectPlan={selectPlan}
        OKbuttonText="Select this plan"
        s
        CancelButton
        cleanUpState={cleanUpState}
        size={"sm"}
      >
        <div className="mx-auto px-24 py-8 h-64">
          {showSelector && (
            <PlanSelectCard
              setPlan={setSelectedPlan}
              plans={plans}
              // addToMyPlan={addToMyPlan}
              selectPlan={selectPlan}
            />
          )}
          {/* {!showSelector && <span>Date Picker Here</span>} */}
        </div>
      </ModalBasic>
      <ModalBasic
        id="assessment-modal"
        modalOpen={assessmentModalOpen}
        setModalOpen={setAssessmentModalOpen}
        title="Take Assessment"
        showClose={true}
        showFooter
        closeButtonText="Close"
        scrollable={false}
        size="lg"
      >
        <AssessmentSelect />
        {/* closeModal={setAssessmentModalOpen(false)} */}
      </ModalBasic>

      <ModalBasic
        id="add-impediment-modal"
        modalOpen={impedimentModalOpen}
        setModalOpen={setImpedimentModalOpen}
        title={
          selectedCompetency !== null
            ? "Impediments related to " +
              (selectedCompetency.Area + " - " + selectedCompetency.Name)
            : "Impediments"
        }
        showClose={true}
        showFooter
        closeButtonText="Close"
        scrollable={true}
        size="lg"
        OKbuttonText="Close this window"
        handleConfirmClick={() => setImpedimentModalOpen(false)}
      >
        <AssessmentTemplateQuestionEditor
          assessment_type={"impediment"}
          selectedCompetency={selectedCompetency !== null && selectedCompetency}
          questions={
            selectedCompetency !== null && selectedCompetency.impediments
              ? selectedCompetency.impediments
              : []
          }
          from={"competencies"}
          addQuestionRef={null} //{addQuestionRef}
          refreshQuestions={addImpedimentToCompetency} //{refreshQuestions}
          setQuestions={() => addImpedimentToCompetency()} //{setQuestions}
          modalItem={null}
          modalOpen={impedimentModalOpen}
          title={"Impediment "}
          description={"Add a new impediment below"}
          competencies={competencyListOptions}
        />
      </ModalBasic>
      <ModalBasic
        id="view-impediment-modal"
        modalOpen={viewImpedimentModalOpen}
        setModalOpen={setViewImpedimentModalOpen}
        title={
          "View related impediments to " +
          (selectedCompetency !== null && selectedCompetency.Name)
        }
        showClose={true}
        showFooter
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        <p className="p-6 text-slate-800 text-md ">
          Here you can see all your team's impediments related to this
          competency. Right now it's read only, but we're working on a few
          features to make this more useful.
        </p>
        {selectedImpediment && selectedImpediment.length > 0 && (
          <div className="mt-6 space-y-4">
            {selectedImpediment.map((impediment) => (
              // Card
              <div className=" mx-6 bg-white rounded-md shadow-lg text-slate-800  items-center border border-gray-200  justify-between p-4 space-y-8">
                <div className="flex items-center justify-between space-x-6">
                  <div>
                    <div className="flex font-medium">{impediment.Title}</div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex font-medium px-3">
                      {selectedCompetency.Area} {`>`} {selectedCompetency.Name}
                    </div>
                    <div className="font-medium px-3">
                      Burden:{" "}
                      {impediment.score && impediment.score > 0
                        ? impediment.score
                        : "n/a"}
                    </div>
                    <div className="flex font-medium  px-3">
                      Effort:{" "}
                      {impediment.effort && impediment.effort > 0
                        ? impediment.effort
                        : "n/a"}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  {" "}
                  {impediment.Description}
                </div>
              </div>
            ))}
          </div>
        )}
      </ModalBasic>
    </>
  );
}

export default Competencies;
