import React from "react";
export default function StepsBullets(props) {
  const [steps, setSteps] = React.useState(props.steps);

  React.useEffect(() => {
    setSteps(props.steps);
  }, [props.steps]);
  return (
    <nav className="flex items-center justify-left" aria-label="Progress">
      <p className="text-sm font-medium">
        Level {steps.findIndex((step) => step.status === "current") + 1} of{" "}
        {steps.length}
      </p>
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === "complete" ? (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-accent-600 hover:bg-accent-800"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-accent-200" />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-accent-600"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
