// store.js

import React, { createContext, useEffect, useState } from "react";
import { utils } from "../modules/_utils";

export const Context = createContext(null);

export const Store = ({ children }) => {
  const [state, setState] = useState({});
  const [cache, setCache] = useState();

  useEffect(() => {
    (async () => {
      let cache = await utils.getCacheData("flags", "flags");

      if (cache) {
        setCache(cache);
      }
      setState({ flags: cache.data });
    })();
  }, []);

  return (
    <Context.Provider value={state}>
      {/* <- this value is gonna be Global state */}
      {children}
    </Context.Provider>
  );
};
