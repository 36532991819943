import React, { useEffect, useState } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import Badge from "./Badge";
import Skeleton from "./Skeleton";
import { tailwindConfig } from "../utils/Utils";

const areas = [
  "Culture",
  "Plan",
  "Code",
  "Build",
  "Test",
  "Release",
  "Deploy",
  "Operate",
  "Monitor",
  "Respond",
];

// const stats = [
//   {
//     name: "Culture",
//     stat: "Beginner",
//     score: "1.12", //competency_score
//     burden: "3", //impediment score
//     effort: "2", //imediment effort
//   },

//   {
//     name: "Code",
//     stat: "Intermediate",
//     score: "1.12",
//     burden: "3",
//     effort: "2",
//   },
//   {
//     name: "Build",
//     stat: "Beginner",
//     score: "1.12",
//     burden: "3",
//     effort: "2",
//   },
//   {
//     name: "Test",
//     stat: "Advanced",
//     score: "1.12",
//     burden: "3",
//     effort: "2",
//   },
//   {
//     name: "Deploy",
//     stat: "Epert",
//     score: "1.12",
//     burden: "3",
//     effort: "2",
//   },
//   {
//     name: "Monitor",
//     stat: "Intermediate",
//     score: "1.12",
//     burden: "3",
//     effort: "2",
//   },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Stats(props) {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState();
  const [data, setData] = useState();
  // const data = props.data;
  let open = props.open ? props.open : true;
  let initialData = loading ? loading : data;
  const shadow = props.shadow ? props.shadow : true;
  let areas = [];
  const showModelCoverage = props.showModelCoverage && props.showModelCoverage;
  const process = props.process ? props.process : true;

  // useEffect(() => {
  //   if (props.open === false) {
  //     setStats(null);

  //   }
  // }, [props.open]);

  useEffect(() => {}, [stats]);

  useEffect(() => {
    setStats([]);
    let thisData = props.data;

    if (props.process === true) {
      if (
        thisData !== "loading" &&
        thisData !== undefined &&
        thisData !== null &&
        thisData.length > 0 &&
        Array.isArray(thisData)
      ) {
        //
        setData(data);
        processData(thisData);
        setLoading(false);
      } else {
        processData([]);
      }
    } else {
      setStats(thisData);
      setLoading(false);
    }
  }, [props.data]);

  const processData = (mydata) => {
    mydata.forEach((item) => {
      if (!areas.includes(item.Area)) {
        areas.push(item.Area);
      }
    });

    areas.forEach((area) => {
      //
      let areaObj = {};
      let score = 0;
      let burden = 0;
      let effort = 0;
      let comp_score = 0;
      let counter = 0;

      mydata.forEach((item) => {
        if (item.Area === area) {
          comp_score += item.competency_score ? item.competency_score : 0;
          burden += item.score ? item.score : 0;
          effort += item.effort ? item.effort : 0;
          counter++;
        }
      });
      let calScore = comp_score / counter;
      areaObj.name = area;
      areaObj.score = calScore.toFixed(2);
      areaObj.burden = burden;
      areaObj.effort = effort;
      areaObj.stat = getLevelName(calScore);

      setStats((stats) => [...stats, areaObj]);
    });
  };

  const getLevelName = (score) => {
    if (score < 2) {
      return "Beginner";
    } else if (score < 3) {
      return "Intermediate";
    } else if (score < 4) {
      return "Advanced";
    } else if (score < 5) {
      return "Expert";
    }
    return "What?";
  };

  const getLevelColor = (score) => {
    if (score < 2) {
      //beginner
      return "text-red-700";
    } else if (score < 3) {
      //intermediate
      return "text-orange-500";
    } else if (score < 4) {
      //advanced
      return "text-amber-500 ";
    } else if (score < 5) {
      //expert
      return "text-emerald-500";
    } else {
      //default
      return "text-slate-600";
    }
  };
  const devOpsAreas = [
    "Culture",
    "Plan",
    "Code",
    "Build",
    "Test",
    "Release",
    "Deploy",
    "Operate",
    "Monitor",
    "Respond",
  ];
  return (
    <div className="col-span-12 bg-white rounded-sm">
      <header className="flex grid grid-flow-col align-middle px-5 py-4 border-b border-gray-100">
        <div className="inline-flex w-fit align-middle  text-lg font-semibold text-slate-800 2 ml-0">
          {props.title}
        </div>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <dl className="mt-2 grid grid-cols-5 md:grid-cols-10 divide-y  divide-gray-200 overflow-hidden  md:divide-y-0 md:divide-x">
          {showModelCoverage == true && (
            <>
              <div className="col-start-2 col-span-8 divide-none bg-cyan-100 text-cyan-700 items-center text-center text-sm font-medium rounded-tr-full rounded-tl-full">
                DevOps Model Coverage
              </div>
              <div className="col-span-10 h-0.5 divide-none "> </div>
            </>
          )}
          {devOpsAreas.map((area) => {
            let item = stats && stats.find((item) => item.name === area);
            return (
              <div className="flex items-center justify-center py-2">
                <header className="p-0">
                  <h3 className="text-sm font-semibold text-slate-500 uppercase mb-1">
                    {item ? item.name : area}
                  </h3>
                  <div className="text-2xl font-bold text-slate-800 mb-1">
                    {item ? item.score : "N/A"}
                  </div>
                  <div className="text-sm">
                    <span
                      className={`font-medium ${getLevelColor(
                        item ? item.score : 10
                      )}`}
                    >
                      {" "}
                      {item ? item.stat : "N/A"}
                    </span>
                  </div>
                </header>
              </div>
            );
          })}
        </dl>
      )}
    </div>
  );
}
