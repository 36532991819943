import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ProfileSidebar from "../components/ProfileSidebar";
import Button from "../components/Button";
import ProfileBody from "../components/ProfileBody";
import ImpedimentDetailsBody from "../components/ImpedimentDetailsBody";

function ImpedimentDetails(...props) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);
  const [data, setData] = useState(location.state.data);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [list, setList] = useState(location.state.list);
  const history = useHistory();
  const fullList = location.state.list;

  useEffect(() => {}, [data]);

  const handleBack = () => {
    let path = "/impediments";
    return history.push({
      pathname: path,
      state: { list: fullList },
    });
  };

  const handleSetData = (data) => {
    setData(data);
  };

  const handleSetList = (list) => {
    //turn list into array using list.decription

    setList(list);
  };

  //
  return <ImpedimentDetailsBody data={data} handleBack={handleBack} />;
}

export default withRouter(ImpedimentDetails);
