// import _authService from "../modules/authService";
// import { SESSION_TOKEN } from "../constants/constants";
import jwtDecode from "jwt-decode";
import setAuthToken from "../config/setAuthToken";
import { _assessment } from "../modules/_assessment";
import { _auth } from "../modules/_auth";
import { utils } from "../modules/_utils";

const createLoginSlice = (set) => ({
  status: "",
  isSuccess: false,
  isAuthenticated: false,
  isMfaSuccess: false,
  profile: {},
  error: "",
  loginError: false,
  loader: false,
  guestList: [],
  tenants: [],

  token: "",
  guestProfile: {},
  signupAsync: async (userData) => {
    const response = await _auth.signup(userData);

    if (response.error.length === 0) {
      set(() => ({
        isSuccess: true,
        LoginUserData: response,
        errorMessage: "",
      }));
    } else {
      set(() => ({
        isSuccess: false,
        errorMessage: response.error,
      }));
    }
  },
  setTenantsId: async (id, profile, tenants) => {
    set(() => ({
      // selectedTenantId: id,
      profile: profile,
      tenants: tenants,
      tenantId: id,
    }));
  },
  getAuthAsync: async (history) => {
    let response = await _auth.getAuth(history);
    if (response && response.response && response.response.status === 401) {
      localStorage.removeItem("jwtToken");

      localStorage.removeItem("profile");
      set(() => ({
        status: "",
        isSuccess: false,
        isAuthenticated: false,
        // profile: {},
        error: "",
        loader: false,
        guestList: [],
        // tenants: [],
        // tenantId: "",
        // selectedTenantId: "",
        token: "",
        guestProfile: {},
      }));
      return false;
    } else {
      return true;
    }
  },
  forgotPasswordAsync: async (u, history) => {
    try {
      let obj = {
        email: u,
      };
      let response = await _auth.forgotPassword(obj);

      if (response) {
        localStorage.setItem("email", u);
        // Set token to ls
        history.push("/reset-password");
        return true;
      } else {
        return false;
      }
    } catch (e) {
      set(() => ({
        loginError: true,
      }));
    }
  },
  resetPasswordAsync: async (otp, password, confirmpassword, history) => {
    try {
      let email = await localStorage.getItem("email");
      let data = {
        otp: otp,
        email: email,
        password: password,
        confirmpassword: confirmpassword,
      };
      let response = await _auth.resetPassword(data);
      if (response) {
        // Set token to ls
        history.push("/");
        return true;
      } else {
        return false;
      }
    } catch (e) {
      set(() => ({
        loginError: true,
      }));
    }
  },
  resetPasswordEmailAsync: async (
    id,
    token,
    password,
    confirmpassword,
    history
  ) => {
    let email = await localStorage.getItem("email");
    let data = {
      id: id,
      token: token,
      email: email,
      password: password,
      confirmpassword: confirmpassword,
    };
    let response = await _auth.resetPasswordEmail(data);
    if (response && response.data.status === 200) {
      // Set token to ls
      this.loader = false;
      history.push({
        path: "/",
      });
      return true;
    } else {
      return false;
    }
  },
  addUserAsync: async () => {},
  getAllUser: async (history) => {},
  loginAsync: async (userData, history) => {
    if (userData.email !== "") {
      ////debugger;
      const response = await _auth.login(userData);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === 200
      ) {
        ////debugger;//
        const { token } = response.data.data;
        // Set token to ls
        utils.setLocalStorage("jwtToken", token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        // //debugger;
        const decoded = jwtDecode(token);

        //
        localStorage.removeItem("guestProfile");
        set(() => ({
          isSuccess: true,
          token: token,
          isAuthenticated: true,
          profile: decoded,
          tenants: decoded.tenants,
          // tenantId: decoded.tenantId,
          error: "",
        }));
        return decoded;
      } else if (
        response &&
        response.response.status &&
        response.response.status === 400
      ) {
        ////debugger;
        set(() => ({
          isSuccess: false,
          isAuthenticated: false,
          error: response.response.data.errorMessage,
        }));
        return false;
      } else {
        set(() => ({
          isSuccess: false,
          isAuthenticated: false,
          error: "Username or Password is incorrect",
        }));
        return false;
      }
      // if (response.error.length === 0) {
      //   set(() => ({
      //     isSuccess: true,
      //     isAuthenticated: true,
      //     LoginUserData: response.user,
      //     error: "",
      //   }));
      //   history({
      //     pathname: "/home",
      //   });
      // } else {
      //   set(() => ({
      //     isSuccess: false,
      //     isAuthenticated: false,
      //     errorMessage: response.error,
      //   }));
      // }
    } else {
      set(() => ({
        isSuccess: false,
        isAuthenticated: false,
        error: "Please enter the details",
      }));
    }
  },

  mfa: async (otp, history) => {
    let response = null;
    set(() => ({
      isSuccess: false,
    }));
    if (!otp) {
      set(() => ({
        isSuccess: false,
        isAuthenticated: false,
        error: "Please enter the correct OTP",
      }));
    } else {
      response = await _auth.MFA(otp);
      if (response) {
        set(() => ({
          isSuccess: true,
          isAuthenticated: true,
          isMfaSuccess: true,
        }));
      } else {
        set(() => ({
          isSuccess: false,
          isAuthenticated: false,
          isMfaSuccess: false,
          error: "Something went wrong",
        }));
      }
    }
    return response;
  },
  getGuestList: async () => {
    try {
      // //debugger;
      const response = await _assessment.getGuestList();
      //      debugger;
      if (response) {
        // debugger;

        set(() => ({
          guestList: [...response],
        }));
      } else {
        set(() => ({
          guestList: [],
        }));
      }
    } catch (e) {
      set(() => ({
        error: "Something went wrong, Please try again in sometime",
      }));
    }
  },
  verifyGuestLogin: async (
    userId,
    token,
    name,
    email,
    tenantId,
    type,
    inviteId,
    assessment_name,
    history
  ) => {
    try {
      debugger;
      let response = await _auth.verifyGuest(
        userId,
        token,
        name,
        email,
        inviteId
      );
      debugger;
      if (response.data.status === 200) {
        //debugger;

        localStorage.setItem(
          "guestProfile",
          JSON.stringify({
            userId,
            name,
            email,
            tenantId,
            type,
            inviteId,
            assessment_name,
          })
        );
        set(() => ({
          guestProfile: {
            userId,
            name,
            email,
            tenantId,
            type,
            inviteId,
            assessment_name,
          },
        }));
        //debugger;
        let paramsState;
        if (type !== "pre") {
          paramsState = {
            isPreassessment: false,
            assessment_type: type,
            assessment_name: assessment_name,
            resume: false,
            // new_history_item: _assessment.getJsonTemplate(),
          };
        } else {
          paramsState = {
            isPreassessment: true,
            assessment_type: "pre",
            assessment_name: assessment_name,
            resume: false,
          };
        }
        //debugger;
        history.push({
          pathname: "/assessment",
          state: paramsState,
        });
        //debugger;
      } else {
        //debugger;
      }
    } catch (err) {
      set(() => ({
        error: err,
      }));
    }
  },
  logout: () => {
    set(() => ({
      isSuccess: false,
      token: null,
      LoginUserData: null,
      selectedTenantId: "",
      tenantId: "",
      profile: {},
      guestProfile: {},
      loginError: "",
      error: "",
    }));
    //localStorage[SESSION_TOKEN] = "";
    // _authService.logout();
  },
});

export default createLoginSlice;
