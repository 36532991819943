import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const _policies = {
  async getAllPolicies() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
    };

    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/policy-tracker/get", data);

      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
};
