import React from "react";
import { useState, useEffect } from "react";

import Badge from "./Badge";
import Button from "./Button";
import { Icon } from "@iconify/react";
import Skeleton from "./Skeleton";
import MenuDropdown from "./Menu";
import Tooltip from "./Tooltip";

const projects = [
  {
    name: "Graph API",
    initials: "GA",
    href: "#",
    members: 16,
    bgColor: "bg-pink-600",
  },
  {
    name: "Component Design",
    initials: "CD",
    href: "#",
    members: 12,
    bgColor: "bg-purple-600",
  },
  {
    name: "Templates",
    initials: "T",
    href: "#",
    members: 16,
    bgColor: "bg-yellow-500",
  },
  {
    name: "React Components",
    initials: "RC",
    href: "#",
    members: 8,
    bgColor: "bg-green-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleCard(props) {
  const [view, setView] = useState();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const data = props.data;
  const impediments = data.impediments ? data.impediments : [];
  const competencyListOptions = props.competencyListOptions;
  const name = props.data.Name ? props.data.Name : "No Name";
  const description = props.data.Description;
  const levels = props.data.Levels
    ? props.data.Levels
    : props.data.answer_options;
  const valueDrivers = props.data["Value Drivers"];

  const setImpedimentModalOpen = () => {
    props.setImpedimentModalOpen(true, data);
  };

  const setViewImpedimentModalOpen = (impediments) => {
    props.setViewImpedimentModalOpen(true, impediments);
  };

  useEffect(() => {
    setView(props.view);
  }, [props.view]);

  const getBgColor = (level) => {
    switch (level) {
      case 0:
        return "bg-beginner";
      case 1:
        return "bg-intermediate";
      case 2:
        return "bg-advanced";
      case 3:
        return "bg-expert";
      default:
        return "bg-blue-500";
    }
  };

  const getLevelName = (level) => {
    switch (level) {
      case 0:
        return "Beginner";
      case 1:
        return "Intermediate";
      case 2:
        return "Advanced";
      case 3:
        return "Expert";
      default:
        return "Not Scored";
    }
  };

  const getLevelIndex = (score, index) => {
    let originalScore = score;
    score = score - 1;
    let level;
    let levelName = "";
    // 0 to 1.9 = Beginner
    // 2 to 2.9 = Intermediate
    // 3 to 3.9 = Advanced
    // 4 = Expert
    if (score >= 0 && score < 2) {
      level = 0;
    } else if (score >= 2 && score < 3) {
      level = 1;
    } else if (score >= 3 && score < 4) {
      level = 2;
    } else if (score >= 4) {
      level = 3;
    } else {
      level = 0;
    }
    levelName = getLevelName(level);
    if (level == index) {
      return true;
    } else {
      return false;
    }
  };

  const getProjectInitials = (name) => {
    const words = name.split(" ");
    if (words.length > 1) {
      return words[0][0] + words[1][0];
    }
    return words[0][0];
  };

  const handleChangeCompetencyLevel = (data, new_answer) => {
    setLoading(true);
    props.handleChangeCompetencyLevel(data, new_answer);
    setLoading(false);
  };

  const handleAddToMyPlan = (a, b, c) => {
    setLoading(true);
    props.handleAddToMyPlan(a, b, c);
    setLoading(false);
  };

  return (
    // <div>test</div>
    <div className="shadow-md rounded-md border-t border-r border-l border-b border-gray-200  p-5">
      <div className=" w-full max-w-9xl mx-auto">
        <div className="flex flex-col md:flex-row md:justify-between sm:items-center ">
          <div className="flex flex-col md:flex-col lg:flex-row items-center">
            <h2 className="flex  font-semibold text-slate-800">
              {props.data.key}
              {/* add space space */}
              &nbsp;&nbsp;
            </h2>
            <h2 className="flex  font-semibold text-slate-800">{name}</h2>
            <h2 className="flex rounded border border-cyan-700 bg-cyan-500 bg-opacity-20 px-2 mx-4 uppercase text-xs font-medium text-cyan-700">
              {props.data.Area}{" "}
              {props.data.Subarea && props.data.Subarea !== ""
                ? " - " + props.data.Subarea
                : ""}
            </h2>
            <div className=" text-base font-medium text-slate-800 grow-0 shrink-0 ">
              {props.data.hover_question && (
                <Tooltip
                  size="xl"
                  bg="dark"
                  position={"left"}
                  title={props.data.hover_question}
                  description={props.data.hover_answer}
                >
                  <div className="block ml-3 text-xs text-accent-700 underline">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline mr-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    {props.data.hover_question}
                  </div>
                </Tooltip>
              )}
            </div>
          </div>

          {/* Right: Actions */}
          <div className="flex flex-col md:grid md:grid-flow-col md:auto-cols-max justify-start sm:justify-end gap-2">
            <span
              className="cursor-pointer"
              onClick={() => setViewImpedimentModalOpen(impediments)}
            >
              <Badge color="primary">
                {impediments.length + " Impediments"}
              </Badge>
            </span>

            <Badge color="red">
              Effort: {data.effort !== undefined ? data.effort : 0} days
            </Badge>
            <Badge color="secondary">
              Burden: {data.score !== undefined ? data.score : 0} hours/wk
            </Badge>
            {/* Removed Aug 16. Put back after */}
            <Button
              color="accent"
              size="xs"
              shadow
              className="ml-6 "
              handleClick={() => setImpedimentModalOpen()}
            >
              Add Impediment
            </Button>
          </div>
        </div>
      </div>

      <span className="flex-1 flex text-sm  border-gray-200 bg-white rounded-r-md truncate">
        {data.Description ? data.Description : "No Description"}
      </span>
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-1 sm:gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4"
      >
        {levels.map((project, index) => {
          // let levelIndex = getLevelIndex(data.score, index);
          return (
            <li
              key={project.Name}
              className={`col-span-1 flex rounded-md shadow-sm
            }}`}
            >
              <div
                className={classNames(
                  // getBgColor(project.Name),
                  props.profile.tenantInfo.type !== "admin"
                    ? project == data.your_answer
                      ? "bg-yellow-400"
                      : getBgColor(index)
                    : getLevelIndex(data.score, index) == true
                    ? "bg-yellow-400"
                    : getBgColor(index),
                  // project.Selected ? "bg-indigo-600" : getBgColor(index),
                  "transition-colors ease-in ease-out delay-100ms duration-750ms flex-shrink-0 flex items-center justify-center w-16 text-white text-2xl font-medium rounded-l-md"
                )}
              >
                {/* get initials from name */}
                {getProjectInitials(
                  project.Name ? project.Name : getLevelName(index)
                )}
              </div>

              <div
                className={`shadow flex ${view == "grid" && "h-32"} ${
                  view == "list" && "h-16"
                }
               ${view == "list" && "truncate"}
              flex-1 items-center justify-between text-xs rounded-r-md border-t border-r border-b  ${
                // project == data.your_answer
                props.profile.tenantInfo.type !== "admin"
                  ? project == data.your_answer
                    ? " bg-yellow-100 border-yellow-500"
                    : "bg-white border-gray-200"
                  : getLevelIndex(data.score, index) == true
                  ? " bg-yellow-100 border-yellow-500"
                  : "bg-white border-gray-200"
              }`}
              >
                <div
                  className={`flex-1   ${
                    view == "list" && "truncate"
                  }  px-4 py-2 text-xs`}
                >
                  <a
                    href={project.href}
                    className="font-medium text-slate-900 hover:text-slate-600"
                  >
                    {/* {view == "grid" && project.Description && project.Description}
                  {view == "list" && project.Description && project.Name} */}
                    {view == "grid" && project}
                    {view == "list" && project}
                    {/* {view == "list" && project.Name && project.Name} */}
                  </a>

                  {/* <p className="text-slate-500">
                  {impediments.length} Impediments
                </p> */}
                </div>
                <div className="flex-shrink-0 pr-2">
                  <MenuDropdown
                    data={data}
                    project={project}
                    profile={props.profile}
                    handleAddToMyPlan={(a, b, c) => {
                      handleAddToMyPlan(a, b, c);
                    }}
                    handleChangeCompetencyLevel={handleChangeCompetencyLevel}
                  />
                </div>
                {/* <div>
                {data.your_answer &&
                  project !== data.your_answer &&
                  data.your_answer !== "" && (
                    <Button
                      iconButton
                      // type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full  bg-transparent text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                      value={data}
                      handleClick={() =>
                        handleChangeCompetencyLevel(data, project)
                      }
                    >
                      {loading ? (
                        <Skeleton type="spinner" />
                      ) : (
                        <Icon
                          icon="material-symbols:change-circle-outline-rounded"
                          className="h-5 w-5 fill-current"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  )}
              </div> */}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
