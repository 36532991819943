import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Button from "./Button";
import Skeleton from "./Skeleton";
import MultiSelectListBox1 from "./MultiSelectListBox1";
import { _auth } from "../modules/_auth";
import { _user } from "../modules/_user";

export default function DataEntryDialog(props) {
  const teamList = props.data;
  const [open, setOpen] = useState(props.open);
  const [input, setInput] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedMailingLists, setSelectedMailingLists] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [currentStep, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [company, setCompany] = useState("");
  const [tenant, setTenant] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState(_auth.getUserProfile());
  const [buttonText, setButtonText] = useState("Create Team");
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  //   useEffect(() => {

  //     setSuccess(props.success);
  //   }, [props.success]);

  useEffect(() => {}, [selected]);

  const handleSelect = async (val) => {
    let tenantName = val["Team Name"];

    setInput(tenantName);
    setSelected(val);

    // setLoader(true);
    // setButtonText("Creating Team...");
    // let updatedTenant = await _user.renameTenant(tenantName);
    // if (updatedTenant) {
    //

    //   //set tenant name in profile
    //   let p = profile;
    //   let tenantId = updatedTenant.data.data._id;
    //   p.tenantName = tenantName;

    //   //find tenant in profile and update
    //   //tenants[0].tenantId._id = tenantId;

    //   let tenantIndex = profile.tenants.findIndex(
    //     (t) => t.tenantId._id === tenantId
    //   );

    //   profile.tenants[tenantIndex].tenantId.tenantTitle = val["Team Name"];

    //   profile.tenantInfo.tenantTitle = val["Team Name"];

    //   _auth.setUserProfile(profile);
    //   setTimeout(() => {
    //     setLoader(false);
    //     setButtonText("Done");
    //   }, 1000);
    // } else {
    //   setButtonText("Close");
    //   setLoader(false);
    //   setError(true);
    //   setErrorMessage(
    //     "An error occurred while creating your team. You can still work, but you'll need to create a team later."
    //   );
    //
    // }
  };

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
    setTimeout(() => {
      setError(false);
      setErrorMessage("");
      setLoading(false);
      setInput("");
      setSuccess(false);
    }, 500);
  };
  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (input.length === 0) {
      setError(true);
      setErrorMessage("Please enter a value");
      setLoading(false);
    } else {
      const submitted = await props.handleOnSubmit(selected); //input

      setError(false);
      setErrorMessage("");
      setInput("");
      setSuccess(true);
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {success && (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  {loading && !success && !error && (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <Skeleton type="spinner" />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-slate-900"
                    >
                      {success
                        ? "Team created successfully"
                        : "Create a new team"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-slate-500">
                        {error && (
                          <span className={"text-red-500"}>
                            {errorMessage}{" "}
                          </span>
                        )}
                        {success
                          ? "Your team has been created. You may need to refresh to see it."
                          : error
                          ? ""
                          : "Select your team's name below to create it."}
                      </p>
                    </div>
                  </div>
                </div>
                {!success && !loading && (
                  <div className="mt-2 inline-flex w-full justify-center rounded-md ">
                    {/* <input
                      className="rounded-md items-center"
                      type="text"
                      value={input}
                      onChange={handleChange}
                    /> */}
                    <MultiSelectListBox1
                      key={1}
                      width="w-full"
                      options={teamList}
                      fieldType="single"
                      valueField="Key"
                      displayField="Team Name"
                      label="Team Names"
                      name="teamlist"
                      impedimentId={null}
                      // handleSelect={null}
                      handleDeselect={null}
                      handleSelect={handleSelect}
                      value={tenant}
                    />
                  </div>
                )}
                <div className="mt-5 sm:mt-6">
                  {success ? (
                    <Button
                      color="secondary"
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border 
                      border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      handleClick={() => handleClose(false)}
                    >
                      {success ? "I'm done. Close this dialog." : "Create Team"}
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      handleClick={(e) => handleSubmit(e)}
                    >
                      {success ? "I'm done. Close this dialog." : "Create Team"}
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
