import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";

import RiskLogo from "../images/risk-logo.png";
import Logo from "../images/logo.jpg";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../components/Button";
import { usePersistStore } from "../store/usestore";
import { _stripe } from "../modules/_stripe";
import { features } from "../modules/_features";
// import HeroImage from "../images/hero-cover1.jpg";
// import HeroImage from "../images/hero-blue-van.jpg";
import NotificationBanner from "../components/NotificationBanner";
import FourColumnStats from "./spa/parts/FourColumnStats";
import HeroImage from "../images/road-map.jpg";

function Signin() {
  const mvp = true;
  const history = useHistory();
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const { isAuthenticated, isSuccess, profile, error, loginAsync, token } =
    usePersistStore();
  const [loginMessage, setLoginMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const allowedRoles = ["Manager", "Administrator", "Super Admin"];

  useEffect(() => {
    (async () => {
      const annce = await features.getSystemIssues();

      if (annce) {
        setAnnouncements(annce);
      }
    })();
  }, []);

  const auth = async (event) => {
    setLoader(!loader);

    event.preventDefault();

    var u = event.target.elements.email.value;
    var p = event.target.elements.password.value;

    let userData = {
      email: u,
      password: p,
    };
    let path = "/onboarding";

    let response = await loginAsync(userData);

    // debugger;s
    if (response) {
      setLoader(false);
      _auth.setUserProfile(response);

      if (response.MFA == true) {
        //if the user has MFA enabled, go to MFA page, regardless how many tenants they have

        path = "/mfa";
        history.push({
          state: { profile: response },
          pathname: path,
        });
      } else {
        //if the user has MFA disabled, check if they have more than one tenant
        let tenantCount = response.tenantCount;
        let role = response.companyRole;

        if (
          tenantCount > 1
          // ||
          // role in
          //   ["Admin", "Administrator", "SuperAdmin", "Super Administrator"]
        ) {
          //if they have more than one tenant, go to tenant selection page
          path = "/select-tenant";
          history.push({
            state: { profile: response },
            pathname: path,
          });
        } else {
          //update profile with tenant information if they only have one tenant
          response.tenantId = response.tenants[0].tenantId._id;
          response.tenantName = response.tenants[0].tenantId.tenantTitle;
          let isRenamed = response.tenants[0].tenantId.isRenamed;
          response.tenantRole = response.tenants[0].role;
          response.role =
            response.companyRole &&
            (response.companyRole == "Administrator" ||
              response.companyRole == "Observer")
              ? response.companyRole
              : response.tenants[0].role;

          _auth.setUserProfile(response);
          //if they have one tenant, get their stripe information
          // if (process.env.REACT_APP_NODE_ENV !== "development") {
          //   await _stripe.getStripeData(); //get stripe data and set it in the cache. this also creates websocket connection
          // }
          if (
            isRenamed == true ||
            response.role == "Collaborator" ||
            response.role == "Observer"
            //for now, just check isRenamed. We will add more checks later.
            // ||
            // response.dnd == true ||
            // response.onBoardCompleted == true
          ) {
            //check if we need to show onboarding page

            path = "/home";
            history.push({
              state: { profile: response },
              pathname: path,
            });
          } else {
            history.push({
              state: { profile: response },
              pathname: "/onboarding",
            });
          }
        }
      }
    } else {
      setLoader(false);
    }
  };

  return (
    <main className="relative lg:h-screen w-full isolate overflow-y-auto lg:overflow-hidden bg-slate-900">
      {/* <div className="relative isolate overflow-hidden pt-14 bg-slate-900"> */}

      {/* map announcements array */}
      <div className="space-y-2">
        {announcements &&
          announcements.length > 0 &&
          announcements.map((item, index) => {
            if (item.enabled == true) {
              return (
                <NotificationBanner
                  setOpen={null}
                  showClose={false}
                  text={item.description}
                />
              );
            }
          })}
      </div>
      <img
        src={HeroImage}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover opacity-20 "
      />
      {/* {maintenance && maintenance == true && (
        <NotificationBanner
          // className={"sticky top-64px"}
          setOpen={null}
          showClose={false}
          text={
            "We're making some changes that might disturb you. Feel free to login but bear with us a little longer."
          }
        />
      )} */}

      <div className="w-screen h-screen flex flex-col lg:flex-row  items-center justify-between">
        {/* <div className="w-screen  lg:w-1/2 relative flex flex-col grow-0 justify-center"> */}
        <div className="m-auto flex flex-col justify-center content-center">
          <div className="bg-primary-700 bg-opacity-70 p-6 rounded-lg shadow shadow-md max-w-sm mx-auto px-4 ">
            <div className="flex max-w-sm mx-auto px-4">
              <img width="80" className="mx-auto px-4 py-8" src={Logo} />
              <span className="inline-flex text-3xl m-auto font-bold text-white">
                JDO Connect{" "}
              </span>
            </div>
            <form onSubmit={auth}>
              <div className="space-y-4">
                <div>
                  <label
                    className="text-white block text-sm font-medium mb-1 "
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    id="email"
                    className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-orange-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-secondary-500 focus:ring-1 focus:ring-secondary-500"
                    type="email"
                  />
                </div>
                <div>
                  <label
                    className="text-white block text-sm font-medium mb-1"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    // className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    className=" rounded-md w-full
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-orange-500
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-secondary-500 focus:ring-1 focus:ring-secondary-500"
                    type="password"
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className=" items-center justify-between mt-6">
                {/* {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : ( */}
                <Button
                  enabled={!loader}
                  loader={loader}
                  type="submit"
                  // rounded
                  // disabled
                  fullWidth
                  color="secondary"
                  size="md"
                  // handleClick={null}
                >
                  Sign in
                </Button>
                <div className="mr-1 mt-5">
                  <Link
                    className="text-sm text-white underline hover:text-accent-700"
                    to="/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              {error && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  {error}
                </div>
              )}
            </form>

            {/* Footer */}
            {isAuthenticated === false && (
              <div className="flex grow-0 items-center justify-center mt-6  text-red-500 text-sm">
                {/* Username or password is incorrect. */}
                {loginMessage}
              </div>
            )}
            {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-accent-500 hover:text-accent-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
          </div>
        </div>
        {/* </div> */}
        {/* <div className="h-screen w-full lg:w-1/2 justify-start  h-full">
          <FourColumnStats />
        </div> */}
      </div>
    </main>
  );
}

export default Signin;
