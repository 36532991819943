import React from "react";
import LineChart from "../../charts/LineChart03";
import RiskOverTimeChart from "../../charts/RiskOverTimeChart";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
import Tooltip from "../../components/Tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import QuestionToolTip from "../../components/QuestionToolTip";
import { Icon } from "@iconify/react";
function AnalyticsCard01(props) {
  const data = props.data;
  const loading = props.loading;
  const title = props.title;
  const score = props.score;
  const burden = props.burden;
  const effort = props.effort;
  const breakEven = props.breakEven;
  const burdenSummary = props.burdenSummary;
  const resolved = props.resolved;
  const remaining = props.remaining;
  const impediments = props.impediments;

  const chartData = {
    labels: props.labels,
    datasets: [
      // Indigo line
      {
        label: "Current",
        data: props.values,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(
          tailwindConfig().theme.colors.accent[300]
        )}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.accent[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.accent[700],
      },
      // Gray line
      // {
      //   label: "Previous",
      //   data: [
      //     8000, 5000, 6500, 5000, 6500, 12000, 8000, 9000, 8000, 8000, 12500,
      //     10000, 10000, 12000, 11000, 16000, 12000, 10000, 10000, 14000, 9000,
      //     10000, 15000, 12500, 14000, 11000,
      //   ],
      //   borderColor: tailwindConfig().theme.colors.gray[300],
      //   fill: false,
      //   borderWidth: 2,
      //   tension: 0,
      //   pointRadius: 0,
      //   pointHoverRadius: 3,
      //   pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
      // },
    ],
  };

  return (
    <div className="flex flex-col col-span-full lg:col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
      </header>
      <div className="px-4 py-1 ">
        <div className="flex whitespace-nowrap  shrink-1 justify-between items-center divide-x divide-x-1">
          {/* Impediments */}
          <div className="flex items-center py-2 px-2">
            <div className="mr-5">
              <div className=" text-sm font-semibold text-slate-500 uppercase mb-1">
                {"Impediments"}
              </div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-slate-800 mr-2">
                  {impediments}
                </div>
                {/* <div className="text-sm font-medium text-green-500">+49%</div> */}
              </div>
              <div className="text-sm text-slate-500">total</div>
            </div>
          </div>
          {/* Burden */}
          <div className="flex items-center py-2 px-2">
            <div className="mr-5">
              <div className=" text-sm font-semibold text-slate-500 uppercase mb-1">
                {"Burden"}
              </div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-slate-800 mr-2">
                  {score}
                </div>
                {/* <div className="text-sm font-medium text-green-500">+49%</div> */}
              </div>
              <div className="text-sm text-slate-500">hours/wk</div>
            </div>
          </div>
          {/* Resolved */}
          <div className="flex items-center py-2 px-2">
            <div className="mr-5">
              <div className=" text-sm font-semibold text-slate-500 uppercase mb-1">
                {"Burden Resolved"}
              </div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-slate-800 mr-2">
                  {resolved}
                </div>
                {/* <div className="text-sm font-medium text-green-500">+7%</div> */}
              </div>
              <div className="text-sm text-slate-500">hours/wk</div>
            </div>
          </div>
          {/* Remaining */}
          <div className="flex items-center py-2 px-2">
            <div>
              <div className=" text-sm font-semibold text-slate-500 uppercase mb-1">
                {"Burden Remaining"}
              </div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-slate-800 mr-2">
                  {remaining}
                </div>
                {/* <div className="text-sm font-medium text-yellow-500">+7%</div> */}
              </div>
              <div className="text-sm text-slate-500">hours/wk</div>
            </div>
          </div>
          {/* Effort */}
          <div className="flex items-center py-2 px-2">
            <div className="mr-5">
              <div className=" text-sm font-semibold text-slate-500 uppercase mb-1">
                {"Effort"}
              </div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-slate-800 mr-2">
                  {effort}
                </div>
                {/* <div className="text-sm font-medium text-green-500">+7%</div> */}
              </div>
              <div className="text-sm text-slate-500">days</div>
            </div>
          </div>
          {/* Savings */}
          <div className="flex items-center py-2 px-2">
            <div className="mr-5">
              <div className=" text-sm font-semibold text-slate-500 uppercase mb-1">
                {"Potential Savings"}
              </div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-slate-800 mr-2">
                  {burdenSummary["First year savings"] > 0 ? (
                    burdenSummary["First year savings"].toFixed(0)
                  ) : (
                    <>
                      <div className="flex items-center">
                        {burdenSummary["First year savings"] &&
                          burdenSummary["First year savings"].toFixed(2)}
                        {/* <QuestionToolTip
                          field={"First year savings"}
                          text={
                            "First year potential savings is negative due to the effort required to resolve the reported burden. This number currently includes Organization-level impediments which may skew the results.  We are working on a filter to exclude these impediments from the calculation."
                          }
                        ></QuestionToolTip> */}
                        {/* <Tooltip
                          className={" text-xs text-slate-500 max-w-56"}
                          position={"top"}
                          // size="md"
                          description={
                            "First year potential savings is negative due to the effort required to resolve the reported burden. This number currently includes Organization-level impediments which may skew the results.  We are working on a filter to exclude these impediments from the calculation."
                          }
                          title={"First year savings"}
                        > */}
                        <Tooltip
                          className={"whitespace-normal inline-flex"}
                          size="lg"
                          bg="dark"
                          position={"top"}
                          description={
                            "First year potential savings is negative due to the effort required to resolve the reported burden. This number currently includes Organization-level impediments which may skew the results.  We are working on a filter to exclude these impediments from the calculation."
                          }
                          title={"First year savings"}
                        >
                          <Icon
                            icon="carbon:help"
                            className="ml-2 -mt-3 h-4 w-4 text-secondary-600"
                          />
                        </Tooltip>
                      </div>
                    </>
                  )}
                </div>
                {/* <div className="text-sm font-medium text-green-500">+7%</div> */}
              </div>
              <div className="text-sm text-slate-500">days/yr</div>
            </div>
            <div
              className="hidden md:block w-px h-8 bg-gray-200 mr-5"
              aria-hidden="true"
            ></div>
          </div>
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {/* <LineChart data={chartData} width={800} height={300} /> */}
        <RiskOverTimeChart data={chartData} width={800} height={300} />
      </div>
    </div>
  );
}

export default AnalyticsCard01;
