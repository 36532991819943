import React from "react";
import Paragraph from "../../components/Paragraph";
import Home from "../Home";
import GettingStarted from "../../components/GettingStarted";

export default function IntroPage(props) {
  return (
    <div className="flex h-[calc(100vh-200px)]">
      {/* <main className="h-[calc(100vh-64px)] block  top-16 bottom-0 items-center justify-center"> */}
      <div className="flex flex-col px-4 py-10 sm:px-6 lg:px-8 lg:py-6 max-w-5xl mx-auto my-auto">
        <div className="  ">
          {/* <h2 className="text-xl font-medium text-gray-900">Basecamp</h2> */}
          {/* {props.profile.tenantInfo.type == "admin" ? (
            <Paragraph size={"sm"}>
              JDO Connect gives you the ability to connect with other teams to
              crowdsource the impediment and burden clearance process. Below,
              you will find links to some basic steps to get you started. And to
              the right, you'll see our getting started guide. The getting
              started guide will walk you though the most common order in which
              teams get ramped up.
            </Paragraph>
          ) : (
            ""
          )} */}

          <div className="mt-4">
            <Home
              handleOpenAssessmentModal={props.handleOpenAssessmentModal}
              handleOpenContactModal={props.handleOpenContactModal}
            />
          </div>
        </div>
      </div>
      {/* </main> */}
      {/* 
      <aside className="w-96 bg-white fixed bottom-0 right-0 top-16 hidden overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
        <h2 className="text-xl font-medium text-gray-900">Getting Started</h2>
        <GettingStarted />
      </aside> */}
    </div>
  );
}
