import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import HomeImage from "../images/homeimage.jpg";
import DynamicImage from "./DynamicImage";
export default function GridCard(props) {
  const card = props;
  const to = props.to;
  const imageName = props.imageName;
  //
  const type = props.type;
  const history = useHistory();
  const details = (data) => {
    // const type = "assessment";
    let state = null;
    if (type === "assessment") {
      state = {
        isPreassessment: true,
      };
    }
    if (type === "learning") {
      state = {
        data: data,
      };
    }
    return history.push({
      pathname: props.to ? props.to : "/team/profile",
      state: state,
    });
  };

  return (
    <div className="col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200 flex-none h-full">
      <div className="flex flex-col h-full p-5">
        <header className="">
          <h2 className="text-xsl leading-snug font-semibold text-center inline-flex">
            {card.title}
          </h2>
        </header>
        <div className="grow mt-2 ">
          <div className="text-sm text-ellipsis overflow-hidden">
            <DynamicImage imageName={imageName} type={"nav"} />
            {/* <img src={HomeImage} /> */}
          </div>
          <div className="text-sm text-ellipsis overflow-hidden pt-2 pb-2 min-h-full flex-none">
            <p className="text-xs overflow-hidden">{card.text}</p>
          </div>
        </div>
        <footer className="mt-5">
          {/* <div className="text-sm font-medium text-slate-500 mb-2">{props.dates.from} <span className="text-slate-400">-&gt;</span> {props.dates.to}</div> */}
          <div className="flex  items-center w-full">
            {card.status &&
            (card.status !== "released") &
              (card.status !== "") &
              (card.status !== "Active") ? (
              <button
                className="btn w-full bg-gray-100 cursor-not-allowed text-slate-400"
                // to="/assessmentselect"
                disabled
                onClick={() => details(card)}
              >
                Coming Soon
              </button>
            ) : (
              <button
                className="btn w-full bg-orange-400 hover:bg-orange-400 text-white"
                onClick={() => details(card)}
              >
                {card.button ? card.button : "Learn more"}
              </button>
            )}
          </div>
        </footer>
      </div>
    </div>
  );
}
