import { useHistory } from "react-router-dom";
import HighestRisk from "../images/highest.jpg";
import { _assessment } from "../modules/_assessment";
// import ChartGauge from "./ChartGuage";
import DynamicImage from "./DynamicImage";
import GaugeChart from "react-gauge-chart";
import { ProgressBarSmall } from "./ProgressSmall";
import Stats from "./Stats";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
export default function ContentSplit(props) {
  const goToActionPlan = props.goToActionPlan;

  const thisAssessment = props.thisAssessment;
  const risk = props.risk;
  const blurbsDB = thisAssessment.blurbs;

  const history = useHistory();
  const goHome = () => {
    return history.push({
      pathname: "/home",
    });
  };

  const getLevelName = (level) => {
    switch (level) {
      case 0:
        return "Beginner";
      case 1:
        return "Intermediate";
      case 2:
        return "Advanced";
      case 3:
        return "Expert";
      default:
        return "Beginner";
    }
  };

  const getLevelColor = (score) => {
    if (score < 2) {
      //beginner
      return "text-red-700";
    } else if (score < 3) {
      //intermediate
      return "text-orange-500";
    } else if (score < 4) {
      //advanced
      return "text-amber-500 ";
    } else if (score <= 5) {
      //expert
      return "text-emerald-500";
    } else {
      //default
      return "text-slate-600";
    }
  };
  const getLevelBGColor = (score) => {
    // alert(score);
    if (score == 0) {
      //beginner
      return "bg-red-700 text-red-50";
    } else if (score == 1) {
      //intermediate
      return "bg-orange-500 text-orange-100";
    } else if (score == 2) {
      //advanced
      return "bg-amber-500 text-amber-100 ";
    } else if (score == 3) {
      //expert
      return "bg-emerald-500 text-emerald-300";
    } else {
      //default
      return "bg-slate-200 text-slate-600";
    }
  };

  const assessment_details = _assessment.getAssessmentTypeDetails(
    thisAssessment.assessment_type
  );

  const blurbs =
    assessment_details.length > 0 ? assessment_details[0].blurbs : [];

  const blurb = blurbs.length > 0 ? _assessment.getBlurb(risk, blurbs) : [];

  // const countOfEach = {};
  // for (const num of counts) {
  //   countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
  // }
  //
  const risk_threshold = _assessment.getRiskThresholds();
  const riskLevel = thisAssessment.risk_score;

  // const riskLevel = _assessment.calculateRisk(
  //   counts,
  //   countOfEach,
  //   risk_threshold
  // );
  const riskExplanation = ""; //look up function and pass riskLevel

  const res = thisAssessment.questions
    ? thisAssessment.questions.filter(
        (item) => item.isCorrect === true // "yes"
      ).length
    : null;

  const answered_questions =
    res != null
      ? thisAssessment.questions.length // thisAssessment.questions.filter((item) => "your_answer" in item).length
      : 0;

  const progress = res != null ? res / answered_questions : null;

  return (
    <div className="relative bg-white " style={{}}>
      {/* <div className="lg:absolute lg:inset-0"> */}
      {/* <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 mx-auto items-center"></div> */}
      <div className="pt-8 text-center">
        <div
          className=" gap-10 mt-2 mb-5 text-center  leading-8 "
          style={{ color: "rgb(132 204 22)" }}
        >
          {/*Change text color according to risk */}
          <div className="grid grid-flow-row grid-cols-2">
            <div className="flex flex-col space-y-3">
              <div className="text-slate-700 font-medium text-3xl sm:text-4xl">
                Average Score
              </div>
              <div
                className={`${getLevelColor(
                  thisAssessment.scores.avg_score
                )} text-4xl sm:text-4xl`}
              >
                {thisAssessment.scores.avg_score.toFixed(2)}
              </div>
            </div>
            <div className="flex flex-col space-y-3">
              <div className="text-slate-700 font-medium text-3xl sm:text-4xl">
                Maturity Level
              </div>
              <div
                className={`${getLevelColor(
                  thisAssessment.scores.avg_score
                )} text-4xl sm:text-4xl`}
              >
                {thisAssessment.scores.maturity_level}
              </div>
            </div>
          </div>
          <div className="mt-10 mb-10 ">
            <div className="mt-10 mb-10 ">
              <Stats
                data={props.competencies ? props.competencies : []}
                loading={false}
                title="Score by DevOps Model Area"
                showModelCoverage={true}
                process={true}
              />
            </div>
            <div className="space-y-6 text-left">
              <h2 className="text-left text-xl inline-flex  text-slate-900 font-semibold ">
                Your scoring details
              </h2>
              <p className="text-left p-2 mt-4 bg-secondary-50 border-2 border-dashed border-orange-500 text-sm text-slate-600">
                <h2 className=" inline-flex  text-slate-900 font-semibold ">
                  Why is this important?
                </h2>
                <p>
                  The graph below shows you each competency and how you scored.
                  Note the peaks and valleys. A good starting point is to focus
                  on low areas to the immediate left of higher areas. Those low
                  areas restrict your throughput making the higher areas less
                  efficient.
                </p>
              </p>
              <DashboardCard04
                data={
                  props.allCompetenciesByScore
                    ? props.allCompetenciesByScore
                    : []
                }
                title={"Competency Scores"}
                span={12}
                aspect={true}
              />
            </div>
          </div>

          <div
            id="progress"
            className="text-base text-slate-500 font-normal mt-5 mx-auto mb-10 w-96 text-center content-center m-auto"
          >
            {/* <div className="mt-5 text-center">
              You answered {res} of {answered_questions} questions the way
              Expert teams would.
              {progress === 0 ? (
                <div class=" mt-5 w-full bg-gray-200 h-2  align-middle rounded-full">
                  <div
                    class={`bg-red-500 h-2 rounded-full`}
                    style={{ width: "100%" }}
                  ></div>
                </div>
              ) : (
                <ProgressBarSmall progress={progress} />
              )}
            </div> */}
          </div>
        </div>
        <div className="w-3/4 m-auto mb-10"></div>
      </div>
      <div className="text-left justify-start  mb-5 p-5">
        <div className="px-4 py-5 sm:px-6 col-span-2">
          <h2
            id="applicant-information-title"
            className="text-2xl leading-6 font-medium text-slate-900"
          >
            Your Devops Expert Meter
          </h2>
          <p className="mt-5 text-slate-500">
            Your DevOps Expert meter gives you a visual representation of your
            maturity level compared to an expert level.
          </p>
          <p className="p-2 mt-4 bg-secondary-50 border-2 border-dashed border-orange-500 text-sm text-slate-600">
            <h2 className=" inline-flex  text-slate-900 font-semibold ">
              Why is this important?
            </h2>
            <p>
              While your score tells you your team's maturity level, the DevOps
              Expert meter tells you how far you are from the expert level. For
              example, you can be "Advanced" in your maturity level by answering
              all questions as "Advanced", but that would mean that you did not
              answer any questions as "Expert". On the other hand, you could be
              "Advanced" because you answered most questions as "Expert", but
              answered a few as "Beginner", which pulled your average score
              down. The DevOps Expert meter will show you how many questions you
              are not yet answering as "Expert".
            </p>
          </p>
        </div>
      </div>
      <div
        id="header"
        className="relative  px-4  sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-1"
      >
        <div
          id="guage"
          className="flex flex-col mx-auto justify-center text-center content-center"
        >
          <>
            <div className="items-center">
              <GaugeChart
                id="gauge-chart3"
                nrOfLevels={20}
                percent={progress}
                colors={["#FF0000", "#00FF00"]}
                textColor="#000000"
                hideText={true}
              />{" "}
            </div>

            <div className="m-auto p-3 text-slate-500 text-sm mb-5 p-5 w-3/4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
              </svg>
              You answered {res} of {answered_questions} questions the way
              Expert teams would.
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
