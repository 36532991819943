import React from "react";
import { Link } from "react-router-dom";

import OnboardingImage from "../images/onboarding-image.jpg";
import OnboardingDecoration from "../images/auth-decoration.png";

function Onboarding03() {
  const notificationMethods = [
    { id: "yes", title: "Yes" },
    { id: "no", title: "No" },
    { id: "unsure", title: "Not sure" },
  ];
  const questions = [
    {
      id: "q1",
      title:
        "Does your organisation have a place of business located in the UK or in the European Economic Area (‘EEA’) composed of the 28 EU Member States together with Iceland, Lichtenstein and Norway?",
    },
    {
      id: "q2",
      title:
        "If your organisation does not have a place of business within the EEA, does it collect personally identifiable information on EEA residents or conduct business in the EEA, for example via email and/or an e-commerce website??",
    },
    {
      id: "q3",
      title:
        "Does your organisation process the personal data of EEA residents in relation to the monitoring of their behaviour, where such behaviour takes place in the EEA? Examples include the monitoring of spending patterns or particular geographic movements.?",
    },
  ];
  return (
    <main className="bg-white">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            <div className="flex-1">
              {/* Header */}
              {/* <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"> */}
              {/* Logo */}
              {/* <Link className="block" to="/">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <defs>
                      <linearGradient x1="28.538%" y1="20.229%" x2="100%" y2="108.156%" id="logo-a">
                        <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                        <stop stopColor="#A5B4FC" offset="100%" />
                      </linearGradient>
                      <linearGradient x1="88.638%" y1="29.267%" x2="22.42%" y2="100%" id="logo-b">
                        <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                        <stop stopColor="#38BDF8" offset="100%" />
                      </linearGradient>
                    </defs>
                    <rect fill="#6366F1" width="32" height="32" rx="16" />
                    <path d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z" fill="#4F46E5" />
                    <path d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z" fill="url(#logo-a)" />
                    <path d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z" fill="url(#logo-b)" />
                  </svg>
                </Link>
                <div className="text-sm">
                  Have an account? <Link className="font-medium text-primary-500 hover:text-primary-600" to="/signin">Sign In</Link>
                </div> */}
              {/* </div> */}

              {/* Progress bar */}
              <div className="px-4 pt-12 pb-8">
                <div className="max-w-md mx-auto w-full">
                  <div className="relative">
                    <div
                      className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200"
                      aria-hidden="true"
                    ></div>
                    <ul className="relative flex justify-between w-full">
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-primary-500 text-white"
                          to="/onboarding-01"
                        >
                          1
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-primary-500 text-white"
                          to="/onboarding-02"
                        >
                          2
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-primary-500 text-white"
                          to="/onboarding-03"
                        >
                          3
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-gray-100 text-slate-500"
                          to="/onboarding-04"
                        >
                          4
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 py-8">
              <div className="max-w-md mx-auto">
                <h1 className="text-3xl text-slate-800 font-bold mb-6">
                  GDPR Readiness
                </h1>
                {/* htmlForm */}
                <form>
                  <div className="space-y-4 mb-8">
                    {/* Company Name */}
                    {/* <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="company-name"
                      >
                        Company Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        id="company-name"
                        className="form-input w-full"
                        type="text"
                      />
                    </div> */}
                    {questions.map((question) => (
                      <div>
                        <p className="text-sm leading-5 text-slate-500">
                          {question.title}
                        </p>
                        <fieldset className="mt-4">
                          <legend className="sr-only">
                            Notification method
                          </legend>
                          <div className="mb-10 space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            {notificationMethods.map((notificationMethod) => (
                              <div
                                key={question.id + "-" + notificationMethod.id}
                                className="flex items-center "
                              >
                                <input
                                  id={question.id + "-" + notificationMethod.id}
                                  name={question.id}
                                  type="radio"
                                  // defaultChecked={
                                  //   notificationMethod.id === "email"
                                  // }
                                  className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                                />
                                <label
                                  htmlFor={
                                    question.id + "-" + notificationMethod.id
                                  }
                                  className="ml-3 block text-sm font-medium text-slate-700"
                                >
                                  {notificationMethod.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    ))}

                    {/* City and Postal Code */}
                    {/* <div className="flex space-x-4">
                      <div className="flex-1">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="city"
                        >
                          City <span className="text-red-500">*</span>
                        </label>
                        <input
                          id="city"
                          className="form-input w-full"
                          type="text"
                        />
                      </div>
                      <div className="flex-1">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="postal-code"
                        >
                          Postal Code <span className="text-red-500">*</span>
                        </label>
                        <input
                          id="postal-code"
                          className="form-input w-full"
                          type="text"
                        />
                      </div>
                    </div> */}
                    {/* Street Address */}
                    {/* <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="street"
                      >
                        Street Address <span className="text-red-500">*</span>
                      </label>
                      <input
                        id="street"
                        className="form-input w-full"
                        type="text"
                      />
                    </div> */}
                    {/* Country */}
                    {/* <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="country"
                      >
                        Country <span className="text-red-500">*</span>
                      </label>
                      <select id="country" className="form-select w-full">
                        <option>USA</option>
                        <option>Italy</option>
                        <option>United Kingdom</option>
                      </select>
                    </div> */}
                  </div>
                  <div className="flex items-center justify-between">
                    <Link
                      className="text-sm underline hover:no-underline"
                      to="/onboarding-02"
                    >
                      &lt;- Back
                    </Link>
                    <Link
                      className="btn bg-primary-500 hover:bg-primary-600 text-white ml-auto"
                      to="/onboarding-04"
                    >
                      Next Step -&gt;
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        {/* <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
          <img
            className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
            src={OnboardingDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div> */}
      </div>
    </main>
  );
}

export default Onboarding03;
