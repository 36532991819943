import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useEffect } from "react";
import { utils } from "../../../modules/_utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PlanSelectCard({
  setPlan,
  plans,
  selectPlan,
  addToMyPlan,
  profile,
}) {
  const [selected, setSelected] = useState(null);
  const [allPlans, setAllPlans] = useState(plans);
  const [impedimentCount, setImpedimentCount] = useState(0);

  useEffect(() => {
    setPlan(plans[0]);
    setSelected(plans[0]);
  }, []);

  useEffect(() => {
    setAllPlans(plans);
  }, [plans]);

  const getImpedimentCountOLD = (plan) => {
    let count = 0;
    plan.Competencies.forEach((competency) => {
      count += competency.Cards.length;
    });
    return count;
  };

  const getImpedimentCount = (plan) => {
    let count = 0;
    plan.Competencies.forEach((competency) => {
      count += competency.Cards.filter((card) => card.inPlan === true).length;
    });
    return count;
  };

  const setSelectedPlan = (e, plan) => {
    // let tenantName = plan.tenantId.tenantTitle;
    // let tenantId = plan.tenantId._id;
    // plan = {
    //   ...plan,
    //   tenantName,
    //   tenantId,
    // };
    // setSelected(plan);
    e.preventDefault();
    setPlan(plan);
    addToMyPlan(plan);
    selectPlan(plan);
    setSelected(plan);
  };

  return (
    <div className="w-full pb-20">
      <RadioGroup value={selected} className={"h-full"}>
        <RadioGroup.Label className="sr-only">Select a plan</RadioGroup.Label>
        <div className="space-y-4 h-full overflow-hidden">
          {allPlans.map((plan) => (
            <RadioGroup.Option
              key={plan.name}
              value={plan}
              onClick={(e) => setSelectedPlan(e, plan)}
              checked={selected?._id === plan._id}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-secondary-600 " : "border-gray-300",
                  active ? "border-secondary-600 " : "",
                  "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex items-center">
                    <span className="flex flex-col text-sm">
                      <RadioGroup.Label
                        as="span"
                        className="font-medium text-gray-900"
                      >
                        {plan.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="text-gray-500"
                      >
                        <span className="block sm:inline text-accent-600">
                          {plan.tenantName &&
                            plan.tenantName.length > 0 &&
                            plan.tenantName}
                        </span>
                        {/* <span className="block sm:inline">{plan.status}</span>{" "} */}
                        <span
                          className="hidden sm:mx-1 sm:inline"
                          aria-hidden="true"
                        >
                          &middot;
                        </span>{" "}
                        <span className="block sm:inline">
                          <time dateTime={plan.goal_date}>
                            {utils.formatDate(plan.goal_date)}
                          </time>
                        </span>
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <RadioGroup.Description
                    as="span"
                    className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                  >
                    <span className="font-medium text-gray-900">
                      {plan.Competencies && plan.Competencies.length > 0 ? (
                        <span>
                          {plan.Competencies.length}{" "}
                          {plan.Competencies.length > 1
                            ? "Competencies"
                            : "Competency"}
                        </span>
                      ) : (
                        <span>0 Competencies</span>
                      )}
                    </span>
                    <span className="ml-1 text-gray-500 sm:ml-0">
                      {impedimentCount > 0 ? (
                        <span>
                          {impedimentCount}{" "}
                          {impedimentCount > 1 ? "Impediments" : "Impediment"}
                        </span>
                      ) : (
                        <span>{getImpedimentCount(plan) + " Impediments"}</span>
                      )}
                    </span>
                  </RadioGroup.Description>
                  <span
                    className={classNames(
                      active ? "border" : "border-2",
                      checked
                        ? "border-secondary-600 border-2"
                        : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-lg"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
