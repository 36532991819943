import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Transition from "../utils/Transition";

import UserAvatar from "./profile.png";
import { utils } from "../modules/_utils";
import { _auth } from "../modules/_auth";
import { usePersistStore } from "../store/usestore";
import Button from "./Button";
import { FeatureFlag } from "./FeatureFlag";
import { _stripe } from "../modules/_stripe";

function DropdownProfile({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tier, setTier] = useState(null);
  const [stripeLink, setStripeLink] = useState(null);
  // Cache Object
  useEffect(() => {
    (async () => {
      let myTier = "Free"; //await utils.getCacheData("tier", "Stripe");

      setTier(myTier + " Tier");
    })();
  }, [tier]);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const history = useHistory();
  const profile = _auth.getUserProfile();
  const { logout } = usePersistStore();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const getStripeLink = async (e) => {
    e.preventDefault();
    const stripeLink = await _stripe.stripePortalSession();
    return window.open(stripeLink, "_blank");
  };

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group rounded-full p-2   hover:bg-slate-100"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            clipRule="evenodd"
          />
        </svg>

        {/* <div className="flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium group-hover:text-slate-800">John Henry</span>
          <svg
            className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div> */}
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200 space-y-2">
            <div className="font-medium text-slate-800">
              {profile !== null && profile && profile.name}
            </div>
            <div className="text-xs text-slate-500 italic">
              {profile !== null && profile && profile.email}
            </div>
            <div className="text-xs text-slate-500 ">
              {profile !== null && profile && profile.role}
            </div>

            {/* <div className="text-xs text-slate-500  ">
              <span className="inline-flex mr-2">
                {tier && tier !== null && tier}
              </span>
              <FeatureFlag featureName="Upgrade" type="flag">
                <form
                  className="inline-flex"
                  target="_blank"
                  action={stripeLink}
                >
                  <Button
                    handleClick={(e) => {
                      getStripeLink(e);
                    }}
                    size="xs"
                    color="lime"
                    rounded
                    // type="submit"
                  >
                    Upgrade
                  </Button>
                </form>
              </FeatureFlag>
            </div> */}
          </div>
          <ul>
            {/* {profile !== null && profile && profile.role === "Admin" && (
              <li>
                <Link
                  className="font-medium text-sm text-primary-500 hover:text-primary-600 flex items-center py-1 px-3"
                  to="/settings/account"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  Settings
                </Link>
              </li>
            )} */}
            <li>
              <button
                className="font-medium text-sm text-accent-600 hover:text-accent-700 flex items-center py-1 px-3"
                // to="/signin"
                onClick={() => {
                  _auth.logout(history);
                  logout();
                  setDropdownOpen(!dropdownOpen);
                  history.push("/");
                }}
              >
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownProfile;
