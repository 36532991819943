import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Logo from "../images/logo.jpg";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../components/Button";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { Audit } from "../components/AuditFeature";
import { usePersistStore } from "../store/usestore";

function MFA() {
  const mvp = false;
  const history = useHistory();
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const [passwordMatched, isPasswordMatched] = useState(true);
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const { isSuccess, isAuthenticated, mfa, error } = usePersistStore();
  const profile = history.location.state.profile;

  //check if isAuthenticated is true. if not, redirect to signin
  if (!isAuthenticated) {
    history.push("/signin");
  }

  const multiFactorAuth = async (event) => {
    setLoader(true);
    event.preventDefault();
    let userProfile = profile;
    let response = await mfa(otp);
    if (response) {
      //
      if (profile != false) {
        let tenantCount = userProfile.tenantCount;
        if (tenantCount > 1) {
          return history.push({
            state: { profile: userProfile },
            pathname: "/select-tenant",
          });
        } else {
          //update profile with tenant information if they only have one tenant
          userProfile.tenantId = userProfile.tenants[0].tenantId._id;
          userProfile.tenantName = userProfile.tenants[0].tenantId.tenantTitle;
          let isRenamed = userProfile.tenants[0].tenantId.isRenamed;
          userProfile.tenantRole = userProfile.tenants[0].role;
          userProfile.role =
            userProfile.companyRole &&
            (userProfile.companyRole == "Administrator" ||
              userProfile.companyRole == "Observer")
              ? userProfile.companyRole
              : userProfile.tenants[0].role;

          _auth.setUserProfile(userProfile);

          if (
            isRenamed ||
            userProfile.role == "Collaborator" ||
            userProfile.role == "Observer"
            // userProfile.dnd || userProfile.onBoardCompleted
          ) {
            return history.push({
              state: { profile: userProfile },
              pathname: "/home",
            });
          } else {
            setLoader(false);
            return history.push({
              state: { profile: userProfile },
              pathname: "/onboarding",
            });
          }
        }
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  return (
    <main className="bg-gray-100">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            {/* <div className="max-w-sm mx-auto px-4">
              <img className="max-w-18 mx-auto px-4 py-8" src={Logo} />
            </div> */}

            <div className="bg-white bg-white shadow-lg rounded-md border border-gray-200 max-w-sm mx-auto p-8 ">
              <h1 className="text-2xl font-bold mb-6">
                <ShieldCheckIcon className="inline w-8 h-8 mr-2" />
                <span>Let's verify it's you </span>
              </h1>
              <div className="mb-10">
                We've emailed you a verification code that's good for 15
                minutes. Check your email and enter your verification code
                below:
              </div>
              {/* Form */}
              <form onSubmit={multiFactorAuth}>
                <div className="space-y-4">
                  <div>
                    {/* <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Verification Code
                    </label> */}
                    <input
                      id="Otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="h-12 rounded w-full
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-orange-500
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-none  focus:ring-1 focus:ring-secondary-500"
                      type="number"
                      autoComplete="on"
                      placeholder="Verification Code"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6 mb-4">
                  {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading..."
                      />
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      disabled={otp === undefined || otp === ""}
                      // class="btn bg-primary w-full hover:bg-primary-600 text-white mb-4"
                      fullWidth
                      audit={true}
                      action="Login"
                      size="lg"
                      color="secondary"
                      // onClick={auth}
                    >
                      {" "}
                      Continue Login
                    </Button>
                  )}
                  {/* <div>
                    {" "}
                    <Button
                      type="submit"
                      // disabled={otp === undefined || otp === ""}
                      class="btn bg-primary-500 w-full hover:bg-primary-600 text-white"
                      fullWidth
                      // onClick={auth}
                    >
                      Continue Login
                    </Button>
                  </div> */}

                  {/* After login and MFA, check to see if onboarding has completed or 
            if the user has checked the "Don't show this wizard again" checkbox.
            If the user has not checked the do not show again box and has no completed onboarding, redirect to the onboarding page.
             */}
                  {/* <Link
                    className="btn bg-primary-500 hover:bg-primary-600 text-white ml-3"
                    to="/onboard"
                  >
                    Sign In
                  </Link> */}
                </div>
              </form>
              <button
                // type="submit"

                className="btn bg-white w-full text-primary-600"
                fullWidth
                onClick={history.goBack}
              >
                Cancel
              </button>

              {/* Footer */}
              {passwordMatched === false && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Please recheck your password.
                </div>
              )}
              {isAuthenticated === false && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Check your code and try again.
                </div>
              )}
              {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-primary-500 hover:text-primary-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default MFA;
