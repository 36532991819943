import { useEffect } from "react";
import HeroImage from "../../../images/hero-cover1.jpg";
import { useHistory } from "react-router-dom";

export function Hero(props) {
  const title = props.title;
  const subtitle = props.subtitle;
  const image = props.image;
  const description = props.description;
  const buttons = props.buttons;

  const history = useHistory();

  return (
    <>
      <div className="relative isolate overflow-hidden pt-14 bg-slate-900">
        <img
          src={HeroImage}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover opacity-50 "
        />
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden  sm:-top-80 "
          aria-hidden="true"
        ></div>
        <div
          id="top"
          className="relative overflow-hidden h-screen w-screen bg-cover bc-center     />"
        >
          <div className="h-full w-full absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
            <div className="h-full  w-full xl:grid xl:grid-cols-1">
              <div className="absolute inset-0 h-full w-full    " />
            </div>
          </div>
          <div className="h-3/4 bg-gray-900 bg-opacity-0 rounded-xl items-center justify-center max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-1 xl:grid-flow-col-dense xl:gap-x-8">
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className=" text-center text-white opacity-90 text-4xl tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
                {title}
              </h1>
              <h2 className="text-center mt-4 font-display text-3xl tracking-tight text-white opacity-90 sm:text-4xl">
                {subtitle}
              </h2>
              <p className="text-center mt-4 text-lg tracking-tight text-white opacity-90 max-w-large mx-auto sm:max-w-3xl">
                {description}
              </p>
              {/* <h2 className=" text-center text-2xl tracking-tight sm:text-3xl md:text-4xl lg:text-4xl">
              <span className="block text-primary-500 ">{subtitle}</span>
            </h2> */}
              {/* <p className="leading-6  mt-6 max-w-lg mx-auto text-center text-slate-700 sm:max-w-3xl">
              {description}
            </p> */}
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex justify-center">
                <div className="flex justify-center  space-y-4 sm:space-y-0 m-10 sm:inline-grid sm:grid-cols-1 sm:gap-5">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push("/home");
                      // props.executeScroll("partner_form");
                    }}
                    className="w-full md:w-48 flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-opacity-80 bg-secondary-700  hover:bg-secondary-700 hover:bg-opacity-70 sm:px-8"
                  >
                    Start the journey
                  </button>
                </div>
                <div className="flex justify-center  space-y-4 sm:space-y-0 m-10  sm:inline-grid sm:grid-cols-1  sm:gap-5">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      props.executeScroll("vetBanner");
                    }}
                    className="w-full md:w-48 flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-700 bg-opacity-80 hover:bg-indigo-700 hover:bg-opacity-70 sm:px-8"
                  >
                    Keep learning
                  </button>

                  {/* <a
                  href="#"
                  className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                >
                  Live demo
                </a> */}
                </div>
              </div>
              {/* <div className="m-25">
              <p className="mt-25 text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                Built by veterans in eDiscovery, CyberSecurity, Privacy, and
                Breach
              </p>
            </div> */}
            </div>
          </div>
        </div>

        {/* <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src={HeroImage}
                alt="People working on laptops"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">{props.title}</span>
                <span className="block text-indigo-200">{props.subtitle}</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                {props.descrption}
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a
                    href="#"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                  >
                    Get started
                  </a>
                  <a
                    href="#"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Live demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
}
