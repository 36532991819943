import React from "react";

import { Route, Redirect } from "react-router-dom";
import { Component } from "react";
import PropTypes from "prop-types";
import { usePersistStore } from "../store/usestore";
import jwtDecode from "jwt-decode";
import { Store } from "../layouts/store";
import { _auth } from "../modules/_auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isMfaSuccess, getAuthAsync } = usePersistStore();
  let token = localStorage.getItem("jwtToken");

  getAuthAsync();

  let decoded;
  let currentTime;
  let expired;
  if (token && token !== "undefined" && token !== null) {
    decoded = jwtDecode(token);

    currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      expired = true;
    }
  } else {
    expired = true;
  }

  let mfaAuthenticated = !_auth.getUserProfile()?.MFA ? true : isMfaSuccess;
  const authenticated =
    isAuthenticated && mfaAuthenticated && token !== null && !expired;

  return authenticated ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
};

export default PrivateRoute;
