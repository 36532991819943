/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import Grid from "../components/Grid";
import HomeGrid from "../components/HomeGrid";
import HomeHeader from "../components/HomeHeader";
import { _auth } from "../modules/_auth";
import { _user_profile } from "../modules/_user_profile";
import { useHistory } from "react-router-dom";
import Skeleton from "../components/Skeleton";
import Button from "../components/Button";
import { audit } from "../modules/_audit";
import GettingStarted from "../components/GettingStarted";

const cards = [
  {
    title: "Understand your risk",
    text: "How prepared are you for a data incident?  Are you compliant with the latest data privacy laws? Take one of our Assessments and receive your own Readiness Report with customized action plan to minimize your risk.",
    button: "Take an assessment",
    to: "/assessmenthub",
    action_icon: "takeassessment",
    status: "",
    show: true,
  },
  {
    title: "Cyber Security 101, 201,…",
    text: "PCI?  NIST?  CCPA?  These are complicated topics.  Visit our Learning Center to get up to speed on the basics or just brush up on the latest changes.  At DSU, we strive to make the complicated, simple!",
    button: "Jumpstart your Understanding",
    to: "/learninghub",
    action_icon: "Understanding",
    status: "",
    show: false,
  },
  {
    title: "Get Help!",
    text: "Whether you are looking to review your action plan, or connect with a solution provider to understand their offering, we have Specialists ready to assist.",
    button: "Book an Expert",
    to: "/contact",
    action_icon: "Expert",
    status: "",
    show: true,
  },
  {
    title: "Solutions",
    text: "Was your action plan not spotless?  Have some work to do and need to connect with a provider?  DSU has a fully vetted list of first-class partners ready to assist.",
    button: "Visit the Marketplace",
    to: "/marketplace",
    action_icon: "marketplace",
    show: false,
  },
  {
    title: "Community",
    text: "Connect with people and companies who have been where you are.  Have a question for the community?  Want to rave about a partner or solution?  Your experiences are valuable and we want to hear them.  Join the conversation!",
    button: "Next Stop: Community!",
    to: "/home",
    action_icon: "Community",
    status: "coming soon",
    show: false,
  },
  {
    title: "Premium Offerings",
    text: "Certified employee training is one of the most important things you can do to be prepared.  This, and other valuable offerings, are available to you as a DSU community member. ",
    button: "Explore Value Added Services",
    to: "/home",
    action_icon: "Premium Offerings",
    status: "coming soon",
    show: false,
  },
];

const headerInfo = {
  title: "Welcome, Joe!",
  text: "This is your DSU hub. From here you’ll be able to understand the world of cyber security and data privacy through our Learning Center, get tailored action plans with our Assessments, connect with the DSU Community, browse our solution partners in the Marketplace and more!",
  to: "/home",
  linkText: "for a message from our founder.",
  action_icon: "",
  status: "",
};

export default function Home(props) {
  let profile = _auth.getUserProfile();
  const homeGrid = true;
  const [showHome, setShowHome] = useState(true);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleShowHomeClick(e, value) {
    e.preventDefault();
    setChecked(value);

    let ans = await _user_profile.updateShowHome({ showHome: !value });
    if (ans) {
      profile.showHome = !value;
      _auth.setUserProfile(profile);

      //see if we can add socket listener to update nav links in layout without refres
    }
    setTimeout(() => {
      if (isChecked) {
        window.location.reload();
        audit.logAction("Hide Home Page");
      }
    }, 3000);

    // if user clicks undo, then show home page
  }

  const isChecked = () => {
    return checked;
  };

  async function handleUndoClick(e) {
    setChecked(false);
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    let ans = await _user_profile.updateShowHome({ showHome: true });
    if (ans) {
      profile.showHome = true;
      _auth.setUserProfile(profile);
      profile = _auth.getUserProfile();
    }

    setLoading(false);

    // setShowHome(true);
  }

  // };

  return (
    <div>
      {profile.tenantInfo.type == "admin" ? (
        ""
      ) : (
        <HomeGrid
          profile={profile}
          handleOpenAssessmentModal={(e) => {
            props.handleOpenAssessmentModal(e);
          }}
          handleOpenContactModal={(e) => {
            props.handleOpenContactModal(e);
          }}
        />
      )}
    </div>
  );
}
