import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MarketplacePanel from "../components/MarketplacePanel";

import { _marketplace } from "../modules/_marketplace";
import Tabs from "./component/Tabs";
import ModalBasic from "../components/ModalBasic";
import NotificationBanner from "../components/NotificationBanner";

function Marketplace1() {
  const [data, setData] = useState("loading");
  const [open, setOpen] = useState(true);
  let d1 = [];
  const location = useLocation();
  const selectedTab = location.state ? location.state.filter : "View All";

  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const response = _marketplace.getMarketPlaceVendors();
      const json = await response;
      // set state with the result
      setData(json);
      // convert the data to json
    };
    // call the function insude useEffect
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  const closeBanner = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <NotificationBanner
          className={"sticky top-0"}
          setOpen={setOpen}
          showClose={true}
          text={
            "We haven't officially launched this page, so feel free to click around. It won't contact anyone!"
          }
        />
      )}
      <MarketplacePanel
        selectedTab={selectedTab}
        data={data != undefined ? data : []}
      />
    </div>
  );
}

export default Marketplace1;

/* To do: 
1. DONE - Change switch state after the modal is closed
2. Add icon to marketplace card to indicate you've connected (backend and frontend work)
(if connectedCount > 0)
3. Add action on close modal to send email or integrate with Hubspot
4. DONE - Pass company name and email from marketplace page to card
*/
