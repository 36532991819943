import React from "react";
import { useHistory } from "react-router-dom";

export function ListSelector(props) {
  const { data } = props;
  const { handleClick } = props;
  return (
    <ul className="space-y-2 mb-4">
      {data.map((a) => {
        return (
          a.status != "disabled" && (
            <li key={a.key}>
              <button
                disabled={a.status !== "published" && a.status !== "Beta"}
                onClick={(e) => handleClick(e, a)}
                className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out"
              >
                <div className="flex items-center">
                  {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                  <div className="grow">
                    <div className="flex flex-wrap items-center justify-between mb-0.5">
                      <span className="font-semibold text-slate-800">
                        {a.name}
                        {a.status != "published" && (
                          <span className="normal-case text-xs italic text-primary-500 align-top ml-1">
                            {a.status}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="text-xs">{a.description}</div>
                  </div>
                </div>
              </button>
            </li>
          )
        );
      })}
    </ul>
  );
}
