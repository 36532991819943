import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";
import { utils } from "./_utils";

const { postRequest, getRequest } = require("./ApiRequest");

export const _stripe = {
  stripePortalSession: async () => {
    let stripeCustomerId = await utils.getCacheData("id", "Stripe");

    let url = await postRequest("/stripe/create-customer-portal-session", {
      customerId: stripeCustomerId,
    });

    return url.data.data.url;
  },

  async getStripeData() {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    const stripeId = await this.getStripeCustomerId();
    const stripeProducts = await this.getProducts(stripeId);

    const stripeTier = this.getTier(stripeProducts[0]);
    const userLimit = 100;
    const employeeLimit = 200;

    let data = {
      id: stripeId,
      products: stripeProducts,
      tier: stripeTier,
      userLimit: userLimit,
      employeeLimit: employeeLimit,
    };

    utils.cacheData("Stripe", data);

    return data;
  },

  async getStripeCustomerId() {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    //check tenants table for stripe customer id by tenantId
    let response = await postRequest(
      "/stripe/get-customer-id",
      _auth.getUserProfile()
      // {
      //   tenantId: _auth.getUserProfile().tenantId,
      // }
    );

    return response.data.data.customerId;
  },

  async getProducts(id) {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let customerId;
    if (id) {
      customerId = id;
    } else {
      customerId = await this.getStripeCustomerId();
    }

    let response = await postRequest("/stripe/get-products", {
      customerId: customerId,
    });
    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },

  async getStripeCustomer() {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const id = "cus_MMpg2jjQKlbCOB";
    let response = await postRequest("/stripe/get-customer", { id });
    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },

  getTier(productId) {
    let tiers = this.getTiers();
    if (tiers) {
      let tier = tiers.find((t) => t.productId === productId);
      if (tier) {
        return tier.tier;
      } else return "Free";
    } else {
      return "Free";
    }
  },

  getTiers() {
    //move to database
    let tiers = [
      {
        tier: "Free",
        productId: "prod_MnjZVY3AgKLiEr",
      },
      {
        tier: "Grow",
        productId: "prod_MnjMHGT994ZrmL",
      },
      {
        tier: "Scale",
        productId: "prod_MnjMgQ8kVqKJqt",
      },
    ];
    return tiers;
  },

  async getSubscription() {
    const id = "cus_MMpg2jjQKlbCOB";
    let response = await postRequest("get-subscription", { id });
    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },

  getEntitledFeatures(tier) {
    //filter out features that are not entitled
    let tmp = [];
    let features = this.features();
    let entitledFeatures = features.filter((feature) => {
      if (feature.tiers.includes(tier)) {
        tmp.push(feature);
        utils.cacheData("entitledFeatures", tmp);
        return true;
      } else {
        return false;
      }
    });
    return tmp;
  },
};
