import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import DSULogo from "../images/logo.jpg";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import { _user } from "../modules/_user";
import Button from "../components/Button";

function ResetPasswordEmail(props) {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [otp, setOtp] = useState();
  const [err, setErr] = useState(false);
  const [tokenErr, setTokenErr] = useState(false);
  const [tokenErrMessage, setTokenErrMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token, id } = useParams();
  const [loader, setLoader] = useState(false);
  const [showInviteButton, setShowInviteButton] = useState(true);
  const [resendingToken, setResendingToken] = useState(false);
  const [newTokenSent, setNewTokenSent] = useState(false);

  const load = true;
  useEffect(() => {
    (async () => {
      if (!newTokenSent && !resendingToken) {
        setLoader(true);
        let checkIfValid = await _auth.verifyToken(token, id);
        if (checkIfValid.isValid) {
          setLoader(false);
        } else {
          setTokenErr(true);
          setTokenErrMessage(
            "Your link has expired.  Please request a new one."
          );
        }

        setLoader(false);
      }
    })();
  }, []);

  const resendInvite = async (e) => {
    e.preventDefault();

    setResendingToken(true);
    setLoader(true);
    const success = await _user.reinviteUser(id);
    if (success && success == true) {
      setTokenErr(false);
      setTokenErrMessage("");
      setNewTokenSent(true);
      setResendingToken(false);
      setLoader(false);
      setShowInviteButton(false);
    } else {
      setLoader(false);
      setNewTokenSent(false);
      setResendingToken(false);
      setTokenErr(true);
      setTokenErrMessage("Something went wrong.  Please contact the JDO team.");
      setShowInviteButton(false);
      setResendingToken(false);
      setLoader(false);
    }
  };

  const resetPassword = async (event) => {
    setLoader(true);
    event.preventDefault();

    let path = "/home";

    if (password !== confirmPassword) {
      setErr(true);

      setLoader(false);
    } else {
      let data = {
        id,
        token,
        password,
        confirmPassword,
      };
      let profile = await _auth.resetPasswordEmail(data, history);

      if (profile === false) {
        setLoader(false);
        setIsAuthenticated(true);
      } else {
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
          history.push("/");
        }, 2000);
      }
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="flex max-w-sm mx-auto px-4">
              <img width="80" className="mx-auto px-4 py-8" src={DSULogo} />
              <span className="inline-flex text-3xl m-auto font-bold text-slate-700">
                JDO Connect{" "}
              </span>
            </div>
            {tokenErr ? (
              <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                {/* {tokenErrMessage} */}
                <div className="flex space-y-3 flex-col items-center justify-center mt-6 text-slate-900 text-sm">
                  <div className="text-secondary-500 font-semibold">
                    {tokenErrMessage}
                  </div>
                  {showInviteButton && (
                    <div>
                      <Button
                        type="submit"
                        color="secondary"
                        loader={loader}
                        // class="btn btn-lg w-full bg-secondary-600 hover:bg-secondary-600 hover:shadow-sm text-white "
                        handleClick={(e) => resendInvite(e)}
                      >
                        Get a new link
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="max-w-sm mx-auto px-4 ">
                {!newTokenSent && (
                  <h1 className="text-xl text-slate-700 font-bold mb-6">
                    Create a new password
                  </h1>
                )}

                {!newTokenSent && (
                  <form onSubmit={resetPassword}>
                    <div className="space-y-4">
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          New Password
                        </label>
                        <input
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-orange-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-none focus:ring-1 focus:ring-secondary-500"
                          type="password"
                          autoComplete="on"
                        />
                      </div>
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Retype New Password
                        </label>
                        <input
                          id="confirm-password"
                          className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-orange-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-none focus:ring-1 focus:ring-secondary-500"
                          type="password"
                          autoComplete="on"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-6">
                      <Button
                        type="submit"
                        color="secondary"
                        fullWidth
                        class="btn btn-lg w-full bg-secondary-600 hover:bg-secondary-600 hover:shadow-sm text-white "
                        // onClick={auth}
                        loader={loader}
                      >
                        Set Password
                      </Button>
                    </div>
                  </form>
                )}
                {err && (
                  <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                    The passwords you entered do not match. Please try again.
                  </div>
                )}
                {/* Footer */}
                {isAuthenticated && (
                  <div className="flex items-center justify-center mt-6 text-secondary-500 text-sm">
                    <span className="font-semibold">Something went wrong </span>
                    <button
                      type="submit"
                      class="btn btn-lg w-full bg-secondary-600 hover:bg-secondary-600 hover:shadow-sm text-white "
                      // onClick={auth}
                    >
                      Something went wrong. Let the JDO team know you got this
                      message.
                    </button>
                  </div>
                )}
                {newTokenSent && (
                  <div className="flex items-center justify-center mt-6 text-secondary-500 text-sm">
                    <span className="font-semibold">Check your email! </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPasswordEmail;
