import React, { useEffect, useRef } from "react";
import Button from "./Button";
import { Icon } from "@iconify/react";
import { HandThumbUpIcon } from "@heroicons/react/20/solid";
import CommentsBar from "./CommentsBar";
import { utils } from "../modules/_utils";
// const comments = [
//   {
//     id: 1,
//     name: "Michael Gough",
//     date: "Feb. 8, 2022",
//     comment:
//       "Very straight-to-point article. Really worth time reading. Thank you! But tools are just the instruments for the UX designers. The knowledge of the design tools are as important as the creation of the design strategy.",
//     replies: [
//       {
//         id: 1,
//         name: "Jese Leos",
//         date: "Feb. 12, 2022",
//         comment: "Much appreciated! Glad you liked it ☺️",
//         replies: [],
//         thanks: {
//           count: 2,
//           users: [
//             { id: "1", name: "Oran Sears" },
//             { id: "2", name: "Bill Gates" },
//           ],
//         },
//       },
//       {
//         id: 2,
//         name: "Bonnie Green",
//         date: "Mar. 12, 2022",
//         comment:
//           "The article covers the essentials, challenges, myths and stages the UX designer should consider while creating the design strategy.",
//         replies: [],
//         thanks: { count: 2, users: ["Oran Leos", "Bonnie Green"] },
//       },
//       {
//         id: 3,
//         name: "Helene Engels",
//         date: "Jun. 23, 2022",
//         comment:
//           "Thanks for sharing this. I do came from the Backend development and explored some of the tools to design my Side Projects.",
//         replies: [],
//         thanks: { count: 2, users: ["Jese Leos", "Bonnie Green"] },
//       },
//     ],
//     thanks: { count: 2, users: ["Jese Leos", "Bonnie Green"] },
//   },
//   {
//     id: 2,
//     name: "Jese Leos",
//     date: "Feb. 12, 2022",
//     comment: "Much appreciated! Glad you liked it ☺️",
//     replies: [],
//     thanks: { count: 2, users: ["Jese Leos", "Bonnie Green"] },
//   },
//   {
//     id: 3,
//     name: "Bonnie Green",
//     date: "Mar. 12, 2022",
//     comment:
//       "The article covers the essentials, challenges, myths and stages the UX designer should consider while creating the design strategy.",
//     replies: [],
//     thanks: { count: 0, users: [] },
//   },
//   {
//     id: 4,
//     name: "Helene Engels",
//     date: "Jun. 23, 2022",
//     comment:
//       "Thanks for sharing this. I do came from the Backend development and explored some of the tools to design my Side Projects.",
//     replies: [],
//     thanks: { count: 0, users: [] },
//   },
// ];

export default function Comments(props) {
  const {
    setCommentText,
    commentText,
    handlePost,
    handleReply,
    handleDelete,
    handleEdit,
    handleThankYou,
    // comments,
    audit,
    action,
    auditDetails,
  } = props;
  const ref = useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [comments, setComments] = React.useState([]);

  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const handleInput = (e) => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${e.target.scrollHeight - 16}px`;
    }
  };
  return (
    <section class="  py-8  antialiased">
      <div class="max-w-2xl mx-auto px-4">
        <form class="mb-6">
          <div class="bg-white py-2 px-4 mb-4  rounded-lg rounded-t-lg border border-gray-200  dark:border-gray-700">
            <label for="comment" class="sr-only">
              Your comment
            </label>
            <textarea
              id="comment"
              ref={ref}
              rows="1"
              class="px-0 overflow-hidden w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none   dark:bg-gray-800"
              placeholder="Write a comment..."
              value={commentText}
              required
              //disable resizing
              style={{ resize: "none" }}
              onInput={(e) => handleInput(e)}
              onChange={(e) => setCommentText(e.target.value)}
            ></textarea>
          </div>
          <div class="flex items-center justify-end">
            <Button
              type="submit"
              handleClick={(e) => {
                e.preventDefault();
                handlePost();
              }}
              audit={audit}
              action={action ? action : ""}
              details={auditDetails}
              disabled={!commentText}
              loader={loading}
              //move button to end of line

              // class="inline-flex ml-auto items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Post comment
            </Button>
          </div>
        </form>

        {comments &&
          comments.map((comment, cIdx) => {
            const { _id, name, createdAt, replies } = comment;
            // display comment and replies
            return (
              <div className="bg-white rounded-md shadow mb-6">
                <article class=" p-6 text-base  border-t border-gray-200  ">
                  <footer class="flex justify-between items-center mb-2">
                    <div class="flex items-center">
                      <p class="inline-flex items-center mr-3 text-sm text-gray-900  font-semibold">
                        {/* <img
                          class="mr-2 w-6 h-6 rounded-full"
                          src="https://flowbite.com/docs/images/people/profile-picture-4.jpg"
                          alt="Helene Engels"
                        /> */}
                        {name}
                      </p>
                      <p class="text-sm text-gray-600 dark:text-gray-400">
                        <time pubdate datetime={createdAt} title={createdAt}>
                          {utils.formatDate(createdAt)}
                        </time>
                      </p>
                    </div>
                    {/* REMOVE Dropdown button */}
                    {/* <button
                      id={`dropdownComment${cIdx}Button`}
                      data-dropdown-toggle={`dropdownComment${cIdx}Button`}
                      class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500   rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50   dark:focus:ring-gray-600"
                      type="button"
                    >
                      <svg
                        class="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 16 3"
                      >
                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                      </svg>
                    </button> */}

                    <div
                      id={`dropdown${cIdx}`}
                      class="hidden z-10 w-36  rounded divide-y divide-gray-100 shadow  dark:divide-gray-600"
                    >
                      <ul
                        class="py-1 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownMenuIconHorizontalButton"
                      >
                        <li>
                          <a
                            href="#"
                            class="block py-2 px-4 hover:bg-gray-100  dark:hover:text-white"
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            class="block py-2 px-4 hover:bg-gray-100  dark:hover:text-white"
                          >
                            Remove
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            class="block py-2 px-4 hover:bg-gray-100  dark:hover:text-white"
                          >
                            Report
                          </a>
                        </li>
                      </ul>
                    </div>
                  </footer>
                  <p class="text-gray-500 dark:text-gray-400">
                    {comment.comment}
                  </p>
                  {/* <CommentsBar
                    comment={comment}
                    replies={replies}
                    replyCount={replies && replies.length}
                    thanksCount={
                      comment &&
                      comment.thanks &&
                      comment.thanks.count &&
                      comment.thanks.count
                    }
                  /> */}
                </article>
              </div>
            );
          })}
      </div>
    </section>
  );
}
