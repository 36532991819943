import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Skeleton from "../../components/Skeleton";
import { NavLink } from "react-router-dom";
import RightActionArea from "../../components/ActionAreaRight";
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
function AnalyticsCard05(props) {
  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const ref = useRef(null);
  const data = props.data;
  const upper = props.uppercase;
  const loading = props.loading;

  const getWidth2 = (val) => {
    const highVal = data[0][1];
    const lowVal = data[data.length - 1][1];
    let maxW = 0;
    if (ref.current) {
      maxW = ref.current.offsetWidth;
    } else {
      maxW = 800;
    }
    let width = ((val + 5 - lowVal) / (highVal - lowVal + 5)) * maxW;
    // let width = (maxW * val) / highVal;
    width = (width / maxW) * 100 + "%";

    return width;
  };

  // const getWidth = (val, title) => {
  //   let highVal = data[0][1];

  //   const lowVal = data[data.length - 1][1];
  //
  //   if (title === "High burden competencies") {
  //     return (val / 100) * 100 + "%";
  //   } else if (title === "Top Teams") {
  //     return (val / 10) * 100 + "%";
  //   }
  //   //

  //   //
  //   const maxW = ref.current ? ref.current.offsetWidth : 1000;
  //   //
  //   // let width = Math.log(maxW) * (Math.log(val) / Math.log(highVal));
  //   // highVal = highVal > 1 ? 1 : highVal;
  //   let width = ((val + 5 - lowVal) / (highVal - lowVal + 5)) * maxW;

  //   // let width = (maxW * val) / 100;
  //
  //   width = (width / maxW) * 100 + "%";

  //
  //   return width;
  // };

  const highVal = data && data[0][1];

  const getWidth = (val, title) => {
    if (highVal > 100) {
      //reduce to 100 by percent
      var percDiff = Math.abs((highVal - 100) / ((highVal + 100) / 2));

      let originalVal = val;
      val = originalVal - originalVal * percDiff;
    }

    const maxW = ref.current ? ref.current.offsetWidth : 100;

    // let width = Math.log(maxW) * (Math.log(val) / Math.log(highVal));
    let width = (maxW * val) / 100;

    width = (width / maxW) * 100 + "%";

    return width;
  };

  return (
    <div
      className={`flex  flex-col col-span-full sm:col-span-6 md:col-span-${colSpan}  lg:col-span-${colSpan} xl:col-span-${colSpan}  grow-0 row-span-${rowspan} bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <header className="flex grid grid-flow-col px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
        <RightActionArea>
          <div className=" text-sm font-semibold text-accent-600">
            {/* <NavLink className="" to="/assessments">
              See All -&gt;
            </NavLink>{" "} */}
          </div>
        </RightActionArea>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <div className="grow p-3">
          <div className="flex flex-col h-full">
            {/* Card content */}
            <div className="grow">
              <ul className="flex justify-between text-xs uppercase text-slate-400 font-semibold px-2 space-x-2">
                <li>Name</li>
                <li>Total</li>
              </ul>

              <ul className="space-y-1 text-sm text-slate-800 mt-3 mb-4">
                {/* Item */}
                {data.length > 0 &&
                  data !== "loading" &&
                  data.map((item, index) => {
                    const key = item[0];
                    const val = item[1];
                    // const val2 = item[2] ? item[2] : 0;

                    return (
                      <li
                        id="progress"
                        ref={ref}
                        className="relative px-2 py-1 cursor-pointer  "
                      >
                        <div
                          className={` absolute inset-0 bg-accent-200 opacity-30  rounded-md transition ease-in-out duration-150`}
                          aria-hidden="true"
                          style={{
                            width: getWidth(val, props.title),
                          }}
                        ></div>
                        <div className="relative flex justify-between space-x-2">
                          <div
                            className={`${upper == true ? "uppercase" : null} `}
                          >
                            {key}
                          </div>
                          <div className="font-medium">{val}</div>
                          {/* <div className="font-medium">{val2}</div> */}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {/* Card footer */}
            <div className="text-center pt-4 pb-1 border-t border-gray-100">
              {/* <Link
              className="text-sm font-medium text-primary-500 hover:text-primary-600"
              to="#0"
            >
              See All -&gt;
            </Link> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AnalyticsCard05;
