import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormPage from "../pages/component/FormPage";
import { InviteUser } from "./FormPageInviteUser";

function UserAddPanel(props) {
  const history = useHistory();
  const profile = props.profile;

  return (
    <div
      id="settings"
      className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
    >
      <div id="user-add-panel">
        <div className="grid grid-cols-2 divide-x px-3">
          {/* {props.type == "new" && <InviteUser profile={profile} type={"new"} />} */}
          <InviteUser profile={profile} type={props.type} />
          <FormPage type={props.type} />
        </div>
      </div>
    </div>
  );
}

export default UserAddPanel;
