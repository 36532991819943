export const sort = {
  sortData(sortType, sortedList) {
    if (sortType === "name-a-z") {
      sortedList.sort(function (a, b) {
        if (a.Team && b.Team) {
          if (a.Team > b.Team) return 1;
          if (a.Team < b.Team) return -1;
        }
        return 0;
      });
      // return a.Team && b.Team && a.Team.toLowerCase() - b.Team.toLowerCase();

      // setEmployees(sortedList);
    } else if (sortType === "name-z-a") {
      sortedList.sort((a, b) => {
        if (a.Team && b.Team) {
          if (a.Team < b.Team) return 1;
          if (a.Team > b.Team) return -1;
        }
        return 0;
        // return b.Team && a.Team && b.Team.toLowerCase() - a.Team.toLowerCase();
      });
      // setEmployees(sortedList);
    } else if (sortType === "maturity-first-last") {
      sortedList.sort((a, b) => {
        if (a.scores && b.scores) {
          return b.scores.avg_score - a.scores.avg_score;
        }
        // return a.scores && b.scores && b.scores.avg_score - a.scores.avg_score;
      });
      // setEmployees(sortedList);
    } else if (sortType === "maturity-last-first") {
      sortedList.sort((a, b) => {
        return a.scores && b.scores && a.scores.avg_score - b.scores.avg_score;
      });
      // setEmployees(sortedList);
    }
    localStorage.setItem("Sort Type", sortType);

    return sortedList;
  },
};
